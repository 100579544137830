import {
  Camera,
  ScenarioPerimeter,
  UnsetPerimeter,
  XYCoordinate,
} from '../../hooks/graphql/camera';
import { ScenarioName } from '../../lib/features/scenario';
import { isDefined } from '../../utils/typeUtils';

export type CameraProps = {
  id: string;
  sql_id: number;
  imageUrl: string;
  name: string;
  perimeters: string;
  scenarios: ScenarioName[];
  schedule: string;
  shouldCollectData: boolean;
};

export type CameraUpdatePayload = CameraProps & {
  ipAddress: string;
  port: number;
  threshold: number;
};

export const RenderList = [
  {
    value: 2,
    colValue: 6,
  },
  {
    value: 3,
    colValue: 4,
  },
  {
    value: 4,
    colValue: 3,
  },
];

export type RenderListProps = {
  value: number;
  colValue: number;
};

export const numberOfIncident = [10, 20, 50, 100, 200];

export const showNumberOfItems = [1, 10, 20, 50, 100, 200];

export type DangerZone = {
  index: number | 'new';
  completed: boolean;
  coordinates: XYCoordinate[];
};

export const convertToDangerZones = (
  perimeter: ScenarioPerimeter,
  completed = true,
) =>
  perimeter.position.map((coordinates, index) => ({
    index,
    completed,
    coordinates,
  })) satisfies DangerZone[] as DangerZone[];

export const isPerimeterSet = (
  perimeter?: ScenarioPerimeter | UnsetPerimeter,
): perimeter is ScenarioPerimeter =>
  isDefined(perimeter) && 'scenario' in perimeter;

export function transformCameraToUpdatePayload(
  camera: Camera,
): CameraUpdatePayload {
  const cameraScenarios = camera.cameras_scenarios;
  const scenarios = cameraScenarios.map(
    (item) => item.customer_scenario_label.name,
  );
  const perimeters = cameraScenarios
    .map((item) => item.perimeter)
    .filter(isPerimeterSet);

  return {
    id: String(camera.id),
    sql_id: camera.id,
    imageUrl: camera.image_url,
    name: camera.name,
    perimeters: JSON.stringify(perimeters),
    schedule: JSON.stringify(camera.schedule),
    shouldCollectData: camera.should_collect_data,
    scenarios,
    ipAddress: camera.ip_address,
    port: camera.port,
    threshold: camera.threshold,
  };
}
