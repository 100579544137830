/** @jsxImportSource @emotion/react */
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import i18n from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from './ConfirmationModal';
import { NarrowObservationsTable } from './observation/NarrowObservationsTable';
import {
  ObservationActionEventHandler,
  ObservationActions,
} from './observation/ObservationActions';
import { ObservationDetailsRow } from './observation/ObservationDetailsRow';
import { ObservationsModalImage } from './observation/ObservationsModalImage';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { useAuthenticatedUserContext } from '../../contextapi/AuthenticatedUserProvider';
import { useFeatureFlags } from '../../contextapi/FeatureFlagsProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { UPDATE_OBSERVATION } from '../../graphql/mutations';
import { UPDATE_OBSERVATION_BY_ID } from '../../graphql/mutations/observation';
import {
  GetObservationsNoOpQueryBuilder,
  GetObservationsQueryBuilder,
} from '../../hooks/graphql/observations';
import { useConfirmationModal } from '../../hooks/modal';
import { useAddBookmark, useDeleteBookmark } from '../../hooks/observations';
import { useTrackEvent } from '../../hooks/track_event';
import { spacing } from '../../scss/spacing';
import { isReportFalseFormData } from '../../typescript/components/modal';
import { ObservationProp } from '../../typescript/observation/observation';
import { BWButton } from '../elements/BWButton';
import { CustomSwitch } from '../elements/CustomSwitch';
import { ImageDownloader } from '../elements/ImageDownloader';
import { AnnotationRef } from '../elements/ImageWithAnnotation';
import { SectionTitle } from '../elements/SectionTitle';
import { ShortCutOverlay } from '../observation-quick-view/ShortCutOverlay';

interface Props {
  observation?: ObservationProp;
  paginationDefaults?: {
    pageNumber?: number;
    limit?: number;
  };
  buildGetObservationsQuery?: GetObservationsQueryBuilder;
  onClose: () => void;
}

export function ObservationModal({
  observation,
  paginationDefaults,
  buildGetObservationsQuery = GetObservationsNoOpQueryBuilder,
  onClose,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { user } = useAuthenticatedUserContext();
  const { featureFlags, isAnnotatedObservation } = useFeatureFlags();
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();

  // State
  const [showLabel, setShowLabel] = useState<boolean>(
    featureFlags.observations.show_labels_by_default || false,
  );
  const [hideDangerZone, setHideDangerZone] = useState(true);
  const [activeObservation, setActiveObservation] = useState<
    ObservationProp | undefined
  >(observation);

  useEffect(() => {
    setActiveObservation(observation);
  }, [observation]);

  const handleCloseModal = (action: 'hide' | 'cancel') => {
    trackEvent('modal_close', {
      close_action: action,
      modal: 'observation_quick_view',
    });
    onClose();
  };

  const showAnnotations = !isAnnotatedObservation(activeObservation);

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const [updateObservation] = useMutation(UPDATE_OBSERVATION);
  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);
  const [addObservationBookmark] = useAddBookmark();
  const [deleteObservationBookmark] = useDeleteBookmark();

  const hasDangerZone =
    (activeObservation?.perimeter?.position.length || 0) > 0;

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  const { confirmationModal, openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();

  const openReportFalsePositiveModal = useCallback(
    (observationId: number) => {
      openConfirmationModal({
        type: 'report',
        name: 'report_false_positive',
        title: i18n.t('button.report_false'),
        body: '',
        buttonType: 'primary',
        doneText: i18n.t('button.send'),
        cancelText: i18n.t('button.cancel'),
        onClose: (reason, formData) => {
          if (reason === 'confirm' && isReportFalseFormData(formData)) {
            updateObservation({
              variables: {
                id: observationId,
                data: {
                  is_false_positive: true,
                  false_positive_reason: formData.reason,
                  false_positive_comment: formData.comment,
                  date_set_false_positive: moment()
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                },
              },
            });
          }

          closeConfirmationModal();
        },
      });
    },
    [openConfirmationModal, closeConfirmationModal, updateObservation],
  );

  const handleObservationUpdate: ObservationActionEventHandler = (
    observation,
    action,
    value,
  ) => {
    if (action === 'report') {
      openReportFalsePositiveModal(observation.id);
    } else if (action === 'view_details') {
      navigate(`/observation/${observation.id}`);
    } else if (action === 'bookmark') {
      const bookmarkId = observation.observation_users.find(
        ({ user_id }) => user.id === user_id,
      )?.id;

      if (bookmarkId !== undefined) {
        deleteObservationBookmark({
          variables: {
            id: bookmarkId,
          },
        });
      } else {
        addObservationBookmark({
          variables: {
            data: {
              observation_id: observation.id,
              user_id: user.id,
            },
          },
        });
      }
    } else {
      updateSensitiveObservation({
        variables: {
          id: observation.id,
          column: action,
          value,
        },
      });
    }
  };

  const showObservationTable = paginationDefaults !== undefined;
  const showModal = activeObservation !== undefined;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (confirmationModal.show || !showModal) {
        return;
      }

      if (event.key === 'd') {
        if (showAnnotations) {
          event.preventDefault();
          setHideDangerZone(!hideDangerZone);
        }
      } else if (event.key === 'l') {
        if (showAnnotations) {
          event.preventDefault();
          setShowLabel(!showLabel);
        }
      } else if (event.key === 'D') {
        event.preventDefault();
        handleExport();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [
    confirmationModal.show,
    showAnnotations,
    hideDangerZone,
    onClose,
    showLabel,
    showModal,
  ]);

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={() => handleCloseModal('hide')}
        css={css`
          .modal-dialog {
            min-width: min-content;

            .modal-content {
              min-width: fit-content;
              min-height: fit-content !important;

              .modal-header {
                padding-top: ${spacing(4)};
              }

              .modal-body {
                padding-bottom: ${spacing(6)};
              }
            }
          }
        `}
      >
        <Modal.Header>
          <SectionTitle
            title={`${i18n.t('modal.observation.title')}`}
            removeMargins
          />

          <BWButton
            icon={CancelIcon}
            variant="transparent"
            className="border-0 ms-auto"
            type="button"
            onClick={() => handleCloseModal('cancel')}
            doNotTrack
          />
        </Modal.Header>
        <Modal.Body className="pt-0">
          {activeObservation !== undefined && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: `${spacing(2)}`,
                  padding: `${spacing(3)} 0`,
                }}
              >
                <BWButton
                  className="button-with-loader text-nowrap"
                  variant="outline"
                  icon={WarningIcon}
                  title={i18n.t('button.report_false')}
                  onClick={() => {
                    openReportFalsePositiveModal(activeObservation.id);
                  }}
                  trackAs={{
                    event: 'modal_open',
                    properties: {
                      modal: 'report_false_positive',
                    },
                  }}
                />

                <ImageDownloader
                  imageUrl={activeObservation?.imageUrl}
                  onClick={handleExport}
                />

                <ObservationActions
                  observation={activeObservation}
                  hideReportFalsePositive
                  onObservationAction={handleObservationUpdate}
                />
              </div>

              <Card
                className={`border border-${theme} border-radius`}
                style={{
                  overflow: 'hidden',
                }}
              >
                <Card.Body
                  className="p-0"
                  style={{
                    display: 'flex',
                    width: 'max-content',
                    maxWidth: '90vw',
                    maxHeight: 'calc(100vh - 188px)',
                  }}
                >
                  {showObservationTable && (
                    <NarrowObservationsTable
                      buildGetObservationsQuery={buildGetObservationsQuery}
                      selectedObservation={activeObservation}
                      pagination={paginationDefaults}
                      onObservationSelect={(selectedObservation) => {
                        setActiveObservation(selectedObservation);
                      }}
                      onObservationUpdate={handleObservationUpdate}
                      isConfirmationModalOpen={confirmationModal.show}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    ref={imageContainerRef}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        width: 'fit-content',
                        minHeight: 0,
                        margin: '0 auto',
                      }}
                    >
                      <ObservationsModalImage
                        ref={annotationRef}
                        showLabel={showLabel}
                        observation={activeObservation}
                        showDangerZone={!hideDangerZone}
                      />
                    </div>
                    {showAnnotations && (
                      <div
                        className={`border-${theme}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: `${spacing(4)}`,
                          padding: `${spacing(4)}  ${spacing(3)}`,
                          borderTop: '1px solid',
                        }}
                      >
                        <CustomSwitch
                          label={i18n.t('modal.observation.switch.show_labels')}
                          checked={showLabel}
                          onChange={(e) =>
                            setShowLabel(e.currentTarget.checked)
                          }
                        />
                        <CustomSwitch
                          label={i18n.t('modal.observation.switch.danger_zone')}
                          checked={hasDangerZone && !hideDangerZone}
                          onChange={(e) =>
                            setHideDangerZone(!e.currentTarget.checked)
                          }
                          disabled={!hasDangerZone}
                        />
                        {showObservationTable && <ShortCutOverlay />}
                      </div>
                    )}
                    <ObservationDetailsRow observation={activeObservation} />
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
        </Modal.Body>
      </Modal>
      <ConfirmationModal options={confirmationModal} />
    </>
  );
}
