import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CAMERA, GET_CAMERAS } from '../../graphql/queries/cameras';
import { type ScenarioName } from '../../lib/features/scenario';
import { Maybe } from '../../utils/typeUtils';

type GetCameraQueryVariables = {
  id: number;
};
export type XYCoordinate = [number, number];

export type ScenarioPerimeter = {
  position: XYCoordinate[][];
  scenario: ScenarioName;
};

export type UnsetPerimeter = Record<string, never>;

export type Perimeter = ScenarioPerimeter | UnsetPerimeter;

export type CameraScenario = {
  id: number;
  perimeter: Perimeter;
  customer_scenario_label: {
    id: number;
    name: ScenarioName;
    scenario: {
      name: string;
    };
    label: {
      name: string;
    };
  };
};

export type CameraSchedule = {
  0?: string[];
  1?: string[];
  2?: string[];
  3?: string[];
  4?: string[];
  5?: string[];
  6?: string[];
};

export type ScenariosSchedule = Partial<Record<ScenarioName, CameraSchedule>>;

export type Camera = {
  id: number;
  name: string;
  status: Maybe<'ONLINE' | 'OFFLINE'>;
  image_url: string;
  legacy_id: string;
  cameras_scenarios: CameraScenario[];
  observations_aggregate: {
    aggregate: {
      count: number;
    };
  };
  should_collect_data: boolean;
  schedule: {
    cameraSchedule?: CameraSchedule;
    scenarioSchedule?: ScenariosSchedule;
  };

  ip_address: string;
  port: number;
  threshold: number;
};

type GetCameraQueryResponse = {
  cameras_by_pk: Camera;
};

export const useGetCameraQuery = (variables: GetCameraQueryVariables) =>
  useQuery<GetCameraQueryResponse, GetCameraQueryVariables>(GET_CAMERA, {
    variables,
    fetchPolicy: 'network-only',
  });

export const useGetCameraLazyQuery = () =>
  useLazyQuery<GetCameraQueryResponse, GetCameraQueryVariables>(GET_CAMERA);

type GetAllCamerasQueryResponse = {
  cameras: Camera[];
};

export const useGetCamerasQuery = () =>
  useQuery<GetAllCamerasQueryResponse>(GET_CAMERAS, {
    fetchPolicy: 'network-only',
  });
