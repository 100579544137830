import axios, { AxiosError } from 'axios';
import { ZodType } from 'zod';
import { fetchValidIdToken, signOutWithNotification } from '../utils/firebase';
import { isError } from '../utils/typeUtils';

const BUDDYWISE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const axiosClient = axios.create({
  baseURL: BUDDYWISE_API_URL,
  headers: {
    // timeout: 10000,
    'Content-Type': 'application/json',
  },
});

export function handleUnexpectedApiError(error: unknown) {
  if (!isError(error) || !(error instanceof AxiosError)) {
    throw new Error('Unexpected error', {
      cause: error,
    });
  }

  if (error.response?.status === 401) {
    signOutWithNotification();
  }

  return error;
}

export const getRequestHeaders = async (siteId: number | null) => {
  const token = await fetchValidIdToken();
  return {
    Authorization: `Bearer ${token}`,
    ...(siteId && { 'X-Site-Id': `${siteId}` }),
  };
};

export type UserToken = string | null | undefined;

export type APIResponse<TResponse = unknown, TError = Error> =
  | {
      success: true;
      status: number;
      data: TResponse;
    }
  | {
      success: false;
      status?: number;
      detail?: string;
      error: TError;
    };

export const apiPost = async <TPayload, TResponse>(
  url: string,
  siteId: number | null,
  data: TPayload,
  responseSchema: ZodType,
): Promise<APIResponse<TResponse>> => {
  try {
    const response = await axiosClient.post(url, data, {
      headers: await getRequestHeaders(siteId),
    });

    const parsedResponse = responseSchema.parse(response.data);

    return {
      success: true,
      status: response.status,
      data: parsedResponse,
    };
  } catch (unknownError) {
    const error = handleUnexpectedApiError(unknownError);

    return {
      success: false,
      status: error.response?.status,
      detail: error.response?.data.detail,
      error,
    };
  }
};
