/* eslint-disable react/no-unused-prop-types */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

export type TrackingContextType = {
  page?: string;
  element?: string;
};

const TrackingContext = createContext<TrackingContextType>({});

export const useTrackingContext = () => useContext(TrackingContext);

type Props = PropsWithChildren<TrackingContextType>;

export const TrackingContextProvider = ({ page, element, children }: Props) => {
  const context = useMemo(
    () =>
      ({
        page,
        element,
      }) satisfies TrackingContextType,
    [page, element],
  );

  return (
    <TrackingContext.Provider value={context}>
      {children}
    </TrackingContext.Provider>
  );
};
