import React from 'react';
import { TooltipProps } from 'recharts';
import { useScenarioContext } from '../../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../../contextapi/ThemeProvider';
import {
  getScenarioTitle,
  ScenarioName,
} from '../../../../lib/features/scenario';

type CustomTooltipProps = TooltipProps<number, ScenarioName> & {
  totalObservations?: number;
};

export function DonutTooltip({
  active,
  payload,
  totalObservations,
}: CustomTooltipProps) {
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    const percentage = ((value || 0) / (totalObservations || 0)) * 100;

    const scenario = getScenarioByName(name);
    return scenario ? (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius d-flex align-items-center`}
      >
        <div
          className="me-1"
          style={{
            width: 10,
            height: 10,
            background: scenario.primaryColor,
          }}
        />
        <p className="m-0 ">{`${getScenarioTitle(scenario)} — ${percentage.toFixed(2)}% (${value})`}</p>
      </div>
    ) : null;
  }

  return null;
}
