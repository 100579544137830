/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  COLOR_STATUS_ERROR,
  COLOR_STATUS_NEUTRAL,
  COLOR_STATUS_SUCCESS,
} from '../../scss/colors';
import { spacing } from '../../scss/spacing';
import { Maybe } from '../../utils/typeUtils';

type Props = {
  status: Maybe<'ONLINE' | 'OFFLINE'>;
};

export function CameraStatusIcon({ status }: Props) {
  const statusColor =
    status === 'ONLINE'
      ? COLOR_STATUS_SUCCESS
      : status === 'OFFLINE'
        ? COLOR_STATUS_ERROR
        : COLOR_STATUS_NEUTRAL;

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: ${spacing(1)};
        white-space: nowrap;
        background-color: ${statusColor};
      `}
    />
  );
}
