import { ButtonVariant } from '../../components/elements/BWButton';
import { SVGComponent } from '../utils';

export type ModalType = 'default' | 'report';

export type OnCloseReason = 'hide' | 'cancel' | 'confirm';

export type ReportFalseFormData = {
  reason: string | null;
  comment: string | null;
};

export function isReportFalseFormData(
  data: Record<string, never> | ReportFalseFormData | undefined,
): data is ReportFalseFormData {
  return data !== undefined && 'reason' in data && 'comment' in data;
}

export type ModalFormData = Record<string, never> | ReportFalseFormData;

export type ModalOptions = {
  type: 'default' | 'report';
  name: string;
  body?: string | null;
  title: string | null;
  buttonType: ButtonVariant;
  doneText: string | null;
  cancelText: string | null;
  show: boolean;
  loading?: boolean;
  icon?: SVGComponent;
  onClose: (reason: OnCloseReason, formData?: ModalFormData) => void;
};

export const defaultModalOptions: ModalOptions = {
  type: 'default',
  name: 'unknown',
  title: '',
  body: '',
  buttonType: 'primary',
  doneText: '',
  cancelText: '',
  show: false,
  icon: undefined,
  loading: false,
  onClose: () => null,
};
