import React, { PropsWithChildren } from 'react';
import { Col } from 'react-bootstrap';
import { SVGComponent } from '../../../typescript/utils';
import { BWButton } from '../../elements/BWButton';

type Props = PropsWithChildren<{
  title: string | null;
  description: string | null;
  buttonLabel: string | null;
  buttonIcon?: SVGComponent;
  buttonLoading?: boolean;
  onClick: () => void;
  withoutHr?: boolean;
}>;

export function SettingsBlock({
  title,
  description,
  children,
  buttonLabel,
  buttonIcon,
  buttonLoading = false,
  onClick,
  withoutHr = false,
}: Props) {
  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{title}</p>
      <p className="sub-color">{description}</p>

      {children}

      <BWButton
        type="button"
        variant="outline"
        className="button-with-loader"
        icon={buttonIcon}
        title={buttonLabel}
        loading={buttonLoading}
        onClick={onClick}
      />

      {!withoutHr && <hr />}
    </Col>
  );
}
