import { useQuery } from '@apollo/client';
import { $switchCaseStrict } from 'dolla-switch';
import { ALERT_OBSERVATIONS_BY_INCIDENTS_ID } from '../../graphql/queries/alerts';
import {
  GET_BOOKMARKED_OBSERVATIONS,
  GET_OBSERVATIONS,
  WhereQueryProps,
} from '../../graphql/queries/observation';
import { GET_SCENARIO_DATA } from '../../graphql/queries/scenarios';
import { ScenarioName } from '../../lib/features/scenario';
import { GroupingItemProp } from '../../typescript/grouping/grouping-observation';
import { isObject } from '../../utils/typeUtils';

export type GetObservationsQueryVariables = {
  id?: number; // alertId
  limit: number;
  offset: number;
  where: WhereQueryProps;
  orderBy: 'asc' | 'desc';
};

export type GetGroupObservationQueryVariables = {
  from: number;
  until: number;
  group_id?: string;
  camera_ids?: Array<number>;
  camera_ids_string?: string;
  limit: number;
  offset: number;
  where: WhereQueryProps;
  order_by: { created: string };
};

type QueryVariables = {
  default: GetObservationsQueryVariables;
  alert: GetObservationsQueryVariables;
  bookmark: GetObservationsQueryVariables;
  camera: GetObservationsQueryVariables;
  group: GetGroupObservationQueryVariables;
};

type Observation = {
  id: number;
  imageUrl: string;
  priority: string;
  requestId: string;
  responder: string;
  status: string;
  system_timestamp: number;
  created: number;
  detections: {
    location: number[];
    id: number;
    label: string;
    observation: {
      customer_scenario_label_id: number;
    };
  }[];
  customer_scenario_label: {
    name: ScenarioName;
    scenario: {
      name: string;
    };
  };
  camera: {
    id: number;
    name: string;
  };
  observation_users: {
    id: number;
    user_id: number;
  }[];
};

export type GetObservationsQueryResponse = {
  observations: Observation[];

  observations_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export function isGetObservationsQueryResponse(
  response: unknown,
): response is GetObservationsQueryResponse {
  return isObject(response) && 'observations' in response;
}

export type GetAlertObservationsQueryResponse = {
  alert: {
    id: number;
    alert_incident_observations: {
      observation: Observation;
    }[];

    alert_incident_observations_aggregate: {
      aggregate: {
        count: number;
      };
    };
  };
};

export function isGetAlertObservationsQueryResponse(
  response: unknown,
): response is GetAlertObservationsQueryResponse {
  return isObject(response) && 'alert' in response;
}

export type GetBookmarkedObservationsQueryResponse = {
  observation_user: {
    observation: Observation;
  }[];

  observation_user_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export function isGetBookmarkedObservationsQueryResponse(
  response: unknown,
): response is GetBookmarkedObservationsQueryResponse {
  return isObject(response) && 'observation_user' in response;
}

export type GetGroupObservationQueryResponse = {
  fetch_observations_by_group_id: Observation[];
  fetch_observations_by_group_id_aggregate: {
    aggregate: {
      count: number;
    };
  };
  observation_group_statistics: GroupingItemProp[];
};

export function isGetGroupObservationQueryResponse(
  response: unknown,
): response is GetGroupObservationQueryResponse {
  return isObject(response) && 'fetch_observations_by_group_id' in response;
}

type QueryResponses = {
  default: GetObservationsQueryResponse;
  camera: GetObservationsQueryResponse;
  alert: GetAlertObservationsQueryResponse;
  bookmark: GetBookmarkedObservationsQueryResponse;
  group: GetGroupObservationQueryResponse;
};

export type ObservationsListVariant =
  | 'default'
  | 'alert'
  | 'bookmark'
  | 'camera'
  | 'group';

export const useGetObservationsQuery = <T extends ObservationsListVariant>(
  variant: T,
  variables: QueryVariables[T],
  skip = false,
) => {
  const query = $switchCaseStrict(variant as ObservationsListVariant, {
    alert: () => ALERT_OBSERVATIONS_BY_INCIDENTS_ID,
    bookmark: () => GET_BOOKMARKED_OBSERVATIONS,
    group: () => GET_SCENARIO_DATA,
    default: () => GET_OBSERVATIONS,
    camera: () => GET_OBSERVATIONS,
  });

  return useQuery<QueryResponses[T], QueryVariables[T]>(query, {
    variables,
    skip,
  });
};

export type GetObservationsQueryBuilder = (args: {
  limit: number;
  offset: number;
  isOrder: boolean;
}) => [
  ObservationsListVariant,
  GetObservationsQueryVariables | GetGroupObservationQueryVariables,
];

export const GetObservationsNoOpQueryBuilder: GetObservationsQueryBuilder =
  () => {
    throw new Error('GetObservationsNoOpQueryBuilder is not implemented');
  };

export type PaginationOptions = {
  limit: number;
  pageNumber: number;
  isOrder: boolean;
};
