import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { urlTempImage, urlTempImageV2 } from '../apis/api-request';
import { useAuthenticatedUserContext } from '../contextapi/AuthenticatedUserProvider';
import { ImageSize, useCacheContext } from '../contextapi/CacheProvider';
import { useFeatureFlags } from '../contextapi/FeatureFlagsProvider';
import { ObservationProp } from '../typescript/observation/observation';
import { siteIdAtom } from '../utils/atoms';
import { isString, isUndefined } from '../utils/typeUtils';

export const useObservationImage = (
  observation: ObservationProp,
  imageSize: ImageSize,
) => {
  const { user } = useAuthenticatedUserContext();
  const { featureFlags } = useFeatureFlags();
  const { getCachedImageUrl, setCachedImageUrl } = useCacheContext();
  const siteId = useAtomValue(siteIdAtom);

  const imageUrl = getCachedImageUrl(observation.requestId, imageSize);
  const [isImageLoading, setIsImageLoading] = useState(isUndefined(imageUrl));
  const [isImageFound, setIsImageFound] = useState(isString(imageUrl));
  const [retryCount, setRetryCount] = useState(0);

  const fetchImageUrl = useCallback(async () => {
    if (retryCount < 2) {
      setIsImageLoading(true);
      const param = {
        customer_id: user.customer.id,
        site_id: siteId,
        request_id: observation.requestId,
        version: imageSize,
      };

      const oldParam = { observation_id: observation.id };
      const { show_thumbnail } = featureFlags.image;
      try {
        const getImageUrl = show_thumbnail
          ? await urlTempImageV2(siteId, param)
          : await urlTempImage(siteId, oldParam);

        if (!getImageUrl || getImageUrl?.data.detail === 'Image not found') {
          const getOldImageUrl = await urlTempImage(siteId, oldParam);

          setIsImageFound(true);
          const imageUrl = getOldImageUrl.data.message as string;
          setCachedImageUrl(observation.requestId, imageSize, imageUrl);
        }
        setIsImageFound(true);
        const imageUrl = show_thumbnail
          ? (getImageUrl.data.signed_url as string)
          : (getImageUrl.data.message as string);
        setCachedImageUrl(observation.requestId, imageSize, imageUrl);
      } catch (error) {
        console.error('Error fetching image URL:', error);
        setIsImageFound(false);
        setRetryCount(retryCount + 1);
      } finally {
        setIsImageLoading(false);
      }
    }

    return null;
  }, [
    retryCount,
    user.customer.id,
    siteId,
    observation.requestId,
    observation.id,
    imageSize,
    featureFlags.image,
    setCachedImageUrl,
  ]);

  return {
    imageUrl,
    fetchImageUrl,
    isImageLoading,
    isImageFound,
    setIsImageLoading,
  };
};
