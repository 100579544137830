import React, { PropsWithChildren } from 'react';
import { Col } from 'react-bootstrap';

type Props = PropsWithChildren<{
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
}>;

export const FlexCol = ({ children, sm, md, lg, className }: Props) => (
  <Col
    className={className}
    sm={sm}
    md={md}
    lg={lg}
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    {children}
  </Col>
);
