import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VerticalDotsIcon } from '../../assets/icons/vertical-dots.svg';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { AlertIncidents } from '../../typescript/smart-alert/smart-alert';
import { CustomDropdown } from '../elements/CustomDropdown';
import { ScenarioLabel } from '../elements/ScenarioLabel';

interface Props {
  alert: AlertIncidents;
}

export function AlertRow({ alert }: Props) {
  const navigate = useNavigate();
  const { getScenarioById } = useScenarioContext();
  const { cameraList } = useCameraContext();

  const filteredCamera = alert.alert_rule_state.triggering_camera_ids
    ? cameraList.filter((o1) =>
        alert.alert_rule_state.triggering_camera_ids.some(
          (o2) => o1.sql_id === o2,
        ),
      )
    : [];

  const scenarioId = Number(alert.alert_rule_state.customer_scenario_label_id);
  const scenario = getScenarioById(scenarioId);

  function navigateToAlertDetails() {
    navigate(`/alerts/${alert.id}`);
  }

  const dropdownList = [
    {
      title: i18n.t('button.view_details'),
      value: 'view_details',
    },
  ];

  const createdAt = moment(alert.created_at).unix();

  return (
    <tr className="add-cursor selected-row">
      <td className="p-0" onClick={navigateToAlertDetails}>
        <div className="d-flex align-items-center">
          <span className="ps-3 py-3 ms-0 ph-no-capture">
            {moment.unix(createdAt).format('DD MMM, HH:mm:ss')}
          </span>
        </div>
      </td>
      <td onClick={navigateToAlertDetails}>
        <p className="mb-1 ph-no-capture">
          {alert.alert_rule_state
            ? alert.alert_rule_state.name
            : 'this alert is deleted'}
        </p>
      </td>

      <td onClick={navigateToAlertDetails}>
        {!scenario ? (
          <span>{i18n.t('td.scenario_not_found')}</span>
        ) : (
          <ScenarioLabel scenario={scenario} />
        )}
      </td>

      <td onClick={navigateToAlertDetails}>
        {filteredCamera.length === 0 && (
          <span className="camera-name rounded-pill ph-no-capture">
            {i18n.t('td.camera_no_longer_exists')}
          </span>
        )}
        {filteredCamera.slice(0, 4).map((item, index) => (
          <span
            key={`${index + 1}`}
            className="camera-name rounded-pill ph-no-capture"
          >
            {item ? item.name : i18n.t('td.camera_no_longer_exists')}
          </span>
        ))}

        {filteredCamera.length > 4 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <span className="ph-no-capture">
                  {filteredCamera
                    .slice(4, filteredCamera.length - 1)
                    .map((item) => item.name)
                    .toString()
                    .replaceAll(',', ', ')}
                </span>
              </Tooltip>
            }
          >
            <span className="show-more-cameras ph-no-capture">
              {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
              {i18n.t('list.others')}
            </span>
          </OverlayTrigger>
        )}
      </td>

      <td>
        <div
          className="d-flex justify-content-center align-items-center"
          aria-hidden
        >
          <CustomDropdown
            dropdownClassName="position-absolute"
            dropdownData={dropdownList}
            hideContextData
            isThreeDot
            icon={VerticalDotsIcon}
            onClick={({ value }) => {
              if (value === 'view_details') {
                navigateToAlertDetails();
              }
            }}
          />
        </div>
      </td>
    </tr>
  );
}
