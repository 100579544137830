import { DaysListProps } from '../../typescript/grouping/grouping-observation';

export const generateTimeRangeUrlParam = (
  timeRange: DaysListProps,
  valueOnly = false,
) => {
  const value = JSON.stringify({
    title: timeRange.text,
    range: [timeRange.value, timeRange.end],
  });

  return encodeURI(valueOnly ? value : `query=${value}`);
};
