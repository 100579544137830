import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ImageSize = 'thumbnail' | 'original';

export type CacheContextType = {
  getCachedImageUrl: (requestId: string, size: ImageSize) => string | undefined;
  setCachedImageUrl: (
    requestId: string,
    size: ImageSize,
    imageUrl: string,
  ) => void;
};

const CacheContext = createContext<CacheContextType | undefined>(undefined);

export const useCacheContext = () => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('useCacheContext must be used within a CacheProvider');
  }

  return context;
};

export function CacheProvider({ children }: PropsWithChildren) {
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  const getCachedImageUrl = useCallback(
    (requestId: string, size: ImageSize) => {
      const cacheKey = `${requestId}-${size}`;
      return imageUrls[cacheKey];
    },
    [imageUrls],
  );
  const setCachedImageUrl = useCallback(
    (requestId: string, size: ImageSize, imageUrl: string | null) => {
      const cacheKey = `${requestId}-${size}`;
      setImageUrls((prev) => ({ ...prev, [cacheKey]: imageUrl || '' }));
    },
    [],
  );

  const context = useMemo(
    () => ({
      getCachedImageUrl,
      setCachedImageUrl,
    }),
    [getCachedImageUrl, setCachedImageUrl],
  );

  return (
    <CacheContext.Provider value={context}>{children}</CacheContext.Provider>
  );
}
