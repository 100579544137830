import { gql } from '@apollo/client';
import { USER_FRAGMENTS } from '../queries/user';

export const UPDATE_USER = gql`
  ${USER_FRAGMENTS}

  mutation UPDATE_USER(
    $id: Int!
    $name: String
    $title: String
    $phone_no: String
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, title: $title, phone_no: $phone_no }
    ) {
      ...UserFragment
    }
  }
`;
