import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  className?: string;
  controlId: string;
  type?: string;
  name?: string | undefined;
  value?: string | number | string[] | undefined;
  required?: boolean;
  title?: string;
  placeholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export function InputField({
  className,
  controlId,
  type = 'text',
  value,
  name,
  title,
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  onChange,
}: Props) {
  return (
    <Form.Group className={className} controlId={controlId}>
      {title && <Form.Label className="weight-500">{title}</Form.Label>}
      <Form.Control
        className="rounded-pill form-control-input"
        value={value}
        name={name}
        required={required}
        type={type}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );
}
