import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { AlertIncidents } from '../../typescript/smart-alert/smart-alert';
import { ScenarioLabel } from '../elements/ScenarioLabel';

interface Props {
  alert: AlertIncidents;
}

export function AlertOverview({ alert }: Props) {
  const { cameraList } = useCameraContext();
  const { customerScenarios } = useScenarioContext();

  const filteredCamera = alert.alert_rule_state.triggering_camera_ids
    ? cameraList.filter((o1) =>
        alert.alert_rule_state.triggering_camera_ids.some(
          (o2) => o1.sql_id === o2,
        ),
      )
    : [];

  const filteredScenario = customerScenarios.filter(
    (scenario) =>
      scenario.id === Number(alert.alert_rule_state.customer_scenario_label_id),
  );
  const date = moment(alert.created_at).unix();

  return (
    <Col md={12} className="mb-20">
      <h6 className="mb-20">{i18n.t('alert_detail.alert_overview')}</h6>

      <div>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.alert_name')}:{' '}
          </span>
          {alert.alert_rule_state.name}
        </p>
        <p>
          <span className="fw-bold">{i18n.t('alert_overview.cameras')}: </span>
          {filteredCamera.slice(0, 4).map((item, index) => (
            <span
              key={`${index + 1}`}
              className="camera-name rounded-pill ph-no-capture"
            >
              {item ? item.name : i18n.t('td.camera_no_longer_exists')}
            </span>
          ))}

          {filteredCamera.length > 4 && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <span className="ph-no-capture">
                    {filteredCamera
                      .slice(4, filteredCamera.length - 1)
                      .map((item) => item.name)
                      .toString()
                      .replaceAll(',', ', ')}
                  </span>
                </Tooltip>
              }
            >
              <span className="show-more-cameras ph-no-capture">
                {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
                {i18n.t('list.others')}
              </span>
            </OverlayTrigger>
          )}
        </p>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.scenarios')}:{' '}
          </span>
          {!filteredScenario[0] ? (
            <span>{i18n.t('td.scenario_not_found')}</span>
          ) : (
            <ScenarioLabel scenario={filteredScenario[0]} cursor="default" />
          )}
        </p>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.triggered_on')}:{' '}
          </span>
          {moment.unix(date).format('DD MMM, HH:mm:ss')}
        </p>
      </div>
    </Col>
  );
}
