import React from 'react';
import { TrackingContextProvider } from '../contextapi/TrackingProvider';

export const withPageContext = (children: JSX.Element, pageName: string) => (
  <TrackingContextProvider page={pageName}>{children}</TrackingContextProvider>
);

export const withElementContext = (children: JSX.Element, element: string) => (
  <TrackingContextProvider element={element}>
    {children}
  </TrackingContextProvider>
);
