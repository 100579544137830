import {
  axiosClient,
  getRequestHeaders,
  handleUnexpectedApiError,
} from './utils';

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const fileStatus = async (siteId: number | null) => {
  const response = await axiosClient
    .post(
      '/v2/file-endpoint/status/',
      {},
      {
        headers: await getRequestHeaders(siteId),
      },
    )
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const generateFile = async (
  siteId: number | null,
  data: {
    type: string;
    scenarios: Array<number>;
    since?: number;
    start_time?: number;
    end_time?: number;
    camera_ids?: Array<number>;
    responder?: Array<string>;
    status?: Array<string>;
    priority?: Array<string>;
    is_bookmarked?: boolean;
    limit?: number;
  },
) => {
  const response = await axiosClient
    .post('/v2/file-endpoint/generate-file/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const downloadFile = async (siteId: number | null) => {
  const response = await axiosClient
    .get('/v2/file-endpoint/download-file?file_type=CSV_EXPORT', {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};
