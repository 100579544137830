import { useApolloClient } from '@apollo/client';
import { User } from 'firebase/auth';
import { useAtom } from 'jotai';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from './AuthProvider';
import { LoadingPage } from '../pages/LoadingPage';
import { ClientUser } from '../typescript/observation/assignee';
import {
  excludeDemoAccounts,
  GqlCurrentUser,
  GqlSite,
  isBuddywiseCustomer,
} from '../typescript/user/user';
import { siteIdAtom } from '../utils/atoms';
import { isDefined, isNotNull } from '../utils/typeUtils';

type AuthenticatedUserContextType = {
  user: GqlCurrentUser;
  firebaseUser: User;
  site: GqlSite;
  siteUsers: ClientUser[];
  changeSite: (siteId: number) => Promise<void>;
};

function isAuthenticatedUserContext(context: {
  user?: GqlCurrentUser;
  firebaseUser?: User | null;
  site?: GqlSite;
  siteUsers: ClientUser[];
}): context is AuthenticatedUserContextType {
  return (
    isDefined(context.user) &&
    isDefined(context.firebaseUser) &&
    isNotNull(context.firebaseUser) &&
    isDefined(context.site)
  );
}

const AuthenticatedUserContext =
  createContext<AuthenticatedUserContextType | null>(null);

export const useAuthenticatedUserContext = () => {
  const context = useContext(AuthenticatedUserContext);
  if (!context) {
    throw new Error(
      'useAuthenticatedUser must be used within a AuthenticatedUserProvider',
    );
  }

  return context;
};

export const AuthenticatedUserProvider = ({ children }: PropsWithChildren) => {
  const apolloClient = useApolloClient();
  const [siteId, setSiteId] = useAtom(siteIdAtom);
  const { user, firebaseUser, refetchUser } = useAuthContext();
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  useEffect(() => {
    const validSiteIds = user?.customer.sites.map((site) => site.id);
    if (!validSiteIds) {
      return;
    }

    if (validSiteIds.length === 0) {
      throw new Error('User has access to no sites');
    }

    if (siteId === null || !validSiteIds.includes(siteId)) {
      setSiteId(Math.min(...validSiteIds));
    }
  }, [setSiteId, siteId, user]);

  const site = useMemo(
    () => user?.customer.sites.find((site) => site.id === siteId),
    [siteId, user],
  );

  const siteUsers = useMemo(() => {
    if (!user?.customer.users) {
      return [];
    }

    return isBuddywiseCustomer(user.customer)
      ? user.customer.users
      : user.customer.users.filter(excludeDemoAccounts);
  }, [user]);

  const changeSite = useCallback(
    async (siteId: number) => {
      setShowLoadingPage(true);
      setSiteId(siteId);
      apolloClient.stop();
      await apolloClient.resetStore();
      await refetchUser();
      setShowLoadingPage(false);
    },
    [apolloClient, refetchUser, setSiteId],
  );

  const context = useMemo(
    () => ({
      user,
      firebaseUser,
      site,
      siteUsers,
      changeSite,
    }),
    [user, firebaseUser, site, siteUsers, changeSite],
  );

  if (!isAuthenticatedUserContext(context)) {
    return null;
  }

  return (
    <AuthenticatedUserContext.Provider value={context}>
      {showLoadingPage ? <LoadingPage /> : children}
    </AuthenticatedUserContext.Provider>
  );
};
