/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { CameraProps } from '../camera/camera';
import { ObservationProp } from '../observation/observation';

export type StringAndNumberProps = { title: string; value: number };

export type ActionProps = {
  alert_channel_id: number;
  id: number;
  alert_channel: {
    alert_type_name: string;
  };
};

export type MultiActionProps = {
  action?: ActionProps;
  userIds?: Array<number>;
  iot?: string;
  type: number;
};

export const emptyMultiAction: MultiActionProps = {
  type: 0,
};

export type AlertProps = {
  id: number;
  name: string;
  number_of_observations: number;
  period: number;
  customer_scenario_label_id: number;
  alert_rule_cameras: Array<{
    camera_id: number;
    alert_rule_id: number;
    id: number;
    camera: CameraProps;
  }>;
  alert_actions: Array<{
    id: number;
    alert_site_channel_id: number;
    alert_rule_id: number;
    alert_site_channel: ActionProps;
    alert_recipients: Array<{
      id: number;
      value: string | null;
      alert_action_id: number;
      user_id: number | null;
    }>;
  }>;
  last_triggered: string | null;
  created_at: string | null;
};

export type AlertIncidents = {
  id: number;
  alert_rule: AlertProps;
  alert_rule_state: {
    name: string;
    triggering_camera_ids: Array<number>;
    customer_scenario_label_id: number;
  };
  created_at: string | null;
  alert_incident_observations: Array<{
    observation: ObservationProp;
  }>;
  alert_incident_observations_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

// Create-Alert-Rules
export const conditionList = [
  {
    title: 'button.one_hour',
    value: 1,
  },
  {
    title: 'button.twelve_hour',
    value: 12,
  },
  {
    title: 'button.twenty_four_hour',
    value: 24,
  },
  {
    title: 'button.three_days',
    value: 72,
  },
  {
    title: 'button.one_week',
    value: 168,
  },
];

// export const actionList = [
//   'actionlist.email',
//   'actionlist.sms',
//   // 'actionlist.iot'
// ];
export const intervalList = [
  {
    title: 'button.minutes',
    value: 60,
  },
  {
    title: 'button.hours',
    value: 3600,
  },
  {
    title: 'button.days',
    value: 86400,
  },
];

interface AlertRule {
  camera_id: number;
  alert_rule_id: number;
  id?: number;
}

interface AlertAction {
  id: number;
  alert_site_channel_id: number;
  alert_rule_id: number;
  alert_site_channel: ActionProps;
  alert_recipients: {
    id: number;
    value: string | null | undefined;
    alert_action_id: number;
    user_id: number | null | undefined;
  }[];
}

interface ActionRecipients {
  id: number;
  value: string | null | undefined;
  alert_action_id: number;
  user_id: number | null | undefined;
}
interface AlertAction {
  id: number;
  alert_site_channel_id: number;
  alert_rule_id: number;
  alert_site_channel: ActionProps;
  alert_recipients: Array<ActionRecipients>;
}

interface FilteredItem {
  camera_id: number;
  alert_rule_id?: number;
  id?: number;
  status: string;
}

interface RecipientsFilteredItem {
  id?: number;
  value?: string | null | undefined;
  user_id?: number | null | undefined;
  alert_action_id?: number;
  status: string;
}
interface ActionFilteredItem {
  id?: number;
  alert_site_channel_id: number;
  alert_recipients: Array<RecipientsFilteredItem>;
  status: string;
}

export function filterCameraArray(
  alertRules: AlertRule[],
  cameras: Array<CameraProps>,
  alertId: number,
): Array<FilteredItem> {
  const filteredArray: FilteredItem[] = [];

  // Check for unchanged and created cameras
  cameras.forEach((camera) => {
    const alert = alertRules.find(
      (alertRule) => camera.sql_id === alertRule.camera_id,
    );
    if (alert) {
      filteredArray.push({
        camera_id: alert.camera_id,
        alert_rule_id: alertId,
        id: alert.id,
        status: 'unchanged',
      });
    } else {
      filteredArray.push({
        camera_id: camera.sql_id,
        alert_rule_id: alertId,
        status: 'created',
      });
    }
  });

  // Check for deleted cameras
  alertRules.forEach((alertRule) => {
    const cameraExists = cameras.some(
      (camera) => camera.sql_id === alertRule.camera_id,
    );
    if (!cameraExists) {
      filteredArray.push({
        camera_id: alertRule.camera_id,
        alert_rule_id: alertId,
        id: alertRule.id,
        status: 'deleted',
      });
    }
  });

  return filteredArray;
}

export function filterUserArray(
  userList: Array<ActionRecipients>,
  users: Array<number>,
  alertActionId: number,
): RecipientsFilteredItem[] {
  const filteredArray: RecipientsFilteredItem[] = [];
  users.forEach((user) => {
    const alert = userList.find((alertUser) => user === alertUser.user_id);
    if (alert) {
      filteredArray.push({
        status: 'unchanged',
        id: alert.id,
        user_id: user,
      });
    } else {
      filteredArray.push({
        status: 'created',
        user_id: user,
      });
    }
  });

  userList.forEach((alertUser) => {
    const cameraExists = users.some((user) => user === alertUser.user_id);
    if (!cameraExists) {
      filteredArray.push({
        alert_action_id: alertActionId,
        user_id: alertUser.user_id,
        id: alertUser.id,
        status: 'deleted',
      });
    }
  });
  return filteredArray;
}

export function filterActionArray(
  alertActions: AlertAction[],
  actions: MultiActionProps[],
): ActionFilteredItem[] {
  const filteredArray: ActionFilteredItem[] = [];
  actions.forEach((multiAction) => {
    const { action } = multiAction;
    if (!action) return;

    const alert = alertActions.find(
      (alertAction) => action.id === alertAction.alert_site_channel_id,
    );
    const iot = alert?.alert_recipients.find(
      (alertAction) => alertAction.value,
    );
    if (alert) {
      const updatedUser = !iot
        ? filterUserArray(
            alert.alert_recipients,
            multiAction.userIds || [],
            action.id,
          )
        : [
            {
              alert_action_id: iot.alert_action_id,
              value: multiAction.iot,
              id: iot.id,
              status: 'changed',
            },
          ];
      filteredArray.push({
        id: alert.id,
        alert_site_channel_id: alert.alert_site_channel_id,
        alert_recipients: updatedUser,
        status: 'unchanged',
      });
    } else {
      const alertRecipients = multiAction.userIds
        ? multiAction.userIds.map((user) => ({
            status: 'created',
            user_id: user,
          }))
        : multiAction.iot
          ? [
              {
                status: 'created',
                value: multiAction.iot,
              },
            ]
          : undefined;

      if (alertRecipients) {
        filteredArray.push({
          alert_site_channel_id: action.alert_channel_id,
          alert_recipients: alertRecipients,
          status: 'created',
        });
      }
    }
  });

  alertActions.forEach((alertAction) => {
    const cameraExists = actions.some(
      (action) =>
        action.action && action.action.id === alertAction.alert_site_channel_id,
    );
    if (!cameraExists) {
      filteredArray.push({
        id: alertAction.id,
        alert_site_channel_id: alertAction.alert_site_channel_id,
        alert_recipients: [],
        status: 'deleted',
      });
    }
  });

  return filteredArray;
}
