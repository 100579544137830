import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './scss/buddywise.scss';
import { Provider as StoreProvider } from 'jotai';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { DEPRECATED_LOCAL_STORAGE_KEYS } from './consts';
import { store } from './utils/atoms';
import { initFirebase } from './utils/firebase';
import { initPosthog } from './utils/posthog';
import { initSentry } from './utils/sentry';

initFirebase();
initSentry();
initPosthog();

window._hsp.push([
  'addPrivacyConsentListener',
  () => {
    // do not track with HubSpot, no matter their consent
    window._hsq.push(['doNotTrack']);
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </BrowserRouter>
  </StrictMode>,
);

// Remove deprecated local storage keys
Object.values(DEPRECATED_LOCAL_STORAGE_KEYS).forEach((key) => {
  localStorage.removeItem(key);
});
