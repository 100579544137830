import axios from 'axios';
import { z } from 'zod';

export const TRANSLATIONS_API_BASE_URL = `${process.env.REACT_APP_TRADUORA_URL}/api/v1`;

const projectId = process.env.REACT_APP_TRADUORA_PROJECT_ID;
const clientId = process.env.REACT_APP_TRADUORA_CLIENT_ID;
const clientSecret = process.env.REACT_APP_TRADUORA_CLIENT_SECRET;

const traduoraClient = axios.create({
  baseURL: TRANSLATIONS_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type TokenResponseData = {
  access_token: string;
  token_type: string;
  expires_in: string;
};

const getAuthTokenFromLocalStorage = () => {
  const storedToken = JSON.parse(
    localStorage.getItem('traduora_token') || '{}',
  );
  const parsedToken = z
    .object({
      token: z.string(),
      expires_at: z.number(),
    })
    .safeParse(storedToken).data;

  return parsedToken;
};

const storeAuthTokenInLocalStorage = (responseData: TokenResponseData) => {
  localStorage.setItem(
    'traduora_token',
    JSON.stringify({
      token: responseData.access_token,
      expires_at: Date.now() + parseInt(responseData.expires_in, 10) * 1000,
    }),
  );
};

const getOrFetchTraduoraAuthToken = async () => {
  try {
    const storedToken = getAuthTokenFromLocalStorage();
    if (storedToken && storedToken.expires_at > Date.now()) {
      return storedToken.token;
    }

    const authResponse = await traduoraClient.post<TokenResponseData>(
      '/auth/token',
      {
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
      },
    );
    storeAuthTokenInLocalStorage(authResponse.data);

    return authResponse.data.access_token;
  } catch (error) {
    console.error('Error fetching auth token:', error);
    throw error;
  }
};

export type TranslationCatalog = Record<string, string>;

export const fetchTranslationCatalog = async (locale: string) => {
  const authToken = await getOrFetchTraduoraAuthToken();

  try {
    const response = await traduoraClient.get<TranslationCatalog>(
      `projects/${projectId}/exports?locale=${locale}&format=jsonflat`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching translation catalog:', locale, error);
    throw error;
  }
};
