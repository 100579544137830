import React, { useEffect, useMemo, useState } from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import {
  getScenarioCategoryTitle,
  groupScenariosByCategory,
  Scenario,
} from '../../../lib/features/scenario';
import { spacing } from '../../../scss/spacing';
import { ScenarioLabel } from '../../elements/ScenarioLabel';

type Props = {
  variant: 'singleSelect' | 'multiSelect';
  onScenarioSelected?: (scenario?: Scenario) => void;
  onScenariosSelected?: (scenarios: Scenario[]) => void;
};

export function ScenarioTabs({
  variant,
  onScenarioSelected,
  onScenariosSelected,
}: Props) {
  const { cameraScenarios } = useCameraContext();

  const [selectedScenarios, setSelectedScenarios] = useState<Scenario[]>(
    cameraScenarios.slice(0, variant === 'singleSelect' ? 1 : undefined),
  );

  useEffect(() => {
    if (variant === 'singleSelect') {
      onScenarioSelected?.(selectedScenarios.at(0));
    } else {
      onScenariosSelected?.(selectedScenarios);
    }
  }, [selectedScenarios, onScenarioSelected, variant, onScenariosSelected]);

  const scenariosByCategory = useMemo(
    () => groupScenariosByCategory(cameraScenarios),
    [cameraScenarios],
  );

  return (
    <Col md={12}>
      <Tabs
        onSelect={(category) => {
          setSelectedScenarios(
            cameraScenarios
              .filter((scenario) => scenario.category === category)
              .slice(0, variant === 'singleSelect' ? 1 : undefined),
          );
        }}
        fill
        justify
      >
        {scenariosByCategory.map(({ category, scenarios }) => (
          <Tab
            key={category}
            eventKey={category}
            title={getScenarioCategoryTitle(category)}
            style={{
              margin: `${spacing(3)} 0`,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.5rem',
              }}
            >
              {scenarios.map((scenario) => (
                <ScenarioLabel
                  key={scenario.name}
                  scenario={scenario}
                  showTooltip
                  tooltipPlacement="bottom"
                  isHighlighted={selectedScenarios.some(
                    (selectedScenario) =>
                      selectedScenario.name === scenario.name,
                  )}
                  onClick={() =>
                    variant === 'singleSelect'
                      ? setSelectedScenarios([scenario])
                      : setSelectedScenarios((prev) =>
                          prev.includes(scenario)
                            ? prev.filter((s) => s.name !== scenario.name)
                            : [...prev, scenario],
                        )
                  }
                  cursor="pointer"
                />
              ))}
            </div>
          </Tab>
        ))}
      </Tabs>
    </Col>
  );
}
