import { z } from 'zod';
import {
  apiPost,
  APIResponse,
  axiosClient,
  getRequestHeaders,
  handleUnexpectedApiError,
} from './utils';
import { CameraUpdatePayload } from '../typescript/camera/camera';
import { TimePeriod } from '../typescript/datetime';

export const BUDDYWISE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const userResetPassword = async (data: object) => {
  const response = await axiosClient
    .post('/v1/auth/reset-password', data)
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export type CameraAddPayload = {
  name: string;
  ip: string;
};

export const addCameras = async (
  siteId: number | null,
  data: CameraAddPayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/add/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const doUpdateCamera = async (
  siteId: number | null,
  data: CameraUpdatePayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/update/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export type CameraRemovePayload = {
  id: number;
  ip_address: string;
};

export const doRemoveCamera = async (
  siteId: number | null,
  data: CameraRemovePayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/delete/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export type CameraInitPayload = {
  camera_id: number;
  scenario_id: number;
  ip_address: string;
};

export const doInitCamera = async (
  siteId: number | null,
  data: CameraInitPayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/initialize/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const urlTempImageV2 = async (siteId: number | null, data: object) => {
  const response = await axiosClient
    .post('/v2/images/presigned-url/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const urlTempImage = async (siteId: number | null, data: object) => {
  const response = await axiosClient
    .post('/v2/images/generate-presigned-url/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

type HeatmapPayload = {
  camera_id: number;
  customer_scenario_label_id: number;
  image_url: string;
  timestamp: TimePeriod;
};

export const loadHeatMap = async (
  siteId: number | null,
  data: HeatmapPayload,
): Promise<APIResponse<Blob>> => {
  const response = await axiosClient
    .post('/v2/camera-management/heatmap', data, {
      responseType: 'blob',
      headers: await getRequestHeaders(siteId),
    })
    .then(
      (res) =>
        ({
          success: true,
          status: res.status,
          data: res.data,
        }) satisfies APIResponse,
    )
    .catch((error) => {
      handleUnexpectedApiError(error);
      console.error('Error fetching heatmap:', {
        error,
        response: error.response,
      });

      return {
        success: false,
        status: error.response?.status,
        error,
      } satisfies APIResponse;
    });

  return response;
};

export const createAlertRule = async (siteId: number | null, data: object) => {
  const response = await axiosClient
    .post('/v2/alerts-next/create/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

export const updateAlertRule = async (siteId: number | null, data: object) => {
  const response = await axiosClient
    .post('/v2/alerts-next/update/', data, {
      headers: await getRequestHeaders(siteId),
    })
    .then((res) => res)
    .catch((error) => handleUnexpectedApiError(error).response as any);
  return response;
};

type SendOTPPayload = {
  phone_number: string;
};

const sendOTPResponseSchema = z.object({
  message: z.string(),
});
type SendOTPResponse = z.infer<typeof sendOTPResponseSchema>;

export const sendOTP = async (
  siteId: number | null,
  data: SendOTPPayload,
): Promise<APIResponse<SendOTPResponse>> => {
  const response = apiPost<SendOTPPayload, SendOTPResponse>(
    '/v2/user/send-otp',
    siteId,
    data,
    sendOTPResponseSchema,
  );

  return response;
};

type VerifyOTPPayload = {
  otp_token: string;
};

const verifyOTPResponseSchema = z.object({
  message: z.string(),
});
type VerifyOTPResponse = z.infer<typeof verifyOTPResponseSchema>;

export const verifyOTP = async (
  siteId: number | null,
  data: VerifyOTPPayload,
): Promise<APIResponse<VerifyOTPResponse>> => {
  const response = apiPost<VerifyOTPPayload, VerifyOTPResponse>(
    '/v2/user/verify-otp',
    siteId,
    data,
    verifyOTPResponseSchema,
  );

  return response;
};
