import i18n from 'i18next';
import { z } from 'zod';
import { Theme } from '../../contextapi/ThemeProvider';
import { Camera } from '../../hooks/graphql/camera';
import { GqlScenarioLabel } from '../../typescript/user/user';
import { hslToRgb } from '../../utils/colors';
import { isString } from '../../utils/typeUtils';

export const scenarioCategories = [
  'housekeeping',
  'action-recognition',
  'ppe',
  'area-control',
  'vehicle-control',
  'digital-inspection',
] as const;

export type ScenarioCategory = (typeof scenarioCategories)[number];

export const scenarioLabelNames = [
  // Housekeeping
  'unattended-object',
  'unattended-object:obstructed-exit',
  'unattended-object:obstructed-vehicle-path',
  'unattended-object:obstructed-pedestrian-path',
  // Action Recognition
  'down',
  'down-fall-trip',
  // PPE
  'ppe:hardhat',
  'ppe:vest',
  'ppe:bumpcap',
  // Area Control
  'person:entering-machine-area',
  'person:climbing-on-machinery', // TODO: Talk with CV team about how it is (if at all) implemented
  'security', // TODO: check if we are actually filtering detections by time somehow
  // Vehicle Control
  'near-hit:person',
  'near-hit:forklift',
  'person',
  'forklift',
  // Digital Inspection
  'road-safety-eq',
] as const;

export const scenarioApiNames = [
  // Housekeeping
  'unattended-object_unattended-object',
  'unattended-object_obstructed-exit',
  'unattended-object_obstructed-vehicle-path',
  'unattended-object_obstructed-pedestrian-path',
  // Action Recognition
  'down_down',
  'down-fall-trip_down-fall-trip',
  // PPE
  'ppe_hardhat',
  'ppe_vest',
  'ppe_bumpcap',
  // Area Control
  'person_entering-machine-area',
  'person_climbing-on-machinery',
  'security_security',
  // Vehicle Control
  'near-hit_person',
  'near-hit_forklift',
  'person_person',
  'forklift_forklift',
  // Digital Inspection
  'road-safety-eq_road-safety-eq',
] as const;

export type ScenarioApiName = (typeof scenarioApiNames)[number];

export type ScenarioName = (typeof scenarioLabelNames)[number];

type ScenarioMetadata = {
  category: ScenarioCategory;

  legacyTitle?: string | null;
  legacyDescription?: string | null;
};

type HslColor = [number, number, number];

type CategoryColors = Record<
  ScenarioCategory,
  {
    fromHsl: HslColor;
    toHsl: HslColor;
  }
>;

const colors: CategoryColors = {
  housekeeping: {
    fromHsl: [30, 80, 40],
    toHsl: [30, 80, 80],
  },
  'action-recognition': {
    fromHsl: [70, 100, 30],
    toHsl: [70, 100, 50],
  },
  ppe: {
    fromHsl: [180, 60, 40],
    toHsl: [180, 60, 70],
  },
  'area-control': {
    fromHsl: [300, 100, 40],
    toHsl: [300, 100, 70],
  },
  'vehicle-control': {
    fromHsl: [280, 100, 30],
    toHsl: [280, 100, 80],
  },
  'digital-inspection': {
    fromHsl: [220, 100, 40],
    toHsl: [220, 100, 80],
  },
};

const scenarioColors: Record<ScenarioApiName, string> = {
  'unattended-object_unattended-object': '#6929c4',
  'unattended-object_obstructed-exit': '#8a3ffc',
  'unattended-object_obstructed-vehicle-path': '#a56eff',
  'unattended-object_obstructed-pedestrian-path': '#be95ff',

  down_down: '#1192e8',
  'down-fall-trip_down-fall-trip': '#33b1ff',

  ppe_hardhat: '#005d5d',
  ppe_vest: '#007d79',
  ppe_bumpcap: '#009d9a',

  'person_entering-machine-area': '#9f1853',
  'person_climbing-on-machinery': '#ac3864',
  security_security: '#b95075',

  'near-hit_person': '#b28600',
  'near-hit_forklift': '#bc9127',
  person_person: '#c59b3f',
  forklift_forklift: '#cea754',

  'road-safety-eq_road-safety-eq': '#accd9d',
};

const scenarioMetadata: Record<ScenarioName, ScenarioMetadata> = {
  'unattended-object': {
    category: 'housekeeping',
    legacyTitle: 'scenarios.unattended_object.title',
    legacyDescription: 'scenarios.unattended_object.description',
  },
  'unattended-object:obstructed-exit': {
    category: 'housekeeping',
  },
  'unattended-object:obstructed-vehicle-path': {
    category: 'housekeeping',
  },
  'unattended-object:obstructed-pedestrian-path': {
    category: 'housekeeping',
  },

  down: {
    category: 'action-recognition',
    legacyTitle: 'scenarios.down.title',
    legacyDescription: 'scenarios.down.description',
  },
  'down-fall-trip': {
    category: 'action-recognition',
    legacyTitle: 'scenarios.down_fall_trip.title',
    legacyDescription: 'scenarios.down_fall_trip.description',
  },

  'ppe:hardhat': {
    category: 'ppe',
    legacyTitle: 'scenarios.ppe.hardhat.title',
    legacyDescription: 'scenarios.ppe.hardhat.description',
  },
  'ppe:vest': {
    category: 'ppe',
    legacyTitle: 'scenarios.ppe.vest.title',
    legacyDescription: 'scenarios.ppe.vest.description',
  },
  'ppe:bumpcap': {
    category: 'ppe',
    legacyTitle: 'scenarios.ppe.bumpcap.title',
    legacyDescription: 'scenarios.ppe.bumpcap.description',
  },

  'person:entering-machine-area': {
    category: 'area-control',
  },
  'person:climbing-on-machinery': {
    category: 'area-control',
  },
  security: {
    category: 'area-control',
    legacyTitle: 'scenarios.security.title',
    legacyDescription: 'scenarios.security.description',
  },

  'near-hit:person': {
    category: 'vehicle-control',
    legacyTitle: 'scenarios.nearhit.person.title',
    legacyDescription: 'scenarios.nearhit.person.description',
  },
  'near-hit:forklift': {
    category: 'vehicle-control',
    legacyTitle: 'scenarios.nearhit.forklift.title',
    legacyDescription: 'scenarios.nearhit.forklift.description',
  },
  person: {
    category: 'vehicle-control',
    legacyTitle: 'scenarios.person.title',
    legacyDescription: 'scenarios.person.description',
  },
  forklift: {
    category: 'vehicle-control',
    legacyTitle: 'scenarios.forklift.title',
    legacyDescription: 'scenarios.forklift.description',
  },

  'road-safety-eq': {
    category: 'digital-inspection',
    legacyTitle: 'scenarios.road_safety_equipment.title',
    legacyDescription: 'scenarios.road_safety_equipment.description',
  },
} as const;

export type Scenario = {
  id: number;
  name: ScenarioName;
  apiName: string;
  category: ScenarioCategory;
  titleKey: string;
  descriptionKey: string;
  primaryColor: string;
  secondaryColor: string;
};

export const sortByDefinedOrder = (a: Scenario, b: Scenario) =>
  scenarioLabelNames.indexOf(a.name) - scenarioLabelNames.indexOf(b.name);

export const sortNamesByDefinedOrder = (a: ScenarioName, b: ScenarioName) =>
  scenarioLabelNames.indexOf(a) - scenarioLabelNames.indexOf(b);

export function parseScenarioName(name: string): ScenarioName | undefined {
  return z.enum(scenarioLabelNames).safeParse(name).data;
}

function formatAsTranslationKey(name: ScenarioCategory | ScenarioName) {
  return name.replaceAll('-', '_').replaceAll(':', '.');
}

export function mapToScenarioName(apiName: string): ScenarioName | undefined {
  const [scenarioName, labelName] = apiName.split('_');

  const scenarioLabel =
    scenarioName === labelName ? scenarioName : `${scenarioName}:${labelName}`;

  return parseScenarioName(scenarioLabel);
}

export function mapToScenarioApiName(name: ScenarioName): ScenarioApiName {
  return scenarioApiNames[scenarioLabelNames.indexOf(name)];
}

export function getScenarioCategoryTitle(category: ScenarioCategory) {
  const translationKey = `scenario_category.${formatAsTranslationKey(category)}.title`;

  return i18n.exists(translationKey) ? i18n.t(translationKey) : category;
}

export function getScenarioTitle(scenario: Scenario) {
  const { name, titleKey } = scenario;

  if (i18n.exists(titleKey)) {
    return i18n.t(titleKey);
  }

  const { legacyTitle } = scenarioMetadata[name];
  if (isString(legacyTitle) && i18n.exists(legacyTitle)) {
    return i18n.t(legacyTitle);
  }

  return name;
}

export function getScenarioDescription(scenario: Scenario): string | null {
  const { name, descriptionKey } = scenario;

  if (i18n.exists(descriptionKey)) {
    return i18n.t(descriptionKey);
  }

  const { legacyDescription } = scenarioMetadata[name];
  if (isString(legacyDescription) && i18n.exists(legacyDescription)) {
    return i18n.t(legacyDescription);
  }

  return null;
}

export function findScenarioById(scenarios: Scenario[], scenarioId?: number) {
  return scenarios.find((scenario) => scenario.id === scenarioId);
}

export function findScenarioByName(
  scenarios: Scenario[],
  scenarioName?: ScenarioName,
) {
  return scenarios.find((scenario) => scenario.name === scenarioName);
}

export function findScenarioByApiName(
  scenarios: Scenario[],
  scenarioApiName?: string,
) {
  return scenarios.find((scenario) => scenario.apiName === scenarioApiName);
}

export function filterScenariosByName(
  scenarios: Scenario[],
  scenarioNames: ScenarioName[],
) {
  return scenarios.filter((scenario) => scenarioNames.includes(scenario.name));
}

export function filterScenariosByApiName(
  scenarios: Scenario[],
  scenarioApiNames: string[],
) {
  return scenarios.filter((scenario) =>
    scenarioApiNames.includes(scenario.apiName),
  );
}

export function filterScenariosByCamera(
  userScenarios: Scenario[],
  camera?: Camera,
) {
  const scenarioNames =
    camera?.cameras_scenarios.map(
      (scenario) => scenario.customer_scenario_label.name,
    ) || [];

  return filterScenariosByName(userScenarios, scenarioNames);
}

function filterScenariosByCategory(scenarios: Scenario[], category: string) {
  return scenarios.filter((scenario) => scenario.category === category);
}

export function groupScenariosByCategory(scenarios: Scenario[]) {
  return scenarioCategories
    .map((category) => ({
      category,
      scenarios: filterScenariosByCategory(scenarios, category),
    }))
    .filter(({ scenarios }) => scenarios.length > 0);
}

function getScenarioColors(scenarioName: ScenarioName, theme: Theme) {
  const apiName = mapToScenarioApiName(scenarioName);
  if (scenarioColors[apiName]) {
    return theme === 'light'
      ? {
          primaryColor: scenarioColors[apiName],
          secondaryColor: '#FFFFFF',
        }
      : {
          primaryColor: scenarioColors[apiName],
          secondaryColor: '#FFFFFF',
        };
  }

  const { category } = scenarioMetadata[scenarioName];
  const { fromHsl, toHsl } = colors[category];

  const scenariosInCategory = scenarioLabelNames.filter(
    (name) => scenarioMetadata[name].category === category,
  );
  const position =
    scenariosInCategory.indexOf(scenarioName) / 2 / scenariosInCategory.length;

  const h = fromHsl[0] + position * (toHsl[0] - fromHsl[0]);
  const s = fromHsl[1] + position * (toHsl[1] - fromHsl[1]);
  const l = fromHsl[2] + position * (toHsl[2] - fromHsl[2]);

  const primaryColor = hslToRgb(h, s / 100, l / 100);
  const secondaryColor = hslToRgb(h, s / 100, l / 100);

  return {
    primaryColor: theme === 'light' ? primaryColor : '#FFFFFF',
    secondaryColor: theme === 'light' ? '#FFFFFF' : secondaryColor,
  };
}

export const mapScenarioLabelToScenario =
  (theme: Theme) =>
  (scenarioLabel: GqlScenarioLabel): Scenario | null => {
    const { id, name, scenario, label, custom_name } = scenarioLabel;

    const parsedName = parseScenarioName(name);
    if (!parsedName) {
      return null;
    }

    const metadata = scenarioMetadata[parsedName];
    if (!metadata) {
      return null;
    }

    const apiName = `${scenario.name}_${label.name}`;
    const titleKey = isString(custom_name)
      ? `scenarios.${custom_name}.title`
      : `scenario.${formatAsTranslationKey(parsedName)}.title`;
    const descriptionKey = isString(custom_name)
      ? `scenarios.${custom_name}.description`
      : `scenario.${formatAsTranslationKey(parsedName)}.description`;
    const { primaryColor, secondaryColor } = getScenarioColors(
      parsedName,
      theme,
    );

    return {
      id,
      name: parsedName,
      apiName,
      category: metadata.category,
      titleKey,
      descriptionKey,
      primaryColor,
      secondaryColor,
    };
  };
