import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { AlertProps } from '../../../typescript/smart-alert/smart-alert';
import { CustomDropdown, DropdownProps } from '../../elements/CustomDropdown';

type Props = {
  alertList: Array<AlertProps>;
  selectedAlertList: Array<AlertProps>;
  onClickSelectedAlertList: (selectedAlerts: Array<DropdownProps>) => void;
};

export function AlertNameFilter({
  alertList,
  selectedAlertList,
  onClickSelectedAlertList,
}: Props) {
  const [allAlertList, setAllAlertList] = useState<Array<DropdownProps>>([]);
  const [selectAllAlerts, setSelectAllAlerts] = useState<boolean>(false);

  useEffect(() => {
    if (alertList) {
      const alerts = alertList.map((item) => ({
        title: item.name,
        value: item.id,
        isChecked: selectedAlertList.some((o2) => item.id === o2.id),
      }));
      setAllAlertList(alerts);
    }
  }, [alertList, selectedAlertList]);

  return (
    <Col md={12}>
      <hr />
      <p className="mb-1 weight-600">{i18n.t('filter.alert.title')}</p>
      <p className="sub-color">{i18n.t('filter.alert.body')}</p>

      <CustomDropdown
        variant="checkbox"
        onSelectedAll={(value, fromChild) => {
          if (!fromChild) {
            const selectAll = allAlertList.map((item) => ({
              title: item.title,
              value: item.value,
              isChecked: value,
            }));

            setAllAlertList(selectAll);
            onClickSelectedAlertList(
              selectAll.filter((alert) => alert.isChecked),
            );
          }

          setSelectAllAlerts(value);
        }}
        selectedAll={selectAllAlerts}
        className="rounded-pill"
        title={`${i18n.t('button.show_all_alerts')}`}
        selectAllTitle={`${i18n.t('button.select_all')}`}
        dropdownData={allAlertList}
        hideContextData
        onClick={(value) => {
          const selectAll = allAlertList.map((item) => {
            if (item.value === value.value) {
              return {
                title: item.title,
                value: item.value,
                isChecked: !item.isChecked,
              };
            }
            return item;
          });
          setAllAlertList(selectAll);
          onClickSelectedAlertList(
            selectAll.filter((alert) => alert.isChecked),
          );
        }}
      />
    </Col>
  );
}
