/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ReportFalseModalBody } from './ReportFalseModalBody';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useTrackEvent } from '../../hooks/track_event';
import { spacing } from '../../scss/spacing';
import {
  isReportFalseFormData,
  ModalFormData,
  ModalOptions,
} from '../../typescript/components/modal';
import { BWButton } from '../elements/BWButton';

type Props = {
  options: ModalOptions;
};

export function ConfirmationModal({ options }: Props) {
  const {
    type,
    show,
    loading,
    icon: IconComponent,
    title,
    doneText,
    cancelText,
    buttonType,
    onClose,
  } = options;
  const trackEvent = useTrackEvent();

  const [formData, setFormData] = React.useState<ModalFormData>({});

  useEffect(() => {
    if (type === 'report') {
      setFormData({
        reason: null,
        comment: null,
      });
    } else {
      setFormData({});
    }
  }, [type]);

  const handleClose = useCallback(
    (reason: 'hide' | 'cancel' | 'confirm') => () => {
      if (loading || !show) {
        return;
      }

      if (type === 'report' && reason === 'confirm') {
        trackEvent('report_false_positive', {
          false_postive_reason: formData.reason ?? 'no_reason_selected',
          false_positive_comment_length: formData.comment?.length,
        });
      } else {
        trackEvent('modal_close', {
          close_action: reason === 'confirm' ? 'submit' : reason,
          modal: type,
        });
      }
      // const simpleReason = reason === 'escape' ? 'cancel' : reason;

      onClose(reason, formData);
    },
    [loading, show, trackEvent, type, onClose, formData],
  );

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        handleClose('confirm')();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleClose]);

  return (
    <Modal
      centered
      className="custom-modal"
      show={show}
      onHide={handleClose('hide')}
      css={css`
        .modal-dialog {
          width: fit-content;

          .modal-content {
            min-height: auto !important;

            .modal-header {
              padding-top: ${spacing(4)};
            }

            .modal-body {
              padding-bottom: ${spacing(6)};
            }
          }
        }
      `}
    >
      <Modal.Header>
        <Modal.Title>
          {IconComponent && <IconComponent className="mb-16" />}
          {title}
        </Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={handleClose('cancel')}
          doNotTrack
        />
      </Modal.Header>

      {isReportFalseFormData(formData) ? (
        <ReportFalseModalBody
          formData={formData}
          onFormUpdate={(data) => setFormData(data)}
        />
      ) : (
        <Modal.Body>
          <Row>
            <Col md={12} className="text-start">
              <p className="sub-color">{options.body}</p>
            </Col>
          </Row>
        </Modal.Body>
      )}

      <Modal.Footer>
        <BWButton
          disabled={loading}
          title={cancelText || undefined}
          className="button-with-loader"
          variant="outline"
          type="button"
          onClick={handleClose('cancel')}
          doNotTrack
        />
        <BWButton
          disabled={loading}
          loading={loading}
          title={doneText || undefined}
          className="button-with-loader"
          variant={buttonType}
          type="button"
          onClick={handleClose('confirm')}
          doNotTrack
        />
      </Modal.Footer>
    </Modal>
  );
}
