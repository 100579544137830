/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import moment from 'moment';
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { getScenarioTitle } from '../../lib/features/scenario';
import { ScenarioLabel } from '../elements/ScenarioLabel';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
}
export function CustomTooltip({ active, payload }: Props) {
  const { theme } = useThemeContext();
  const { customerScenarios } = useScenarioContext();

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    customerScenarios.filter((element) => scenarioList.includes(element.name));

  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius d-flex flex-column gap-3`}
      >
        <p className="m-0 ph-no-capture">{`Date: ${moment(payload[0].payload.range).format('DD MMM, HH:mm')}`}</p>
        {filteredScenario?.map((scenario, index) => (
          <ScenarioLabel
            key={scenario.name}
            scenario={scenario}
            label={`${getScenarioTitle(scenario)}: ${payload[index].value}`}
          />
        ))}
      </div>
    );
  }
  return null;
}
