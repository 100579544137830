import { useMutation } from '@apollo/client';
import { multiFactor, sendEmailVerification } from 'firebase/auth';
import i18n from 'i18next';
import { useAtomValue } from 'jotai';
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendOTP } from '../../apis/api-request';
import { useAuthenticatedUserContext } from '../../contextapi/AuthenticatedUserProvider';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useFeatureFlags } from '../../contextapi/FeatureFlagsProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { UPDATE_USER } from '../../graphql/mutations/user';
import { spacing } from '../../scss/spacing';
import { GqlCurrentUser, GqlUser } from '../../typescript/user/user';
import { siteIdAtom } from '../../utils/atoms';
import { BWButton } from '../elements/BWButton';
import { InputField } from '../elements/InputField';
import { ManageMFAModal } from '../modal/ManageMFAModal';
import { OtpModal } from '../modal/OtpModal';

interface Props {
  user: GqlCurrentUser | GqlUser;
}

export function UserForm({ user }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { refetchUser } = useAuthContext();
  const { firebaseUser } = useAuthenticatedUserContext();
  const { isMFAEnabled } = useFeatureFlags();
  const siteId = useAtomValue(siteIdAtom);

  const phoneInputRef = useRef<string>();

  const isCurrentUser = user.__typename === 'user';
  const isMFAEnrolled = multiFactor(firebaseUser).enrolledFactors.length > 0;
  const isEmailVerified = firebaseUser.emailVerified;

  const [editedUser, setEditedUser] = useState<GqlCurrentUser | null>(
    isCurrentUser ? user : null,
  );

  const [loading, setLoading] = useState(false);
  const [loadingOtpModal, setLoadingOtpModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showMfaModal, setShowMfaModal] = useState(false);

  const [updateUser, onUpdateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    setLoading(false);
    if (onUpdateUser.data) {
      toast.success(i18n.t('toast.success.user_update'));
      refetchUser();
    }
  }, [onUpdateUser.data, refetchUser]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!editedUser) {
      return;
    }
    const { name } = e.target;
    setEditedUser({ ...editedUser, [name]: e.target.value });
  };

  const handlePhoneChange = (value: string) => {
    if (!editedUser) {
      return;
    }
    setEditedUser({ ...editedUser, phoneNo: value, phoneNoVerified: false });
    phoneInputRef.current = value;
  };

  const updateUserInfo = (e: FormEvent<HTMLFormElement>) => {
    if (!editedUser) {
      return;
    }
    e.preventDefault();
    setLoading(true);
    updateUser({
      variables: {
        id: editedUser.id,
        name: editedUser.username,
        title: editedUser.title,
        phone_no: editedUser.phoneNo,
      },
    });
  };

  const handleOtpModalOpen = async () => {
    if (!editedUser) {
      return;
    }
    setLoadingOtpModal(true);
    const response = await sendOTP(siteId, {
      phone_number: editedUser.phoneNo,
    });
    setLoadingOtpModal(false);
    if (response.success) {
      setShowOtpModal(true);
    } else {
      toast.error(response.detail);
    }
  };

  const handleOpenMFAModal = () => {
    if (!editedUser) {
      return;
    }

    if (!isEmailVerified) {
      sendEmailVerification(firebaseUser);
      toast.error(
        i18n.t('toast.error.email_not_verified', { email: user.email }),
      );
      return;
    }

    setShowMfaModal(true);
  };

  return (
    <Form onSubmit={updateUserInfo} className="user-information">
      <InputField
        controlId="formFullName"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="username"
        value={editedUser ? editedUser.username : user.username || ''}
        title={`${i18n.t('input.username.title')}`}
        placeholder={
          isCurrentUser ? `${i18n.t('input.username.placeholder')}` : ''
        }
        onChange={handleChange}
        disabled={!isCurrentUser}
        required
      />

      <InputField
        controlId="formTitle"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="title"
        value={editedUser ? editedUser.title : user.title || ''}
        title={`${i18n.t('input.user_title.title')}`}
        placeholder={`${i18n.t('input.user_title.placeholder')}`}
        onChange={handleChange}
        disabled={!isCurrentUser}
        required
      />

      {isCurrentUser && (
        <div className="mb-12 phone-verification">
          <Form.Label className="weight-500">{`${i18n.t('input.phoneNo.title')}`}</Form.Label>
          <InputGroup>
            <PhoneInput
              controlId="formPhoneNumber"
              className={`flex-1 input-${theme} ph-no-capture`}
              name="phoneNo"
              title={`${i18n.t('input.phoneNo.title')}`}
              international
              placeholder={`${i18n.t('input.phoneNo.placeholder')}`}
              value={editedUser?.phoneNo}
              onChange={handlePhoneChange}
            />
            {(!user.phoneNoVerified ||
              user.phoneNo !== editedUser?.phoneNo) && (
              <BWButton
                className="position-absolute end-0"
                variant="outline"
                type="button"
                title={!loadingOtpModal ? i18n.t('button.verify') : ''}
                disabled={loadingOtpModal}
                loading={loadingOtpModal}
                onClick={handleOtpModalOpen}
              />
            )}
            {isMFAEnabled &&
              user.phoneNoVerified &&
              user.phoneNo === editedUser?.phoneNo && (
                <BWButton
                  className="position-absolute end-0"
                  variant="outline"
                  type="button"
                  title={
                    isMFAEnrolled
                      ? i18n.t('user_profile.unenroll_mfa')
                      : i18n.t('user_profile.enroll_mfa')
                  }
                  disabled={!user.phoneNoVerified}
                  onClick={handleOpenMFAModal}
                />
              )}
          </InputGroup>
        </div>
      )}

      <InputField
        controlId="formEmail"
        className={`mb-12 input-${theme} ph-no-capture`}
        type="email"
        name="email"
        value={editedUser ? editedUser.email : user.email}
        title={`${i18n.t('input.email.title')}`}
        placeholder={`${i18n.t('input.email.placeholder')}`}
        disabled
        readOnly
        required
      />

      {isCurrentUser && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: spacing(7),
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link
                to="/#"
                style={{
                  height: 'auto',
                  textDecoration: 'none',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window._hsp.push(['showBanner']);
                }}
              >
                <BWButton
                  variant="link"
                  style={{
                    color: theme === 'dark' ? 'white' : 'black',
                  }}
                  title={i18n.t('footer.cookie_settings')}
                />
              </Link>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <BWButton
                title={i18n.t('button.cancel')}
                className="button-with-loader"
                variant="outline"
                type="button"
                onClick={() => navigate('/dashboard')}
              />
              <BWButton
                title={i18n.t('button.save')}
                className="button-with-loader ms-12"
                variant="primary"
                type="submit"
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>

          <OtpModal
            show={showOtpModal}
            onClose={() => setShowOtpModal(false)}
            onVerify={async () => {
              await refetchUser();
              setShowOtpModal(false);
            }}
          />

          {isMFAEnabled && (
            <ManageMFAModal
              show={showMfaModal}
              phoneNumber={user.phoneNo}
              onClose={() => {
                setShowMfaModal(false);
              }}
            />
          )}
        </>
      )}
    </Form>
  );
}
