import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  DaysListFilterProp,
  DaysListProps,
  TIME_RANGES,
} from '../typescript/grouping/grouping-observation';

type TimeRangeContextType = {
  timeRange: DaysListProps;
};

const TimeRangeContext = createContext<TimeRangeContextType | undefined>(
  undefined,
);

export const useTimeRangeContext = () => {
  const context = useContext(TimeRangeContext);
  if (!context) {
    throw new Error(
      'useTimeRangeContext must be used within a TimeRangeProvider',
    );
  }

  return context;
};

type Props = PropsWithChildren;

export const TimeRangeProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const queryString =
    (searchParams.get('query') &&
      decodeURI(String(searchParams.get('query')))) ||
    '{}';

  const query: Partial<DaysListFilterProp> = JSON.parse(queryString);

  const [rangeFrom, rangeTo] = query.range || [undefined, undefined];

  const timeRangeSearchParam = query.title
    ? TIME_RANGES.filter((item) => item.text === query.title).map((item) => ({
        ...item,
        value: rangeFrom || item.value,
        end: rangeTo || item.end,
      }))[0]
    : undefined;

  const timeRange = useMemo(() => {
    if (!timeRangeSearchParam) {
      if (pathname.includes('dashboard')) {
        return TIME_RANGES[4];
      }

      return TIME_RANGES[1];
    }

    return timeRangeSearchParam;
  }, [timeRangeSearchParam, pathname]);

  const context = useMemo(
    () => ({
      timeRange,
    }),
    [timeRange],
  );

  return (
    <TimeRangeContext.Provider value={context}>
      {children}
    </TimeRangeContext.Provider>
  );
};
