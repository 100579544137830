import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityObserver from 'react-visibility-observer';
import { PriorityIcon } from './PriorityIcon';
import { useAuthenticatedUserContext } from '../../contextapi/AuthenticatedUserProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationUserInsertInput } from '../../graphql/mutations/observation';
import { useAddBookmark, useDeleteBookmark } from '../../hooks/observations';
import { useTrackEvent } from '../../hooks/track_event';
import {
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
} from '../../typescript/observation/observation';
import { BWButton } from '../elements/BWButton';
import { LazyLoadedImage } from '../elements/LazyLoadedImage';
import { ScenarioLabel } from '../elements/ScenarioLabel';
import { ObservationActions } from '../modal/observation/ObservationActions';

interface Props {
  observation: ObservationProp;
  selectedObservations: boolean;
  hideCameraColumn?: boolean;
  onImageClick: (selectedObservation: ObservationProp) => void;
  isObservationSelected: (isSeleted: boolean, id: number) => void;
  onReportFalseModal: (id: number) => void;
  onSensitiveObservationAction: (
    id: number,
    type: string,
    value: string,
  ) => void;
}

export function ObservationTableRow({
  observation,
  selectedObservations,
  hideCameraColumn = false,
  onImageClick,
  isObservationSelected,
  onReportFalseModal,
  onSensitiveObservationAction,
}: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { siteUsers } = useAuthenticatedUserContext();
  const { user } = useAuthenticatedUserContext();
  const { customerScenarios } = useScenarioContext();
  const trackEvent = useTrackEvent();

  const [addObservationBookmark] = useAddBookmark();
  const [deleteObservationBookmark] = useDeleteBookmark();

  function onBookmark(value: ObservationUserInsertInput) {
    const bookmarkId = observation.observation_users.find(
      (item) => item.user_id === user.id,
    )?.id;

    if (bookmarkId) {
      deleteObservationBookmark({
        variables: {
          id: bookmarkId,
        },
      });
    } else {
      addObservationBookmark({
        variables: {
          data: value,
        },
      });
    }
  }

  const filteredScenario = customerScenarios.filter(
    (scenario) => scenario.name === observation.customer_scenario_label.name,
  );

  const priorityObject = OBSERVATION_PRIORITIES.find(
    (priority) => priority.value === observation.priority,
  );

  const statusText =
    OBSERVATION_STATUSES.find((status) => status.value === observation.status)
      ?.title || 'status.unknown';

  function navigateToDetailsPage() {
    navigate(`/observation/${observation.id}`);
  }

  const filteredAssignee = siteUsers.filter(
    (item) => item.email === observation.responder,
  )[0];

  const handleImageClick = (observation: ObservationProp) => {
    trackEvent('thumbnail_click', {
      action: 'open_observation_quick_view',
    });
    onImageClick(observation);
  };

  const { camera } = observation;

  return (
    <tr
      className={`add-cursor selected-row ${selectedObservations && `selected-row-${theme}`}`}
    >
      <td className="p-0">
        <div className="d-flex align-items-center">
          <div
            className="ps-3 py-3"
            onClick={() => {
              isObservationSelected(!selectedObservations, observation.id);
            }}
            aria-hidden
          >
            <FormCheck
              checked={selectedObservations}
              className="me-12 check-box"
              readOnly
              onChange={() => {
                trackEvent('checkbox_check', {});
              }}
            />
          </div>
          <span
            className="ms-0 ph-no-capture"
            onClick={() => navigateToDetailsPage()}
            aria-hidden
          >
            {moment
              .unix(observation.system_timestamp)
              .format('DD MMM, HH:mm:ss')}
          </span>
        </div>
      </td>

      <td onClick={() => navigateToDetailsPage()}>
        {!filteredScenario[0] ? (
          <span>{i18n.t('td.scenario_not_found')}</span>
        ) : (
          <ScenarioLabel scenario={filteredScenario[0]} />
        )}
      </td>

      {!hideCameraColumn && (
        <td onClick={() => navigateToDetailsPage()} className="text-truncate">
          {camera ? (
            <BWButton
              className="ph-no-capture"
              variant="light"
              title={camera.name}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/camera/${camera.id}`);
              }}
              trackAs="camera_chip_click"
            />
          ) : (
            <span className="camera-name rounded-pill ph-no-capture">
              {i18n.t('td.camera_no_longer_exists')}
            </span>
          )}
        </td>
      )}

      <td onClick={() => navigateToDetailsPage()} className="text-truncate">
        {observation.responder && filteredAssignee ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            className="ph-no-capture"
            style={{ textDecoration: 'none' }}
            to={`/profile/${filteredAssignee.id}`}
          >
            <BWButton
              variant="link"
              title={filteredAssignee.username || observation.responder}
            />
          </Link>
        ) : (
          <span data-ph-capture="true">{i18n.t('td.not_assigned')}</span>
        )}
      </td>

      <td
        data-ph-capture="true"
        className="text-capitalize"
        onClick={() => navigateToDetailsPage()}
      >
        {i18n.t(statusText)}
      </td>

      <td onClick={() => navigateToDetailsPage()}>
        <VisibilityObserver triggerOnce threshold={0.2}>
          <LazyLoadedImage
            onImageClick={handleImageClick}
            observation={observation}
          />
        </VisibilityObserver>
      </td>

      <td onClick={() => navigateToDetailsPage()}>
        <div className="d-flex align-items-center">
          <PriorityIcon priority={priorityObject?.value} />
          <p className="m-0" data-ph-capture="true">
            {i18n.t(priorityObject?.title || 'priority.unknown')}
          </p>
        </div>
      </td>

      <td>
        <div
          className="d-flex justify-content-center align-items-center"
          aria-hidden
        >
          <ObservationActions
            observation={observation}
            onObservationAction={(observation, action, value) => {
              const { id } = observation;
              if (action === 'report') {
                onReportFalseModal(id);
              } else if (action === 'bookmark') {
                onBookmark({ observation_id: `${id}`, user_id: user.id });
              } else if (action === 'view_details') {
                navigateToDetailsPage();
              } else {
                onSensitiveObservationAction(
                  observation.id,
                  action,
                  value || '',
                );
              }
            }}
            isThreeDot
          />
        </div>
      </td>
    </tr>
  );
}
