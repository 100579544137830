import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

let isInitialized = false;

export function initSentry() {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const isEnabled =
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_DEV_ENABLE_SENTRY === 'true';

  if (dsn && isEnabled) {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllInputs: true,
          maskAllText: true,
          blockAllMedia: true,
        }),
        Sentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    isInitialized = true;
  }
}

export function captureExceptionInSentry(error: Error) {
  if (!isInitialized) return;

  Sentry.captureException(error);
}
