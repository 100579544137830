import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useObservationImage } from '../../../hooks/image';
import { ObservationProp } from '../../../typescript/observation/observation';
import { ImageLoader } from '../../elements/ImageLoader';
import {
  AnnotationRef,
  ImageWithAnnotation,
} from '../../elements/ImageWithAnnotation';

type Props = {
  observation: ObservationProp;
  showLabel: boolean;
  showDangerZone?: boolean;
  onImageDimensionsChange?: (width: number, height: number) => void;
};

export const ObservationsModalImage = forwardRef<AnnotationRef | null, Props>(
  (
    { observation, showLabel, showDangerZone, onImageDimensionsChange },
    ref,
  ) => {
    // Context
    const { theme } = useThemeContext();
    const { imageUrl, isImageLoading, fetchImageUrl } = useObservationImage(
      observation,
      'original',
    );

    // Ref
    const annotationRef = useRef<AnnotationRef>(null);

    // State
    const [isImageSizeKnown, setIsImageSizeKnown] = useState<boolean>(false);
    const [imageDimensions, setImageDimensions] = useState<{
      width: number;
      height: number;
    }>();

    useImperativeHandle<AnnotationRef | null, AnnotationRef | null>(
      ref,
      () => annotationRef.current,
    );

    const calculateImageDimensions = useCallback(() => {
      const imageRef = annotationRef.current?.imageRef;
      if (imageRef?.current) {
        const { width, height } = imageRef.current;
        if (
          imageDimensions?.width !== width ||
          imageDimensions?.height !== height
        ) {
          setImageDimensions({ width, height });
          if (onImageDimensionsChange) {
            onImageDimensionsChange(width, height);
          }
        }
      }
    }, [imageDimensions, onImageDimensionsChange]);

    const onImageLoad = useCallback(() => {
      calculateImageDimensions();
      setIsImageSizeKnown(true);
    }, [calculateImageDimensions]);

    const onImageError = useCallback(() => {
      setIsImageSizeKnown(false);
      fetchImageUrl();
    }, [fetchImageUrl]);

    useEffect(() => {
      calculateImageDimensions();
      window.addEventListener('resize', calculateImageDimensions);
      return () =>
        window.removeEventListener('resize', calculateImageDimensions);
    }, [calculateImageDimensions]);

    useEffect(() => {
      if (!imageUrl) {
        setIsImageSizeKnown(false);
        fetchImageUrl();
      }
    }, [fetchImageUrl, imageUrl]);

    return isImageLoading || !imageUrl ? (
      <ImageLoader
        style={{
          width: imageDimensions?.width || '100%',
          height: imageDimensions?.height || '100%',
        }}
        className={`loading-image loading-image-${theme}`}
      />
    ) : (
      <div
        style={{
          width: isImageSizeKnown ? '100%' : imageDimensions?.width || '100%',
          height: isImageSizeKnown ? '100%' : imageDimensions?.height || '100%',
        }}
      >
        <ImageWithAnnotation
          ref={annotationRef}
          className="ph-no-capture"
          containerWidth="100%"
          containerHeight="100%"
          hideLabel={!showLabel}
          isInteractive
          onLoad={onImageLoad}
          onError={onImageError}
          imageUrl={imageUrl}
          observation={observation}
          showDangerZone={showDangerZone}
          crossOrigin="anonymous"
        />
      </div>
    );
  },
);

ObservationsModalImage.displayName = 'ObservationsModalImage';
