import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { merge } from 'ts-deepmerge';
import { useAuthenticatedUserContext } from './AuthenticatedUserProvider';
import { ObservationProp } from '../typescript/observation/observation';
import {
  DEFAULT_FEATURE_FLAGS,
  GqlFeatureFlags,
} from '../typescript/user/user';
import { isNumber } from '../utils/typeUtils';

type FeatureFlagsContextType = {
  featureFlags: GqlFeatureFlags;
  isMFAEnabled: boolean;
  isAnnotatedObservation: (observation?: ObservationProp) => boolean;
  overrideFeatureFlags: (overrides: GqlFeatureFlags) => void;
};

const FeatureFlagContext = createContext<FeatureFlagsContextType | null>(null);

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider',
    );
  }

  return context;
};

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuthenticatedUserContext();

  const showDevTools = process.env.REACT_APP_DEV_SHOW_DEV_TOOLS === 'true';

  const [overrides, setOverrides] = useState<GqlFeatureFlags>(
    showDevTools
      ? JSON.parse(localStorage.getItem('feature_flags') || '{}')
      : {},
  );

  const featureFlags = useMemo(
    () =>
      merge(
        DEFAULT_FEATURE_FLAGS,
        user.customer.feature_flags ?? {},
        overrides ?? {},
      ),
    [overrides, user.customer.feature_flags],
  );

  const overrideFeatureFlags = useCallback(
    (overrides: GqlFeatureFlags) => {
      if (showDevTools) {
        setOverrides(overrides);
        localStorage.setItem('feature_flags', JSON.stringify(overrides));
      }
    },
    [showDevTools],
  );

  useEffect(() => {
    const phEnabled = localStorage.getItem('ph_enabled') === 'true';
    if (featureFlags.enable_posthog !== phEnabled) {
      if (featureFlags.enable_posthog) {
        localStorage.setItem('ph_enabled', String(featureFlags.enable_posthog));
      } else {
        localStorage.removeItem('ph_enabled');
      }

      window.location.reload();
    }
  }, [featureFlags.enable_posthog]);

  const isAnnotatedObservation = useCallback(
    (observation?: ObservationProp) => {
      const minObservationId = featureFlags.observations.show_annotation;
      if (!observation || !isNumber(minObservationId)) {
        return false;
      }

      return observation.id < minObservationId;
    },
    [featureFlags.observations.show_annotation],
  );

  const context = useMemo(
    () => ({
      featureFlags,
      isMFAEnabled: featureFlags.enable_mfa,
      isAnnotatedObservation,
      overrideFeatureFlags,
    }),
    [featureFlags, isAnnotatedObservation, overrideFeatureFlags],
  );

  return (
    <FeatureFlagContext.Provider value={context}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
