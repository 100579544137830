import { useQuery } from '@apollo/client';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AlertOverview } from '../components/alert-detail/AlertOverview';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { Loader } from '../components/elements/Loader';
import { ObservationList } from '../components/observation/ObservationList';
import { ALERT_INCIDENTS_BY_ID } from '../graphql/queries/alerts';
import { AlertIncidents } from '../typescript/smart-alert/smart-alert';

export const AlertDetailsPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const [alert, setAlert] = useState<AlertIncidents>();

  // GraphQL
  const { loading, error, data, refetch } = useQuery(ALERT_INCIDENTS_BY_ID, {
    variables: {
      id: Number(slug),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      if (data.alert) {
        setAlert(data.alert);
      } else {
        toast.error(i18n.t('toast.error.alert_not_exists'), {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
    }
  }, [data, navigate, refetch]);

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <div className="alert-detail">
      <div>
        <Breadcrumb
          title={`${i18n.t('smart_notifications.title')}`}
          toUrl="/alerts"
        />
      </div>
      <Row>
        <Col md={12} className="mb-32">
          <h5 className="m-0">{i18n.t('alert_detail.title')}</h5>
        </Col>
        {loading && (
          <Col
            md={12}
            className="vh-100 d-flex justify-content-center align-items-center"
          >
            <Loader main />
          </Col>
        )}
        {alert && (
          <>
            <AlertOverview alert={alert} />
            <Col md={12} className="mb-24" key="buttons">
              <h6 className="mb-20">
                {i18n.t('alert_detail.observation_overview')}
              </h6>
              <ObservationList variant="alert" alertId={alert.id} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
