import i18n from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { AlertNameFilter } from './filterModal/AlertNameFilter';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { WhereQueryProps } from '../../graphql/queries/alerts';
import { getScenarioTitle, Scenario } from '../../lib/features/scenario';
import { CameraProps } from '../../typescript/camera/camera';
import { AlertProps } from '../../typescript/smart-alert/smart-alert';
import { BWButton } from '../elements/BWButton';
import { CameraFilter } from '../observation/filterModal/CameraFilter';
import { DateTimeFilter } from '../observation/filterModal/DateTimeFilter';
import { ScenarioFilter } from '../observation/filterModal/ScenarioFilter';

interface Props {
  data: WhereQueryProps | null;
  show: boolean;
  alertRules: Array<AlertProps> | [];

  onApplyClick: (obj: WhereQueryProps | null) => void;
  onCloseClick: () => void;
}

type ScenarioAndActive = [Scenario, boolean];

export function AlertsFilterModal({
  data,
  show,
  alertRules,
  onApplyClick,
  onCloseClick,
}: Props) {
  const { theme } = useThemeContext();
  const { customerScenarios } = useScenarioContext();

  const { cameraList } = useCameraContext();

  const [selectedAlertList, setSelectedAlertList] = useState<Array<AlertProps>>(
    [],
  );
  const [selectedCameraList, setSelectedCameraList] = useState<
    Array<CameraProps>
  >([]);
  const [selectedScenarioList, setSelectedScenarioList] = useState<
    ScenarioAndActive[]
  >([]);

  const [filterData, setFilterData] = useState<WhereQueryProps | null>(data);

  const isRemoveButton =
    filterData ||
    selectedCameraList.length !== 0 ||
    selectedScenarioList.length !== 0;

  useEffect(() => {
    const subscribe = async () => {
      if (data?.alert_incident_observations) {
        const filterArray = cameraList.filter(
          (item) =>
            data?.alert_incident_observations?.observation?.camera_id?._in.indexOf(
              item.sql_id,
            ) !== -1,
        );
        setSelectedCameraList(filterArray);
      }
      if (data?.alert_rule?.id) {
        const filterAlertArray = alertRules.filter(
          (item) => data?.alert_rule?.id?._in.indexOf(item.id) !== -1,
        );
        setSelectedAlertList(filterAlertArray);
      }
      const filterScenarioArray = customerScenarios.map(
        (item) =>
          [
            {
              ...item,
            },
            data?.alert_rule?.customer_scenario_label_id
              ? data?.alert_rule.customer_scenario_label_id?._in.indexOf(
                  item.id || 0,
                ) !== -1
              : false,
          ] satisfies ScenarioAndActive,
      );

      setSelectedScenarioList(filterScenarioArray);
    };
    subscribe();
  }, [cameraList, customerScenarios, data, alertRules]);

  const activeScenarios = selectedScenarioList
    .filter(([_, active]) => active)
    .map(([item]) => item);

  return (
    <Modal
      centered
      className="custom-modal"
      show={show}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.filter.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0">
          <Col md={12}>
            {filterData?.created_at !== undefined && [
              <p className="mb-1 weight-600 " key="title">
                {i18n.t('filter.date_and_time.title')}
              </p>,
              <BWButton
                key="button"
                title={`
                  ${moment(filterData?.created_at._gte).format('YYYY-MM-DD, HH:mm:ss')}
                  ~
                  ${moment(filterData?.created_at._lte).format('YYYY-MM-DD, HH:mm:ss')}
                `}
                className="button-with-loader mb-12 ph-no-capture"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.created_at = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]}

            {selectedAlertList.length !== 0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.alert.title')}
              </p>,
              <div key="value">
                {selectedAlertList.map((item) => (
                  <BWButton
                    key="button"
                    title={item.name}
                    className="mb-2 me-2 ph-no-capture"
                    variant={`${theme}`}
                    type="button"
                    rightIcon={CancelIcon}
                    onClick={() => {
                      const updateFilter = { ...filterData };
                      const activeAlertList = selectedAlertList.filter(
                        (filterItem) => filterItem.id !== item.id,
                      );
                      updateFilter.alert_rule =
                        activeAlertList.length !== 0
                          ? {
                              id: {
                                _in: activeAlertList.map((alert) => alert.id),
                              },
                            }
                          : undefined;

                      setFilterData(updateFilter);
                      setSelectedAlertList(activeAlertList);
                    }}
                  />
                ))}
              </div>,
            ]}

            {selectedCameraList.length !== 0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.camera.title')}
              </p>,
              <div key="value">
                {selectedCameraList.map((item) => (
                  <BWButton
                    key="button"
                    title={item.name}
                    className="mb-2 me-2 ph-no-capture"
                    variant={`${theme}`}
                    type="button"
                    rightIcon={CancelIcon}
                    onClick={() => {
                      const updateFilter = { ...filterData };
                      const activeCameraList = selectedCameraList.filter(
                        (filterItem) => filterItem.sql_id !== item.sql_id,
                      );
                      updateFilter.alert_incident_observations =
                        activeCameraList.length !== 0
                          ? {
                              observation: {
                                camera_id: {
                                  _in: activeCameraList.map(
                                    (camera) => camera.sql_id,
                                  ),
                                },
                              },
                            }
                          : undefined;
                      setFilterData(updateFilter);
                      setSelectedCameraList(activeCameraList);
                    }}
                  />
                ))}
              </div>,
            ]}

            {activeScenarios.length !== 0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.scenarios.title')}
              </p>,
              <div key="value">
                {activeScenarios.map((item) => (
                  <BWButton
                    key={item.name}
                    title={getScenarioTitle(item)}
                    className="mb-2 me-2"
                    variant={`${theme}`}
                    type="button"
                    rightIcon={CancelIcon}
                    onClick={() => {
                      const updateScenarioList = selectedScenarioList.map(
                        ([scenario, active]) =>
                          scenario.id === item.id
                            ? ([
                                { ...scenario },
                                false,
                              ] satisfies ScenarioAndActive)
                            : ([scenario, active] satisfies ScenarioAndActive),
                      );
                      const activeScenarioList = updateScenarioList
                        .filter(([_, active]) => active)
                        .map(([scenario]) => scenario);

                      const updateFilter = { ...filterData };

                      updateFilter.alert_rule =
                        activeScenarioList.length !== 0
                          ? {
                              customer_scenario_label_id: {
                                _in: activeScenarioList.map(
                                  (scenario) => scenario.id,
                                ),
                              },
                            }
                          : undefined;
                      setFilterData(updateFilter);
                      setSelectedScenarioList(updateScenarioList);
                    }}
                  />
                ))}
              </div>,
            ]}

            {/* {filterData?.status !== undefined && [
              <p className="mb-1 weight-600 " key="title">{i18n.t('filter.status.title')}</p>,
              <Button
                key="button"
                title={i18n.t(filteredStatus[0]?.title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.status = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]} */}

            {/* {filterData?.priority !== undefined && [
              <p className="mb-1 weight-600 " key="title">{i18n.t('filter.priority.title')}</p>,
              <Button
                key="button"
                title={i18n.t(filteredPriority[0].title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.priority = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]} */}

            {isRemoveButton && (
              <BWButton
                title={i18n.t('button.remove_filters')}
                className="button-with-loader ms-auto"
                variant="outline"
                type="button"
                onClick={() => {
                  const filterScenarioArray = selectedScenarioList.map(
                    (item) => ({
                      ...item,
                      active: false,
                    }),
                  );

                  setSelectedScenarioList(filterScenarioArray);
                  setFilterData(null);
                  setSelectedAlertList([]);
                  setSelectedCameraList([]);
                }}
              />
            )}
          </Col>

          <DateTimeFilter
            onChange={(dates: DateRange | null) => {
              const convertedDates = (dates || []).map((date: Date) =>
                moment(date).unix(),
              );
              const updateFilter = { ...filterData };
              const start = Number(convertedDates[0]);
              const end = Number(convertedDates[1]);
              const timestamp = {
                _gte: moment.unix(start).utc().format('YYYY-MM-DDTHH:mm:ss'),
                _lte: moment.unix(end).utc().format('YYYY-MM-DDTHH:mm:ss'),
              };

              updateFilter.created_at = timestamp;
              setFilterData(updateFilter);
            }}
          />

          <AlertNameFilter
            alertList={alertRules}
            selectedAlertList={selectedAlertList}
            onClickSelectedAlertList={(alert) => {
              const updateFilter = { ...filterData };
              const id = alert.map((item) => Number(item.value));
              updateFilter.alert_rule =
                alert.length !== 0
                  ? {
                      id: {
                        _in: id,
                      },
                    }
                  : undefined;
              setFilterData(updateFilter);
              setSelectedAlertList(
                alertRules.filter((o1) => id.some((o2) => o1.id === o2)),
              );
            }}
          />
          <CameraFilter
            cameraList={cameraList}
            selectedCameraList={selectedCameraList}
            onClickSelectedCameraList={(cameras) => {
              const updateFilter = { ...filterData };
              const id = cameras.map((item) => Number(item.value));
              updateFilter.alert_incident_observations =
                cameras.length !== 0
                  ? {
                      observation: {
                        camera_id: {
                          _in: id,
                        },
                      },
                    }
                  : undefined;

              setFilterData(updateFilter);
              setSelectedCameraList(
                cameraList.filter((o1) => id.some((o2) => o1.sql_id === o2)),
              );
            }}
          />

          <ScenarioFilter
            selectedScenarios={selectedScenarioList}
            onClick={(scenarios) => {
              const updateFilter = { ...filterData };
              const id = scenarios
                .filter(([_, active]) => active)
                .map(([sceanrio]) => sceanrio.id);
              updateFilter.alert_rule =
                scenarios.length !== 0
                  ? {
                      customer_scenario_label_id: {
                        _in: id,
                      },
                    }
                  : undefined;
              setFilterData(updateFilter);
              setSelectedScenarioList(scenarios);
            }}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <BWButton
          title={i18n.t('button.cancel')}
          className="button-with-loader"
          variant="outline"
          type="button"
          onClick={onCloseClick}
        />
        <BWButton
          title={i18n.t('button.confirm')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={() => {
            onApplyClick(filterData);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
