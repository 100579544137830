import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import i18next from 'i18next';
import { toast } from 'react-toastify';

export function initFirebase() {
  const firebaseConfig = JSON.parse(
    process.env.REACT_APP_FIREBASE_CONFIG || '{}',
  );
  initializeApp(firebaseConfig);
}

export async function signOutWithNotification() {
  toast.error(i18next.t('toast.error.token_expired'), {
    toastId: 'token_expired',
  });
  await signOut(getAuth());
}

export async function fetchValidIdToken() {
  const idToken = await getAuth().currentUser?.getIdToken();

  if (!idToken) {
    signOutWithNotification();
  }

  return idToken;
}
