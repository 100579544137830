import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_DATA } from '../../graphql/queries/dashboard';

type ObservationsFilter = {
  is_false_positive: {
    _eq: boolean;
  };
  system_timestamp?: {
    _gte: number;
    _lte: number;
  };
  responder?: {
    _eq: string;
  };
  status?: {
    _eq: 'open';
  };
};

type AlertsFilter = {
  created_at: {
    _gte: string;
    _lte: string;
  };
};

export type GetDashboardDataQueryVariables = {
  whereToday: ObservationsFilter;
  whereTotal: ObservationsFilter;
  whereTotalPrevious: ObservationsFilter;
  whereAssigned: ObservationsFilter;
  whereOpenedAssigned: ObservationsFilter;
  whereAlert: AlertsFilter;
};

type AggregateResponse = {
  aggregate: {
    count: number;
  };
};

export type GetGroupsQueryResponse = {
  observationsToday: AggregateResponse;
  observationsTotal: AggregateResponse;
  observationsTotalPrevious: AggregateResponse;
  observationsAssigned: AggregateResponse;
  openAssignedObservations: AggregateResponse;
  alertsTotal: AggregateResponse;
  onlineCameras: AggregateResponse;
  offlineCameras: AggregateResponse;
};

export const useGetDashboardData = (
  variables: GetDashboardDataQueryVariables,
) =>
  useQuery<GetGroupsQueryResponse, GetDashboardDataQueryVariables>(
    GET_DASHBOARD_DATA,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );
