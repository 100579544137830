/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { AlertAction } from './multiAction/AlertAction';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { GET_ALERT_SITE_CHANNEL } from '../../graphql/queries/alerts';
import {
  ActionProps,
  emptyMultiAction,
  MultiActionProps,
} from '../../typescript/smart-alert/smart-alert';
import { BWButton } from '../elements/BWButton';

type Props = {
  className?: string;
  selectedActionList: Array<MultiActionProps>;
  onAction: (action: Array<MultiActionProps>) => void;
};

export function MultiAlertAction({
  className,
  selectedActionList,
  onAction,
}: Props) {
  const [actionList, setActionList] = useState<Array<ActionProps>>([]);

  const { data } = useQuery(GET_ALERT_SITE_CHANNEL);

  useEffect(() => {
    if (data) {
      setActionList(data.alert_site_channel);
    }
  }, [data]);

  const lastAction =
    selectedActionList.length > 0
      ? selectedActionList[selectedActionList.length - 1]
      : null;
  const isLastActionSelected = lastAction?.action !== undefined;
  const areUsersSelected = (lastAction?.userIds || []).length > 0;
  const isDeviceNamed = (lastAction?.iot || '').length > 0;

  const isLastActionCompleted =
    isLastActionSelected && (areUsersSelected || isDeviceNamed);

  const disableAddNewAction =
    (lastAction && !isLastActionCompleted) || selectedActionList.length >= 3;

  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('alert_action.title')}</p>
      <p className="sub-color">{i18n.t('alert_action.body')}</p>
      {selectedActionList.map((item, index) => {
        if (item.action) {
          return (
            <AlertAction
              key={item.action.id}
              className={`action-item ${index !== selectedActionList.length - 1 && 'mb-12'}`}
              actionList={actionList.filter(
                (o1) =>
                  !selectedActionList
                    .slice(0, index)
                    .some((o2) => o1.id === o2?.action?.id),
              )}
              selectedAction={item}
              onAction={(action) => {
                const updatedList = [...selectedActionList];
                updatedList[index] = action;
                onAction(updatedList);
              }}
              onDelete={(action) => {
                const updatedList = selectedActionList.filter(
                  (filter) => filter !== action,
                );
                onAction(updatedList);
              }}
            />
          );
        }
        return (
          <AlertAction
            key={String(index + 1)}
            className="action-item"
            actionList={actionList.filter(
              (o1) =>
                !selectedActionList
                  .slice(0, index)
                  .some((o2) => o1.id === o2?.action?.id),
            )}
            selectedAction={item}
            onAction={(action) => {
              const updatedList = [
                ...selectedActionList.slice(0, index),
                action,
              ];
              onAction(updatedList);
            }}
          />
        );
      })}
      <BWButton
        disabled={disableAddNewAction}
        icon={AddIcon}
        className="mt-12"
        variant="outline"
        type="button"
        title={i18n.t('button.add_action')}
        onClick={() => {
          const addNewAction = [...selectedActionList, emptyMultiAction];
          onAction(addNewAction);
        }}
      />
    </Col>
  );
}
