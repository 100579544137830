/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-props-no-spreading */
import i18n from 'i18next';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ReactComponent as DoneIcon } from '../assets/icons/done.svg';
import { ReactComponent as LoadingIcon } from '../assets/icons/loading.svg';
import { BottomToast } from '../components/elements/BottomToast';
import { NavigationSidebar } from '../components/nav/NavigationSidebar';
import { useCSVExportContext } from '../contextapi/CSVExportProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';

export const ProtectedLayout = () => {
  const { csvExportStatus } = useCSVExportContext();
  const { theme } = useThemeContext();

  return (
    <div className="master-layout">
      <NavigationSidebar />
      <Container
        fluid="lg"
        className="mx-master d-flex flex-column justify-context-center"
      >
        <Outlet />
      </Container>
      {csvExportStatus && (
        <BottomToast
          icon={csvExportStatus === 'pending' ? LoadingIcon : DoneIcon}
          title={
            csvExportStatus === 'pending'
              ? `${i18n.t('bottom_toast.generating_csv_report')}`
              : `${i18n.t('bottom_toast.report_successfully_created')}`
          }
          variant={theme}
          buttonText={`${i18n.t('button.download')}`}
        />
      )}
    </div>
  );
};
