import { getAuth } from 'firebase/auth';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { z } from 'zod';

export type Theme = 'light' | 'dark';

type ThemeContextType = {
  setTheme: (theme: Theme) => void;
  theme: Theme;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeContext = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};

type Props = PropsWithChildren<{
  override?: Theme;
}>;

export function ThemeProvider({ override, children }: Props) {
  const lastUsedTheme = z
    .enum(['light', 'dark'])
    .safeParse(localStorage.getItem('theme')).data;

  const [theme, setTheme] = useState(lastUsedTheme || 'light');
  const finalTheme =
    override ?? (getAuth().currentUser === null ? 'light' : theme);

  const ThemeProviderValue = useMemo(
    () => ({
      setTheme: (theme: Theme) => {
        setTheme(theme);
        localStorage.setItem('theme', theme);
      },
      theme: finalTheme,
    }),
    [finalTheme],
  );

  useEffect(() => {
    document.documentElement.classList.add(`theme-${finalTheme}`);

    return () => {
      document.documentElement.classList.remove(`theme-${finalTheme}`);
    };
  }, [finalTheme]);

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      {children}
    </ThemeContext.Provider>
  );
}
