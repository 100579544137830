import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA = gql`
  query GET_DASHBOARD_DATA(
    $whereToday: observations_bool_exp
    $whereTotal: observations_bool_exp
    $whereTotalPrevious: observations_bool_exp
    $whereAssigned: observations_bool_exp
    $whereOpenedAssigned: observations_bool_exp
    $whereAlert: alert_incidents_bool_exp
  ) {
    observationsToday: observations_aggregate(where: $whereToday) {
      aggregate {
        count
      }
    }
    observationsTotal: observations_aggregate(where: $whereTotal) {
      aggregate {
        count
      }
    }
    observationsTotalPrevious: observations_aggregate(
      where: $whereTotalPrevious
    ) {
      aggregate {
        count
      }
    }
    observationsAssigned: observations_aggregate(where: $whereAssigned) {
      aggregate {
        count
      }
    }
    openAssignedObservations: observations_aggregate(
      where: $whereOpenedAssigned
    ) {
      aggregate {
        count
      }
    }

    onlineCameras: cameras_aggregate(where: { status: { _eq: ONLINE } }) {
      aggregate {
        count
      }
    }
    offlineCameras: cameras_aggregate(where: { status: { _eq: OFFLINE } }) {
      aggregate {
        count
      }
    }

    alertsTotal: alert_incidents_aggregate(where: $whereAlert) {
      aggregate {
        count
      }
    }
    openAssignedObservations: observations_aggregate(
      where: $whereOpenedAssigned
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CHART_DATA = gql`
  query GET_CHART_DATA($from: Int!, $until: Int!, $camera_ids: [Int]) {
    chartData: observation_group_statistics_list(
      from: $from
      until: $until
      camera_ids: $camera_ids
    ) {
      id
      scenario_names
      timeseries {
        count
        range
      }
    }
  }
`;

// Observations Query Props
export type WhereQueryProps = {
  priority?: { _eq: string };
  status?: { _eq: string };
  customer_scenario_label?: { label: { name: { _in: Array<string> } } };
  customer_scenario_label_id?: { _in: Array<number> };
  responder?: { _eq: string };
  camera_id?: { _in: Array<number> };
  created?: { _gte: number; _lte: number };
  system_timestamp?: { _gte: number; _lte: number };
  is_false_positive?: { _eq: false };
};

export type ObservationsQueryProps = {
  limit: number;
  offset: number;
  where?: WhereQueryProps;
};
