import React, { PropsWithChildren } from 'react';
import { fontSize, spacing } from '../../scss/spacing';

type Props = PropsWithChildren<{
  backgroundColor?: string;
  color?: string;
}>;

export const Badge = ({
  children,
  backgroundColor = '#D0DEFF',
  color = '#2064D5',
}: Props) => (
  <span
    className="ph-no-capture"
    style={{
      padding: `0 ${spacing(2)}`,
      borderRadius: spacing(1),
      height: spacing(6),
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: fontSize(1),
      whiteSpace: 'nowrap',
      backgroundColor,
      color,
    }}
  >
    {children}
  </span>
);
