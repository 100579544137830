/** @jsxImportSource @emotion/react */
import i18n from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { ScenarioButton } from './ScenarioButton';
import { ReactComponent as LegendBar } from '../../../assets/icons/legend-bar.svg';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { Camera } from '../../../hooks/graphql/camera';
import { useGetChartDataQuery } from '../../../hooks/graphql/chart';
import { findScenarioById, Scenario } from '../../../lib/features/scenario';
import { GRAY_MIDDLE, GRAY_WARM_DARKER_AA } from '../../../scss/colors';
import { spacing } from '../../../scss/spacing';
import { isEmptyArray } from '../../../utils/typeUtils';
import { CustomSwitch } from '../../elements/CustomSwitch';
import { HeatMapImageWithDangerZone } from '../HeatMapImageWithDangerZone';
import { TitleWithContent } from '../TitleWithContent';

type Props = {
  camera: Camera;
  scenarioId?: number;
  onScenarioChange?: (scenario: Scenario) => void;
};

export function CameraHeatMap({ camera, scenarioId, onScenarioChange }: Props) {
  const { theme } = useThemeContext();
  const { timeRange } = useTimeRangeContext();
  const {
    customerScenarios,
    getScenariosByCamera,
    getScenariosByObservationSets,
  } = useScenarioContext();
  const [showDangerZones, setShowDangerZones] = useState(false);

  const { data } = useGetChartDataQuery({
    from: timeRange.value,
    until: timeRange.end,
    camera_ids: [camera.id],
  });

  const cameraScenarios = getScenariosByCamera(camera);

  const observedScenarios = useMemo(
    () => getScenariosByObservationSets(data?.chartData || []),
    [data?.chartData, getScenariosByObservationSets],
  );

  const visibleScenarioNames = Array.from(
    new Set([...cameraScenarios, ...observedScenarios]),
  ).map((scenario) => scenario.name);

  const visibleScenarios = customerScenarios.filter((scenario) =>
    visibleScenarioNames.includes(scenario.name),
  );

  const scenariosWithHeatmap = visibleScenarios.filter((scenario) =>
    observedScenarios.some((s) => s.id === scenario.id),
  );

  const scenariosWithoutHeatmap = visibleScenarios.filter(
    (scenario) => !observedScenarios.some((s) => s.id === scenario.id),
  );

  const selectedScenario =
    findScenarioById(scenariosWithHeatmap, scenarioId) ||
    scenariosWithHeatmap.at(0);

  const changeScenario = useCallback(
    (scenario: Scenario) => {
      if (onScenarioChange) {
        onScenarioChange(scenario);
      }
    },
    [onScenarioChange],
  );

  useEffect(() => {
    if (!scenarioId) {
      return;
    }

    const scenario = findScenarioById(visibleScenarios, scenarioId);
    if (!scenario && !isEmptyArray(visibleScenarios)) {
      changeScenario(visibleScenarios[0]);
    }
  }, [changeScenario, scenarioId, visibleScenarios]);

  return (
    <Card
      className={`card-${theme} border border-${theme} border-radius overflow-hidden`}
    >
      <Card.Body
        className="g-0"
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          position: 'relative',
          padding: 0,
        }}
      >
        <Col
          md={9}
          style={{
            background: 'black',
            display: 'flex',
            alignItems: 'center',
            minHeight: 390,
          }}
        >
          <HeatMapImageWithDangerZone
            camera={camera}
            scenario={selectedScenario}
            showDangerZones={showDangerZones}
          />
        </Col>
        <Col
          md={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <h3
            style={{
              height: spacing(9),
              padding: spacing(4),
              margin: 0,
              lineHeight: 'normal',
              borderBottom: `1px solid ${theme === 'light' ? GRAY_MIDDLE : GRAY_WARM_DARKER_AA}`,
            }}
          >
            {`${i18n.t('camera_detail.scenarios')} (${visibleScenarios.length})`}
          </h3>
          <div
            style={{
              overflowX: 'auto',
              flex: '1 1 0',
              marginBottom: -1,
              minHeight: 120,
            }}
          >
            {scenariosWithHeatmap.map((scenario, index) => {
              const isScenarioFoundCamera = cameraScenarios.some(
                (s) => s.id === scenario.id,
              );
              return (
                <ScenarioButton
                  key={scenario.name}
                  scenario={scenario}
                  isSelected={scenario.id === selectedScenario?.id}
                  onClick={() => changeScenario(scenario)}
                  isDisabled={
                    !observedScenarios.some((s) => s.id === scenario.id)
                  }
                  isFirst={index === 0}
                  overlayText={
                    isScenarioFoundCamera
                      ? undefined
                      : 'camera_detail.scenario.heatmap.with_data.overlay'
                  }
                />
              );
            })}

            {scenariosWithoutHeatmap.map((scenario) => (
              <ScenarioButton
                key={scenario.name}
                scenario={scenario}
                isSelected={scenario.id === selectedScenario?.id}
                onClick={() => changeScenario(scenario)}
                isDisabled={
                  !observedScenarios.some((s) => s.id === scenario.id)
                }
                overlayText="camera_detail.scenario.heatmap.without_data.overlay"
              />
            ))}
          </div>

          <TitleWithContent
            title={`${i18n.t('camera_detail.display_options')}`}
          >
            <CustomSwitch
              label={`${i18n.t('switch.danger_zone')}`}
              checked={showDangerZones}
              onChange={() => setShowDangerZones(!showDangerZones)}
            />
          </TitleWithContent>

          <TitleWithContent title={`${i18n.t('camera_detail.legend')}`}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className="m-0 me-12">{i18n.t('camera_detail.legend.less')}</p>
              <LegendBar />
              <p className="m-0 ms-12">{i18n.t('camera_detail.legend.more')}</p>
            </div>
          </TitleWithContent>
        </Col>
      </Card.Body>
    </Card>
  );
}
