import { useQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/queries/user';
import { GqlCurrentUser } from '../../typescript/user/user';

type GetUserQueryResponse = {
  user: [GqlCurrentUser];
};

export const useGetUserQuery = (skip = false) =>
  useQuery<GetUserQueryResponse>(GET_USER, {
    fetchPolicy: 'network-only',
    skip,
  });
