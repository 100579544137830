import { useAtomValue } from 'jotai';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { fileStatus } from '../apis/api-csv';
import { siteIdAtom } from '../utils/atoms';

type CSVExportStatus = 'pending' | 'done';

type CSVExportContextType = {
  csvExportStatus: CSVExportStatus | null;
  setCsvExportStatus: (value: CSVExportStatus | null) => void;
};

const CSVExportContext = createContext<CSVExportContextType | null>(null);

export const useCSVExportContext = () => {
  const context = useContext(CSVExportContext);
  if (!context) {
    throw new Error(
      'useCSVExportContext must be used within a CSVExportProvider',
    );
  }

  return context;
};

export const CSVExportProvider = ({ children }: PropsWithChildren) => {
  const [csvExportStatus, setCsvExportStatus] =
    useState<CSVExportStatus | null>(null);
  const siteId = useAtomValue(siteIdAtom);

  useEffect(() => {
    const fetchData = async () => {
      const makeAPICall = async () => {
        try {
          const response = await fileStatus(siteId);
          const { files } = response.data;
          if (Object.keys(files).length === 0 && files.constructor === Object) {
            setCsvExportStatus(null);
          } else if (files.CSV_EXPORT === 'pending') {
            setCsvExportStatus(files.CSV_EXPORT);
            setTimeout(makeAPICall, 2000);
          } else if (files.CSV_EXPORT && files.CSV_EXPORT === 'done') {
            setCsvExportStatus(files.CSV_EXPORT);
          } else {
            setTimeout(makeAPICall, 2000);
          }
        } catch (err) {
          setCsvExportStatus(null);
        }
      };

      makeAPICall();
    };

    fetchData();
  }, [siteId]);

  const context = useMemo(
    () => ({ csvExportStatus, setCsvExportStatus }),
    [csvExportStatus],
  );

  return (
    <CSVExportContext.Provider value={context}>
      {children}
    </CSVExportContext.Provider>
  );
};
