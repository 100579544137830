/* eslint-disable react/no-unused-prop-types */
import i18n from 'i18next';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import React, { useState } from 'react';
import { BWButton } from './BWButton';
import { generateFile } from '../../apis/api-csv';
import { ReactComponent as CSVIcon } from '../../assets/icons/csv-icon.svg';
import { useCSVExportContext } from '../../contextapi/CSVExportProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { siteIdAtom } from '../../utils/atoms';

type Props = {
  type?: string;
  scenarios?: Array<number>;
  camera_ids?: Array<number>;
  responder?: Array<string>;
  status?: Array<string>;
  priority?: Array<string>;
  start_time?: number;
  end_time?: number;
  is_bookmarked?: boolean;
  limit?: number;
  disable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export function CSVDownloader({
  type,
  scenarios,
  camera_ids,
  responder,
  status,
  priority,
  start_time,
  end_time,
  is_bookmarked,
  limit,
  disable,
}: Props) {
  const { csvExportStatus, setCsvExportStatus } = useCSVExportContext();
  const { customerScenarios } = useScenarioContext();
  const [loader, setLoader] = useState(false);
  const siteId = useAtomValue(siteIdAtom);

  return (
    <BWButton
      data-ph-capture="true"
      title={i18n.t('button.export_as_csv')}
      className="button-with-loader ms-12"
      variant="outline"
      icon={CSVIcon}
      onClick={async () => {
        setLoader(true);
        const scenarioIds = customerScenarios.map((item) => item.id);
        const generate_file = await generateFile(siteId, {
          type: type || 'CSV_EXPORT',
          scenarios: scenarios || scenarioIds,
          camera_ids,
          responder,
          status,
          priority,
          limit,
          start_time,
          is_bookmarked,
          end_time: end_time || moment().unix(),
        });
        setLoader(false);
        if (generate_file.status === 200) {
          setCsvExportStatus('pending');
        }
      }}
      trackAs={{
        event: 'csv_export_generate',
      }}
      loading={loader}
      disabled={disable || loader || csvExportStatus !== null}
    />
  );
}
