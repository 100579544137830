import moment from 'moment';
import React, { useMemo } from 'react';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { CustomPlaceholder } from '../../elements/CustomPlaceholder';

export function MultiBarChartPlaceholder() {
  const { timeRange } = useTimeRangeContext();

  const placeholderBarCount = useMemo(() => {
    switch (timeRange.text) {
      case '24 hours':
        return 25;
      case 'today':
        return moment().hours() + 1;
      case '7':
        return 8;
      case '14':
        return 15;
      case '30':
        return 31;
      default:
        return Math.ceil((timeRange.end - timeRange.value) / 86400) + 1;
    }
  }, [timeRange]);

  const placeholderBarHeights = useMemo(
    () =>
      Array.from({ length: placeholderBarCount }).map(
        () => 240 * Math.random(),
      ),
    [placeholderBarCount],
  );

  return (
    <div
      style={{
        width: '100%',
        height: 300,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: 8,
          marginBottom: 30,
          height: 240,
        }}
      >
        {placeholderBarHeights.map((height, index) => (
          <CustomPlaceholder
            // eslint-disable-next-line react/no-array-index-key
            key={`${placeholderBarCount}_${index}`}
            style={{
              flex: 1,
              height,
            }}
          />
        ))}
      </div>
      <CustomPlaceholder
        style={{
          display: 'block',
          margin: '0 auto',
          width: '100%',
          height: 26,
        }}
      />
    </div>
  );
}
