import i18n from 'i18next';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TimeSelector } from './TimeSelector';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { CameraSchedule } from '../../../hooks/graphql/camera';
import { emptyCameraSchedule } from '../../../typescript/camera/schedule';
import { isDefined } from '../../../utils/typeUtils';

type Props = {
  variant: 'camera_schedule' | 'scenario_schedule';
  cameraSchedule?: CameraSchedule;
  onScheduleChange: (cameraSchedule?: CameraSchedule) => void;
};

const daysOfWeek = {
  0: 'weekdays.monday',
  1: 'weekdays.tuesday',
  2: 'weekdays.wednesday',
  3: 'weekdays.thursday',
  4: 'weekdays.friday',
  5: 'weekdays.saturday',
  6: 'weekdays.sunday',
};

type ScheduleOptions = 'camera_schedule' | 'custom_schedule';
type DayOptions = 'every_day' | 'selected_days';
type HourOptions = 'every_hour' | 'selected_hours';

export function CameraScheduleTabPane({
  variant,
  cameraSchedule: defaultCameraSchedule,
  onScheduleChange,
}: Props) {
  const cameraSchedule = defaultCameraSchedule || emptyCameraSchedule;

  const [scheduleRadio, setScheduleRadio] = useState<ScheduleOptions>(
    variant === 'scenario_schedule' && !isDefined(defaultCameraSchedule)
      ? 'camera_schedule'
      : 'custom_schedule',
  );

  const [daysRadio, setDaysRadio] = useState<DayOptions>(
    Object.keys(cameraSchedule).length === 7 ? 'every_day' : 'selected_days',
  );

  const [timesRadio, setTimesRadio] = useState<HourOptions>(
    Object.values(cameraSchedule).every(
      (daySchedule) => daySchedule.length === 0,
    )
      ? 'every_hour'
      : 'selected_hours',
  );

  function updateDaySchedule(
    parentIndex: keyof CameraSchedule,
    index: number,
    time?: string,
  ) {
    const daySchedule = cameraSchedule[parentIndex] || [];
    if (time) {
      daySchedule[index] = time;
    } else {
      daySchedule.splice(index, 1);
    }
    onScheduleChange({
      ...cameraSchedule,
      [parentIndex]: daySchedule,
    });
  }

  const handleScheduleRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const radioValue = e.target.value as ScheduleOptions;

      setScheduleRadio(radioValue);
      if (radioValue === 'camera_schedule') {
        onScheduleChange(undefined);
      } else {
        const updatedCameraSchedule = {
          ...emptyCameraSchedule,
          ...cameraSchedule,
        };
        onScheduleChange(updatedCameraSchedule);
      }
    },
    [cameraSchedule, onScheduleChange],
  );

  const handleDaysRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const radioValue = e.target.value as DayOptions;

    setDaysRadio(radioValue);
    if (radioValue === 'every_day') {
      const updatedCameraSchedule = {
        ...emptyCameraSchedule,
        ...cameraSchedule,
      };
      onScheduleChange(updatedCameraSchedule);
    }
  };

  const handleHoursRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const radioValue = e.target.value as HourOptions;

    setTimesRadio(radioValue);
    if (radioValue === 'every_hour') {
      const updatedCameraSchedule = Object.fromEntries(
        Object.entries(cameraSchedule).map(([dayIndex]) => [dayIndex, []]),
      );
      onScheduleChange(updatedCameraSchedule);
    }
  };

  return (
    <>
      {variant === 'scenario_schedule' && (
        <Col md={12} className="mb-24">
          <p className="mb-1 weight-600">
            {i18n.t('modal.camera_schedule.custom.title')}
          </p>
          <p className="sub-color">
            {i18n.t('modal.camera_schedule.custom.description')}
          </p>
          <FormGroup controlId="inheritSchedule">
            <FormCheck
              type="radio"
              className="weight-600"
              label={i18n.t(
                'modal.camera_schedule.custom.use_default_schedule',
              )}
              value="camera_schedule"
              checked={scheduleRadio === 'camera_schedule'}
              onChange={handleScheduleRadioChange}
            />

            <FormCheck
              label={i18n.t('modal.camera_schedule.custom.set_custom_schedule')}
              className="weight-600"
              type="radio"
              value="custom_schedule"
              checked={scheduleRadio === 'custom_schedule'}
              onChange={handleScheduleRadioChange}
            />
          </FormGroup>
          {scheduleRadio === 'custom_schedule' && <hr />}
        </Col>
      )}

      {scheduleRadio === 'custom_schedule' && (
        <Row>
          <Col md={12}>
            <p className="mb-1 weight-600">
              {i18n.t('modal.add_new_schedule.days')}
            </p>
            <p className="sub-color">
              {i18n.t('modal.add_new_schedule.days.body')}
            </p>

            <FormGroup controlId="daysSchedule">
              <FormCheck
                label={i18n.t('radiobox.all_week')}
                className="weight-600"
                type="radio"
                value="every_day"
                checked={daysRadio === 'every_day'}
                onChange={handleDaysRadioChange}
              />
              <FormCheck
                label={i18n.t('radiobox.custom_days')}
                className="weight-600"
                type="radio"
                value="selected_days"
                checked={daysRadio === 'selected_days'}
                onChange={handleDaysRadioChange}
              />
            </FormGroup>

            {daysRadio === 'selected_days' &&
              Object.entries(daysOfWeek).map(([dayIndex, dayLabel]) => {
                const dayNumber = Number(dayIndex) as keyof CameraSchedule;
                return (
                  <FormCheck
                    key={dayNumber}
                    type="checkbox"
                    className="check-box ms-32"
                    label={i18n.t(dayLabel)}
                    checked={dayNumber in cameraSchedule}
                    onChange={() => {
                      const updatedCameraSchedule = { ...cameraSchedule };
                      if (dayNumber in cameraSchedule) {
                        delete updatedCameraSchedule[dayNumber];
                      } else {
                        updatedCameraSchedule[dayNumber] = [];
                      }
                      onScheduleChange(updatedCameraSchedule);
                    }}
                  />
                );
              })}
            <hr />
          </Col>

          <Col md={12}>
            <p className="mb-1 weight-600">
              {i18n.t('modal.add_new_schedule.time')}
            </p>
            <p className="sub-color">
              {i18n.t('modal.add_new_schedule.time.body')}
            </p>

            <FormGroup controlId="hoursSchedule">
              <FormCheck
                type="radio"
                className="weight-600"
                label={i18n.t('radiobox.all_time')}
                value="every_hour"
                checked={timesRadio === 'every_hour'}
                onChange={handleHoursRadioChange}
              />

              <FormCheck
                label={i18n.t('radiobox.custom_time')}
                className="weight-600"
                type="radio"
                value="selected_hours"
                checked={timesRadio === 'selected_hours'}
                onChange={handleHoursRadioChange}
              />
            </FormGroup>

            {timesRadio === 'selected_hours' &&
              Object.entries(cameraSchedule).map(([dayIndex, daySchedule]) => {
                const dayNumber = Number(dayIndex) as keyof CameraSchedule;
                return (
                  <div key={dayNumber} className="mt-24 ms-32">
                    <p className="mb-2 weight-600 d-flex align-items-center">
                      {i18n.t(daysOfWeek[dayNumber])}{' '}
                      <AddIcon
                        key="warning"
                        className="w-auto ps-12 pe-12 icon"
                        onClick={() => {
                          if (daySchedule.at(-1) === '') {
                            toast.warning('Please fill the time');
                          } else {
                            onScheduleChange({
                              ...cameraSchedule,
                              [dayNumber]: [...daySchedule, '00:00-24:00'],
                            });
                          }
                        }}
                      />
                    </p>
                    {daySchedule.map((timeSpan: string, index: number) => {
                      const [fromHour, fromMinute, toHour, toMinute] =
                        timeSpan.split(/[:-]/);
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${index}-${timeSpan}`}
                          className="d-flex align-items-center mb-12 ms-32"
                          style={{ gap: 8 }}
                        >
                          <p className="m-0">from</p>
                          <TimeSelector
                            value={fromHour}
                            unit="hours"
                            onChange={(value) => {
                              updateDaySchedule(
                                dayNumber,
                                index,
                                `${value}:${fromMinute}-${toHour}:${toMinute}`,
                              );
                            }}
                          />
                          <TimeSelector
                            value={fromMinute}
                            unit="minutes"
                            onChange={(value) => {
                              updateDaySchedule(
                                dayNumber,
                                index,
                                `${fromHour}:${value}-${toHour}:${toMinute}`,
                              );
                            }}
                          />

                          <p className="m-0">to</p>
                          <TimeSelector
                            value={toHour}
                            unit="hours"
                            onChange={(value) => {
                              updateDaySchedule(
                                dayNumber,
                                index,
                                `${fromHour}:${fromMinute}-${value}:${toMinute}`,
                              );
                            }}
                          />
                          <TimeSelector
                            value={toMinute}
                            unit="minutes"
                            onChange={(value) => {
                              updateDaySchedule(
                                dayNumber,
                                index,
                                `${fromHour}:${fromMinute}-${toHour}:${value}`,
                              );
                            }}
                          />

                          <DeleteIcon
                            key="warning"
                            className="w-auto ps-12 pe-12"
                            onClick={() => {
                              updateDaySchedule(dayNumber, index);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </Col>
        </Row>
      )}
    </>
  );
}
