/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { isString } from '../../../utils/typeUtils';
import { CustomDropdown } from '../../elements/CustomDropdown';

type Props = {
  value: string;
  unit: 'hours' | 'minutes';
  onChange: (paddedValue: string) => void;
};

export function TimeSelector({ value, unit, onChange }: Props) {
  const dropdownValues =
    unit === 'hours' ? [...Array(24).keys()] : [...Array(60).keys()];

  return (
    <CustomDropdown
      title={value}
      customCss={css`
        .dropdown-menu {
          height: 240px;
          overflow-x: auto;
        }
      `}
      dropdownData={dropdownValues.map((value) => {
        const formattedValue = String(value).padStart(2, '0');
        return {
          title: `${formattedValue} ${unit === 'hours' ? 'h' : 'm'}`,
          value: formattedValue,
        };
      })}
      onClick={(selectItem) => {
        const { value } = selectItem;
        if (isString(value)) {
          onChange(value);
        }
      }}
    />
  );
}
