import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useThemeContext } from './ThemeProvider';

export function ThemedToastContainer() {
  const { theme } = useThemeContext();

  return (
    <ToastContainer
      autoClose={5000}
      hideProgressBar
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme={theme}
    />
  );
}
