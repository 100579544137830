/* eslint-disable no-nested-ternary */
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { getScenarioTitle, Scenario } from '../../lib/features/scenario';
import { CameraProps } from '../../typescript/camera/camera';
import {
  CustomDropdown,
  DropdownProps,
  mapScenarioToDropdownProps,
} from '../elements/CustomDropdown';

type Props = {
  className?: string | undefined;
  cameraList: Array<CameraProps>;
  selectedCameraList: Array<CameraProps>;
  selectedScenario: Scenario | null;
  onScenario: (scenario: Scenario) => void;
  onClickSelectedCameraList: (selectedCameras: Array<DropdownProps>) => void;
};

export function Triggers({
  className,
  cameraList,
  selectedCameraList,
  selectedScenario,
  onScenario,
  onClickSelectedCameraList,
}: Props) {
  const { customerScenarios } = useScenarioContext();
  const [selectAllCameras, setSelectAllCameras] = useState<boolean>(false);
  const [allCameraList, setAllCameraList] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    if (cameraList) {
      const cameras = cameraList.map((item) => ({
        title: item.name,
        value: item.sql_id,
        isChecked: selectedCameraList.some((o2) => item.sql_id === o2.sql_id),
      }));
      setAllCameraList(cameras);
    }
  }, [cameraList, selectedCameraList]);

  const selectedCameraLength = allCameraList.filter(
    (item) => item.isChecked,
  ).length;

  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('triggers.title')}</p>
      <p className="sub-color">{i18n.t('triggers.body')}</p>

      <div className="d-flex align-items-center">
        <p className="m-0 me-12">{i18n.t('triggers.scenario.title')}</p>

        <CustomDropdown
          variant="scenario"
          className="rounded-pill me-12"
          title={
            selectedScenario
              ? getScenarioTitle(selectedScenario)
              : i18n.t('button.select_scenario')
          }
          dropdownData={customerScenarios.map(mapScenarioToDropdownProps)}
          onClick={({ scenario }) => {
            if (scenario) {
              onScenario(scenario);
            }
          }}
        />

        <p className="m-0 me-12">{i18n.t('triggers.camera.title')}</p>

        <CustomDropdown
          variant="checkbox"
          onSelectedAll={(value, fromChild) => {
            if (!fromChild) {
              const selectAll = allCameraList.map((item) => ({
                ...item,
                isChecked: value,
              }));

              setAllCameraList(selectAll);
              onClickSelectedCameraList(
                selectAll.filter((camera) => camera.isChecked),
              );
            }

            setSelectAllCameras(value);
          }}
          selectedAll={selectAllCameras}
          className="rounded-pill"
          title={`${
            selectedCameraLength === 0
              ? i18n.t('button.select_cameras')
              : `${selectedCameraLength} ${i18n.t('button.selected')}`
          } `}
          selectAllTitle={`${i18n.t('button.select_all')}`}
          dropdownData={allCameraList}
          hideContextData
          onClick={(value) => {
            const selectAll = allCameraList.map((item) => {
              if (item.value === value.value) {
                return {
                  title: item.title,
                  value: item.value,
                  isChecked: !item.isChecked,
                };
              }
              return item;
            });
            setAllCameraList(selectAll);
            onClickSelectedCameraList(
              selectAll.filter((camera) => camera.isChecked),
            );
          }}
        />
      </div>
    </Col>
  );
}
