import $switchCaseDefault from 'dolla-switch';
import React from 'react';
import { PriorityValue } from '../../typescript/observation/observation';

type Props = {
  priority?: PriorityValue;
};

export function PriorityIcon({ priority }: Props) {
  const priorityClass = $switchCaseDefault(
    priority,
    {
      high: () => 'background-error',
      medium: () => 'background-warning',
      low: () => 'background-success',
    },
    () => 'background-success',
  );

  return (
    <span
      className={`${priorityClass}`}
      style={{
        width: 6,
        height: 6,
        marginRight: 4,
      }}
    />
  );
}
