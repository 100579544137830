/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { DeveloperTools } from './DeveloperTools';
import { LanguageDropdown } from './LanguageDropdown';
import { NavigationItem } from './NavigationItem';
import { ThemeToggleButton } from './ThemeToggleButton';
import { UserProfileDropdown } from './UserProfileDropdown';
import logo from '../../assets/logo-icon-dark.svg';
import inverseLogo from '../../assets/logo-icon-light.svg';
import { ReactComponent as Alerts } from '../../assets/nav/bell.svg';
import { ReactComponent as Camera } from '../../assets/nav/camera.svg';
import { ReactComponent as Observations } from '../../assets/nav/eye.svg';
import { ReactComponent as Charts } from '../../assets/nav/system.svg';
import { useFeatureFlags } from '../../contextapi/FeatureFlagsProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { TrackingContextProvider } from '../../contextapi/TrackingProvider';
import { spacing } from '../../scss/spacing';

export function NavigationSidebar() {
  const { theme } = useThemeContext();
  const { featureFlags } = useFeatureFlags();

  const showDevTools = process.env.REACT_APP_DEV_SHOW_DEV_TOOLS === 'true';
  const showLanguageSwitcher = featureFlags.show_language_switcher;

  return (
    <TrackingContextProvider element="navigation_bar">
      <div className={`side-nav border-end border-${theme}`}>
        <Navbar expand="lg" variant={theme} className="m-2 m-lg-0">
          <Navbar.Brand
            href="/"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Image
              src={theme === 'light' ? logo : inverseLogo}
              className="mt-lg-4"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav defaultActiveKey="/">
              <div>
                <NavigationItem
                  href="/dashboard"
                  icon={Charts}
                  label={i18n.t('navigation.item.dashboard')}
                  trackingContext={{
                    menu_item: 'dashboard',
                  }}
                />

                <NavigationItem
                  href="/observations"
                  icon={Observations}
                  label={i18n.t('navigation.item.observations')}
                  trackingContext={{
                    menu_item: 'observations',
                  }}
                />

                <NavigationItem
                  href="/alerts"
                  icon={Alerts}
                  label={i18n.t('navigation.item.alerts')}
                  trackingContext={{
                    menu_item: 'alerts',
                  }}
                />

                <NavigationItem
                  href="/cameras"
                  icon={Camera}
                  label={i18n.t('navigation.item.cameras')}
                  trackingContext={{
                    menu_item: 'cameras',
                  }}
                />
              </div>

              <div
                style={{
                  gap: spacing(2),
                }}
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;

                  @media (min-width: 992px) {
                    flex-direction: column;
                  }

                  .dropdown-menu {
                    width: 200px;
                  }
                `}
              >
                {showDevTools && <DeveloperTools />}
                {showLanguageSwitcher && <LanguageDropdown />}
                <ThemeToggleButton />
                <UserProfileDropdown />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </TrackingContextProvider>
  );
}
