import Konva from 'konva';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layer, Stage } from 'react-konva';
import { CIRCLE_DIAMETER, KonvaDangerZone } from './KonvaDangerZone';
import { useCameraContext } from '../../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../../contextapi/ScenarioProvider';
import {
  Perimeter,
  ScenarioPerimeter,
  XYCoordinate,
} from '../../../../hooks/graphql/camera';
import { Scenario } from '../../../../lib/features/scenario';
import {
  convertToDangerZones,
  DangerZone,
  isPerimeterSet,
} from '../../../../typescript/camera/camera';
import { isString, Nullable } from '../../../../utils/typeUtils';

export type CanvasSize = {
  width: number;
  height: number;
};

type Props = {
  scenario?: Scenario;
  perimeter?: Perimeter;
  imageElement: Nullable<HTMLImageElement>;
  editable?: boolean;
  isDrawing?: boolean;
  // replaceContextMenu?: boolean;
  renderInsideLayer?: boolean;
  onZoneSelected?: (id?: number) => void;
  onPerimeterUpdated?: (perimeter: ScenarioPerimeter) => void;
};

const getMouseCoordinate = (
  event: Konva.KonvaEventObject<MouseEvent>,
  canvasSize: CanvasSize,
): XYCoordinate | null => {
  const pointer = event.target.getStage()?.getPointerPosition();
  if (!pointer) {
    return null;
  }

  return [
    Math.max(0, Math.min(pointer.x, canvasSize.width)) / canvasSize.width,
    Math.max(0, Math.min(pointer.y, canvasSize.height)) / canvasSize.height,
  ];
};

export function DangerZoneOverlay({
  scenario: scenarioProp,
  perimeter,
  imageElement,
  editable,
  // replaceContextMenu = false,
  isDrawing = false,
  renderInsideLayer = false,
  onZoneSelected,
  onPerimeterUpdated,
}: Props) {
  const { setDangerZoneExportLayer } = useCameraContext();
  const { getScenarioByName } = useScenarioContext();

  const scenario = scenarioProp || getScenarioByName(perimeter?.scenario);

  // state
  const [canvasSize, setCanvasSize] = useState<CanvasSize>({
    width: imageElement?.width || 0,
    height: imageElement?.height || 0,
  });
  // const [dropdownPosition, setDropdownPosition] = useState<Vector2d>();
  const [activeZoneIndex, setActiveZoneIndex] = useState<string | null>(null);
  const [hoveredZoneIndex, setHoveredZoneIndex] = useState<string | null>(null);
  const [newDangerZone, setNewDangerZone] = useState<DangerZone | null>(null);
  const [mouseCoordinate, setMouseCoordinate] = useState<XYCoordinate | null>(
    null,
  );

  // reference
  const stageRef = useRef<Konva.Stage>(null);
  const dangerZoneLayerRef = useRef<Konva.Layer>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null); // TODO: remove this ref

  const dangerZones = useMemo(
    () => [
      ...(isPerimeterSet(perimeter) ? convertToDangerZones(perimeter) : []),
      ...(newDangerZone ? [newDangerZone] : []),
    ],
    [newDangerZone, perimeter],
  );

  useEffect(() => {
    setNewDangerZone(null);
    setActiveZoneIndex(null);
  }, [isDrawing]);

  useEffect(() => {
    setNewDangerZone(null);
    setActiveZoneIndex(null);
    setHoveredZoneIndex(null);
  }, [scenario, perimeter]);

  useEffect(() => {
    onZoneSelected?.(
      isString(activeZoneIndex) ? Number(activeZoneIndex) : undefined,
    );
  }, [onZoneSelected, activeZoneIndex]);

  const imageWidth = imageElement?.width || 0;
  const imageHeight = imageElement?.height || 0;

  const updateCanvasSize = useCallback(() => {
    setCanvasSize({
      width: imageWidth,
      height: imageHeight,
    });
  }, [imageHeight, imageWidth]);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  const handleStageMouseDown = useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      if (!isDrawing || !scenario) {
        return;
      }

      const mouseCoordinate = getMouseCoordinate(event, canvasSize);
      if (!mouseCoordinate) {
        return;
      }

      if (newDangerZone === null) {
        setNewDangerZone({
          index: 'new',
          completed: false,
          coordinates: [mouseCoordinate],
        });

        return;
      }

      const [startX, startY] = newDangerZone.coordinates[0];
      const { width, height } = canvasSize;
      const isOverStartingPoint =
        Math.abs(startX * width - event.evt.offsetX) < CIRCLE_DIAMETER &&
        Math.abs(startY * height - event.evt.offsetY) < CIRCLE_DIAMETER;

      if (!isOverStartingPoint || newDangerZone.coordinates.length < 3) {
        setNewDangerZone({
          ...newDangerZone,
          coordinates: [...newDangerZone.coordinates, mouseCoordinate],
        });
      } else {
        const updatedPerimeter = {
          scenario: scenario.name,
          position: [...(perimeter?.position || []), newDangerZone.coordinates],
        };
        onPerimeterUpdated?.(updatedPerimeter);
        setNewDangerZone(null);
      }
    },
    [
      canvasSize,
      isDrawing,
      newDangerZone,
      onPerimeterUpdated,
      scenario,
      perimeter,
    ],
  );

  const handleStageClick = useCallback(
    (evt: Konva.KonvaEventObject<MouseEvent>) => {
      if (editable) {
        const zoneIndex = evt.target.parent?.name();
        setActiveZoneIndex(zoneIndex ?? null);
      }
    },
    [editable],
  );

  const handleStageMouseMove = useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      if (!isDrawing || !newDangerZone) {
        return;
      }

      const mouseCoordinate = getMouseCoordinate(event, canvasSize);
      setMouseCoordinate(mouseCoordinate);
    },
    [canvasSize, isDrawing, newDangerZone],
  );

  const handleDangerZoneMouseOver = useCallback(
    (evt: Konva.KonvaEventObject<MouseEvent>) => {
      if (editable) {
        const zoneIndex = evt.target.parent?.name();
        setHoveredZoneIndex(zoneIndex ?? null);
      }
    },
    [editable],
  );

  const handleDangerZoneMouseOut = useCallback(() => {
    if (editable) {
      setHoveredZoneIndex(null);
    }
  }, [editable]);

  const cursor = isDrawing
    ? 'crosshair'
    : activeZoneIndex !== null && activeZoneIndex === hoveredZoneIndex
      ? 'grab'
      : hoveredZoneIndex !== null
        ? 'pointer'
        : 'default';

  // const handleContextMenu = useCallback(
  //   (event: Konva.KonvaEventObject<MouseEvent>) => {
  //     if (!replaceContextMenu) {
  //       return;
  //     }

  //     event.evt.preventDefault();
  //     setDropdownPosition((position) =>
  //       position
  //         ? undefined
  //         : {
  //             x: event.evt.offsetX,
  //             y: event.evt.offsetY,
  //           },
  //     );
  //   },
  //   [replaceContextMenu],
  // );

  useEffect(() => {
    setDangerZoneExportLayer(dangerZoneLayerRef.current);
  }, [dangerZoneLayerRef, setDangerZoneExportLayer]);

  if (!scenario) {
    return null;
  }

  const insideLayer = (
    <>
      {dangerZones.map((dangerZone) => (
        <KonvaDangerZone
          key={dangerZone.index}
          dangerZone={dangerZone}
          activeColor={scenario.primaryColor}
          isActive={dangerZone.index?.toString() === activeZoneIndex}
          mouseCoordinate={mouseCoordinate}
          canvasSize={canvasSize}
          onMouseOver={handleDangerZoneMouseOver}
          onMouseOut={handleDangerZoneMouseOut}
          onCoordinatesUpdated={(updatedCoordinates) => {
            const updatedPerimeter = {
              scenario: scenario.name,
              position: dangerZones.map((zone) =>
                zone.index === dangerZone.index
                  ? updatedCoordinates
                  : zone.coordinates,
              ),
            };
            onPerimeterUpdated?.(updatedPerimeter);
          }}
        />
      ))}
    </>
  );

  if (renderInsideLayer) {
    return insideLayer;
  }

  return (
    <div
      ref={mainContainerRef}
      style={{
        // pointerEvents: hideOnHover ? 'none' : 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      {/* {replaceContextMenu && dropdownPosition && (
        <Dropdown
          style={{
            position: 'absolute',
            left: dropdownPosition?.x || 0,
            top: dropdownPosition?.y || 0,
            zIndex: 100,
          }}
        >
          <Dropdown.Menu
            show
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            {cameraScenarios.map((scenario) => (
              <ScenarioLabel
                key={scenario.name}
                scenario={scenario}
                cursor="pointer"
                onClick={() => {
                  // console.log('onScenarioClick', scenario);
                  setDropdownPosition(undefined);
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )} */}
      <Stage
        ref={stageRef}
        width={canvasSize.width}
        height={canvasSize.height}
        onClick={handleStageClick}
        onMouseDown={handleStageMouseDown}
        onMouseMove={handleStageMouseMove}
        onMouseEnter={() => setMouseCoordinate(null)}
        onMouseLeave={() => setMouseCoordinate(null)}
        // onContextMenu={handleContextMenu}
        style={{
          cursor,
        }}
      >
        <Layer ref={dangerZoneLayerRef}>{insideLayer}</Layer>
      </Stage>
    </div>
  );
}
