import React from 'react';

export enum TrendIconColor {
  PREFERRED = '#12B76A',
  UNDESIRED = '#F04438',
}

type Props = {
  className?: string;
  direction: 'up' | 'down';
  strokeColor: TrendIconColor;
};

export const TrendIcon = ({ className, direction, strokeColor }: Props) =>
  direction === 'up' ? (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3346 6.3335L11.7774 12.8907C11.4474 13.2207 11.2824 13.3857 11.0921 13.4475C10.9248 13.5019 10.7445 13.5019 10.5771 13.4475C10.3868 13.3857 10.2218 13.2207 9.89183 12.8907L7.61078 10.6096C7.28076 10.2796 7.11576 10.1146 6.92548 10.0528C6.75811 9.99841 6.57782 9.99841 6.41045 10.0528C6.22018 10.1146 6.05517 10.2796 5.72516 10.6096L1.66797 14.6668M18.3346 6.3335H12.5013M18.3346 6.3335V12.1668"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3346 14.6665L11.7774 8.10931C11.4474 7.7793 11.2824 7.61429 11.0921 7.55247C10.9248 7.49809 10.7445 7.49809 10.5771 7.55247C10.3868 7.61429 10.2218 7.7793 9.89183 8.10931L7.61078 10.3904C7.28076 10.7204 7.11576 10.8854 6.92548 10.9472C6.75811 11.0016 6.57782 11.0016 6.41045 10.9472C6.22018 10.8854 6.05517 10.7204 5.72516 10.3904L1.66797 6.33317M18.3346 14.6665H12.5013M18.3346 14.6665V8.83317"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
