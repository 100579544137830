import React from 'react';
import { fontSize } from '../../scss/spacing';

type Props = {
  title: string;
  removeMargins?: boolean;
};

export const SectionTitle = ({ title, removeMargins = false }: Props) => (
  <h2
    className={removeMargins ? 'm-0' : 'mb-20'}
    style={{
      fontSize: fontSize(4),
      fontWeight: 600,
    }}
  >
    {title}
  </h2>
);
