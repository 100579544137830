import React, { PropsWithChildren } from 'react';
import { AuthenticatedUserProvider } from '../contextapi/AuthenticatedUserProvider';
import { CacheProvider } from '../contextapi/CacheProvider';
import { CameraProvider } from '../contextapi/CameraProvider';
import { CSVExportProvider } from '../contextapi/CSVExportProvider';
import { FeatureFlagsProvider } from '../contextapi/FeatureFlagsProvider';
import { PosthogContextProvider } from '../contextapi/PosthogContextProvider';
import { ScenarioProvider } from '../contextapi/ScenarioProvider';
import { TimeRangeProvider } from '../contextapi/TimeRangeProvider';

export function AuthenticatedProviders({ children }: PropsWithChildren) {
  return (
    <AuthenticatedUserProvider>
      <PosthogContextProvider>
        <FeatureFlagsProvider>
          <CSVExportProvider>
            <CacheProvider>
              <TimeRangeProvider>
                <ScenarioProvider>
                  <CameraProvider>{children}</CameraProvider>
                </ScenarioProvider>
              </TimeRangeProvider>
            </CacheProvider>
          </CSVExportProvider>
        </FeatureFlagsProvider>
      </PosthogContextProvider>
    </AuthenticatedUserProvider>
  );
}
