import React from 'react';
import { spacing } from '../../../scss/spacing';

type Props = {
  title: string | null;
  icon: JSX.Element;
  captureWithPosthog?: boolean;
};

export function ObservationDetail({
  title,
  icon,
  captureWithPosthog = false,
}: Props) {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: spacing(1) }}
      data-ph-capture={captureWithPosthog ? 'true' : 'false'}
    >
      {icon}
      <p style={{ margin: 0 }}>{title}</p>
    </div>
  );
}
