/** @jsxImportSource @emotion/react */
import i18n from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { DangerZoneOverlay } from './DangerZoneOverlay';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash-can.svg';
import { useCameraContext } from '../../../../contextapi/CameraProvider';
import { ScenarioPerimeter } from '../../../../hooks/graphql/camera';
import { Scenario, ScenarioName } from '../../../../lib/features/scenario';
import { isPerimeterSet } from '../../../../typescript/camera/camera';
import { isDefined } from '../../../../utils/typeUtils';
import { BWButton } from '../../../elements/BWButton';
import { ScenarioTabs } from '../ScenarioTabs';

interface Props {
  show: boolean;
  onClose: () => void;
}

export function DangerZoneModal({ show, onClose }: Props) {
  const { gqlCamera, cameraImageUrl, cameraScenarios, updateCamera } =
    useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const imageRef = useRef<HTMLImageElement>(null);

  const [selectedScenario, setSelectedScenario] = useState<
    Scenario | undefined
  >(cameraScenarios.at(0));

  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<number>();
  const [scenarioPerimeters, setScenarioPerimeters] =
    useState<Partial<Record<ScenarioName, ScenarioPerimeter>>>();

  useEffect(() => {
    if (gqlCamera && !isDefined(scenarioPerimeters)) {
      setScenarioPerimeters(
        gqlCamera.cameras_scenarios.reduce(
          (acc, item) => {
            const { perimeter, customer_scenario_label } = item;
            if (isPerimeterSet(perimeter)) {
              acc[customer_scenario_label.name] = perimeter;
            }
            return acc;
          },
          {} as Partial<Record<ScenarioName, ScenarioPerimeter>>,
        ),
      );
    }
  }, [gqlCamera, scenarioPerimeters]);

  useEffect(() => {
    setIsDrawing(false);
  }, [selectedScenario]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleSaveChanges = async () => {
    if (!gqlCamera) return;

    setLoading(true);

    const perimeters = scenarioPerimeters
      ? JSON.stringify(Object.values(scenarioPerimeters))
      : undefined;

    const success = await updateCamera({
      perimeters,
    });

    setLoading(false);
    if (success) {
      handleCloseModal();
    }
  };

  const onClickDraw = () => {
    setIsDrawing((prev) => !prev);
  };

  const onClickErase = () => {
    if (isDrawing) {
      setIsDrawing(false);
      return;
    }

    if (scenarioPerimeters && selectedScenario) {
      const scenarioPerimeter = scenarioPerimeters[selectedScenario.name];
      if (!scenarioPerimeter) {
        return;
      }

      setScenarioPerimeters({
        ...scenarioPerimeters,
        [selectedScenario.name]: {
          ...scenarioPerimeter,
          position: scenarioPerimeter.position.filter(
            (_, index) => index !== selectedZoneIndex,
          ),
        },
      });
    }
  };

  const scenarioPerimeter = selectedScenario
    ? scenarioPerimeters?.[selectedScenario.name]
    : undefined;

  return (
    <Modal
      centered
      className="custom-modal danger-zone-modal bw-modal"
      dialogClassName="modal-70vw"
      show={show}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera_danger_zone.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={handleCloseModal}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <ScenarioTabs
            variant="singleSelect"
            onScenarioSelected={setSelectedScenario}
          />
          <Col md={12} className="text-center canvas-container">
            <div
              style={{
                position: 'relative',
                width: '100%',
              }}
            >
              <Image
                className="ph-no-capture"
                onLoad={() => setIsImageLoading(false)}
                onError={() => setIsImageLoading(true)}
                width="100%"
                src={cameraImageUrl}
                ref={imageRef}
              />
              {!isImageLoading && selectedScenario && (
                <DangerZoneOverlay
                  scenario={selectedScenario}
                  perimeter={scenarioPerimeter}
                  editable
                  isDrawing={isDrawing}
                  // replaceContextMenu
                  imageElement={imageRef.current}
                  onZoneSelected={(zoneIndex) =>
                    setSelectedZoneIndex(zoneIndex)
                  }
                  onPerimeterUpdated={(perimeter) => {
                    setScenarioPerimeters({
                      ...scenarioPerimeters,
                      [selectedScenario.name]: perimeter,
                    });
                    setIsDrawing(false);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '1rem',
          }}
        >
          <BWButton
            variant="outline"
            type="button"
            title={i18n.t('button.draw')}
            onClick={onClickDraw}
            active={isDrawing}
          />

          <BWButton
            variant="outline"
            type="button"
            title={i18n.t('button.erase')}
            icon={DeleteIcon}
            disabled={!isDefined(selectedZoneIndex) && !isDrawing}
            onClick={onClickErase}
          />
        </div>

        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <BWButton
            title={i18n.t('button.back_to_settings')}
            variant="outline"
            type="button"
            disabled={loading}
            onClick={onClose}
          />

          <BWButton
            variant="primary"
            type="button"
            title={i18n.t('button.save_changes')}
            className="button-with-loader"
            disabled={loading}
            loading={loading}
            onClick={handleSaveChanges}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
