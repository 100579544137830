import { atom, createStore } from 'jotai';
import { LOCAL_STORAGE_KEYS } from '../consts';

export const store = createStore();
export const siteIdAtom = atom<number | null>(null);

const storedSiteId = localStorage.getItem(LOCAL_STORAGE_KEYS.SITE_ID);
store.set(siteIdAtom, storedSiteId ? Number(storedSiteId) : null);

store.sub(siteIdAtom, () => {
  const siteId = store.get(siteIdAtom);
  if (siteId !== null) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SITE_ID, String(siteId));
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SITE_ID);
  }
});
