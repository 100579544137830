/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Loader } from './Loader';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  EventName,
  EventPropertiesWithContext,
  useTrackEvent,
} from '../../hooks/track_event';
import {
  COLOR_BLUE,
  COLOR_BLUE_DARK,
  COLOR_PURPLE_DARK,
  COLOR_RED_600,
  GRAY_LIGHT,
  GRAY_MIDDLE,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARK,
  PRIMARY_COLOR_LIGHT,
  SECONDARY_COLOR,
  SECONDARY_COLOR_COMPLIMENT,
} from '../../scss/colors';
import { fontSize, spacing, SpacingValue } from '../../scss/spacing';
import { SVGComponent } from '../../typescript/utils';
import { isObject } from '../../utils/typeUtils';

export type ButtonVariant =
  | 'primary'
  | 'light'
  | 'dark'
  | 'transparent'
  | 'outline-light'
  | 'outline-dark'
  | 'admin-user'
  | 'non-admin-user'
  | 'link'
  | 'danger';

type Props = {
  variant?: ButtonVariant | 'outline';
  className?: string;
  style?: React.CSSProperties;
  title?: string | null;
  icon?: SVGComponent;
  iconProps?: React.SVGProps<SVGSVGElement>;
  rightIcon?: SVGComponent;
  iconSpacing?: SpacingValue;
  isSimpleRounded?: boolean;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  trackingProperties?: EventPropertiesWithContext['button_click'];
  trackAs?:
    | [EventName | string, object]
    | EventName
    | string
    | {
        event: EventName | string;
        properties?: object;
      };
  doNotTrack?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'>;

export const whiteSVG = css`
  svg {
    & path {
      stroke: white;
    }
  }
`;

export const buttonCss = css`
  &.btn {
    height: ${spacing(8)};
    font-weight: 600;
    font-size: ${fontSize(2)};
    padding: ${spacing(2)} ${spacing(4)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacing(2)};

    &-primary {
      background-color: ${PRIMARY_COLOR};
      border-color: ${PRIMARY_COLOR};
      color: ${SECONDARY_COLOR};

      &:hover,
      &:active,
      &:first-of-type:active,
      &.show,
      &:focus-visible,
      &:disabled {
        background-color: ${PRIMARY_COLOR_DARK} !important;
        border-color: ${PRIMARY_COLOR_DARK} !important;
        color: ${SECONDARY_COLOR} !important;
      }
    }

    &:focus {
      box-shadow: 0 0 0 3px #{${PRIMARY_COLOR_DARK} + '40'};
    }

    &-light {
      background-color: ${GRAY_LIGHT};
    }

    &-dark {
      background-color: ${SECONDARY_COLOR_COMPLIMENT};
      ${whiteSVG}
    }

    &-transparent {
      background-color: transparent;
      color: ${SECONDARY_COLOR};
      border: none !important;
      text-decoration: none !important;

      &:hover,
      &:focus,
      &:active {
        color: ${SECONDARY_COLOR} !important;

        svg {
          & path {
            stroke: ${SECONDARY_COLOR};
          }
        }
      }
    }

    &-outline-light {
      background-color: white;
      border-color: ${GRAY_MIDDLE};
      color: ${SECONDARY_COLOR};

      &:hover,
      &:active,
      &.active {
        border-color: ${PRIMARY_COLOR};
        background-color: #f8f9fa;
      }

      &:focus,
      &.active {
        box-shadow: 0 0 0 3px ${PRIMARY_COLOR_LIGHT};
      }

      &:disabled {
        border-color: ${GRAY_MIDDLE};
        color: ${SECONDARY_COLOR};
      }
    }

    &-outline-dark {
      background-color: ${SECONDARY_COLOR_COMPLIMENT};
      color: white;
      border-color: ${SECONDARY_COLOR_COMPLIMENT};
      ${whiteSVG}
      &:hover {
        border-color: ${PRIMARY_COLOR};
      }

      &:focus {
        box-shadow: 0 0 0 3px #{${PRIMARY_COLOR_DARK} + '40'};
      }

      &:after {
        filter: invert(100%);
      }

      &:disabled {
        background-color: ${SECONDARY_COLOR_COMPLIMENT};
        color: white;
      }
    }

    &-admin-user {
      background-color: ${COLOR_PURPLE_DARK} !important;
      border-color: ${COLOR_PURPLE_DARK} !important;
      color: white !important;
      ${whiteSVG}
    }

    &-non-admin-user {
      background-color: ${COLOR_BLUE_DARK} !important;
      border-color: ${COLOR_BLUE_DARK} !important;
      color: white !important;
      ${whiteSVG}
    }

    &-link {
      text-decoration: none;
      font-weight: 600;
      color: ${COLOR_BLUE};
      border: none !important;
      padding: 0 !important;

      svg {
        & path {
          stroke: ${COLOR_BLUE};
        }
      }
    }

    &-danger {
      background-color: ${COLOR_RED_600};
      ${whiteSVG}

      &:hover {
        border-color: ${COLOR_RED_600};
      }

      &:focus {
        box-shadow: 0 0 0 3px #{${COLOR_RED_600} + '40'};
      }
    }
  }

  &.button-with-loader {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
      margin-left: ${spacing(2)};
    }
  }
`;

export const BWButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      className = '',
      style,
      icon: Icon,
      iconProps = {},
      rightIcon: RightIcon,
      iconSpacing = 2,
      title = '',
      isSimpleRounded = false,
      loading = false,
      disabled = false,
      active = false,
      onClick,
      trackingProperties: trackingContext,
      trackAs,
      doNotTrack,
      ...props // Spread remaining props
    }: Props,
    ref,
  ) => {
    const { theme } = useThemeContext();
    const trackEvent = useTrackEvent();

    const bsVariant = variant === 'outline' ? `outline-${theme}` : variant;

    const classNames = [
      className,
      !isSimpleRounded && 'rounded-pill',
      active && 'active',
      'icon',
    ].filter(Boolean);

    return (
      <BootstrapButton
        ref={ref}
        className={classNames.join(' ')}
        variant={bsVariant}
        css={buttonCss}
        style={style}
        onClick={(e) => {
          if (!doNotTrack) {
            if (trackAs) {
              const [eventName, properties] = Array.isArray(trackAs)
                ? trackAs
                : isObject(trackAs)
                  ? [trackAs.event, trackAs.properties]
                  : [trackAs, {}];
              trackEvent(eventName, properties);
            } else {
              trackEvent(
                'button_click',
                trackingContext
                  ? { ...trackingContext }
                  : {
                      button_text: title ?? undefined,
                    },
              );
            }
          }
          if (onClick) onClick(e);
        }}
        disabled={loading || disabled}
        {...props}
      >
        {Icon && <Icon {...iconProps} />}
        {title}
        {RightIcon && <RightIcon className={`ms-${iconSpacing}`} />}
        {loading && <Loader className="ms-12" />}
      </BootstrapButton>
    );
  },
);

BWButton.displayName = 'Button';
