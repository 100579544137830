import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CameraIcon } from '../assets/icons/camera-dark.svg';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import {
  CustomDropdown,
  DropdownProps,
} from '../components/elements/CustomDropdown';
import { Loader } from '../components/elements/Loader';
import { ScenarioLabel } from '../components/elements/ScenarioLabel';
import { ScenarioChart } from '../components/group-observation/ScenarioChart';
import { ObservationList } from '../components/observation/ObservationList';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useScenarioContext } from '../contextapi/ScenarioProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { WhereQueryProps } from '../graphql/queries/observation';
import { useGetObservationsQuery } from '../hooks/graphql/observations';
import { mapToScenarioName } from '../lib/features/scenario';
import { generateTimeRangeUrlParam } from '../lib/helpers/timeRange';
import { DaysListProps } from '../typescript/grouping/grouping-observation';
import { isDefined } from '../utils/typeUtils';

type VariableProps = {
  from: number;
  until: number;
  group_id: string | undefined;
  offset: number;
  camera_ids?: number[];
  camera_ids_string?: string;
  order_by: { created: string };
  where: WhereQueryProps;
  limit: number;
};

export const ScenarioDetailsPage = () => {
  const { groupId: groupIdParam } = useParams();
  if (!groupIdParam) {
    throw new Error('Group ID is required');
  }
  const groupId = Number(groupIdParam);
  const [searchParams, setSearchParams] = useSearchParams();

  const { getScenariosByName } = useScenarioContext();
  const { gqlCameras } = useCameraContext();

  const limit = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : 10;
  const pageNumber = searchParams.get('pageNo')
    ? Number(searchParams.get('pageNo'))
    : 1;
  const filterParam = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';
  const cameraIdsParam = searchParams.get('cameraIds')
    ? decodeURI(String(searchParams.get('cameraIds')))
    : undefined;

  const cameraIds: number[] | undefined = isDefined(cameraIdsParam)
    ? JSON.parse(cameraIdsParam)
    : undefined;

  const { timeRange } = useTimeRangeContext();

  const filter: WhereQueryProps = JSON.parse(filterParam);

  const [queryVar, setQueryVar] = useState<WhereQueryProps>(filter);

  const defaultOffset = pageNumber === 1 && 0;
  const offset = defaultOffset ? 0 : (pageNumber - 1) * limit;
  const [isOrder, _setIsOrder] = useState(true);

  // camera
  const [allCameraList, setAllCameraList] = useState<Array<DropdownProps>>([]);

  const [variables] = useState<VariableProps>({
    from: timeRange.value,
    until: timeRange.end,
    group_id: groupIdParam,
    camera_ids: cameraIds,
    camera_ids_string: cameraIds ? `{${cameraIds.join(', ')}}` : undefined,
    offset,
    order_by: { created: !isOrder ? 'asc' : 'desc' },
    limit,
    where: {
      is_false_positive: { _eq: false },
      ...queryVar,
    },
  });

  const { loading, error, data, refetch } = useGetObservationsQuery(
    'group',
    variables,
  );

  const emptyList = data?.fetch_observations_by_group_id.length === 0;
  const groupItem = data?.observation_group_statistics[0];

  const scenarioNames = useMemo(
    () => (groupItem?.scenario_names || []).map(mapToScenarioName),
    [groupItem],
  );

  const filteredScenarios = useMemo(
    () => getScenariosByName(scenarioNames.filter(isDefined)),
    [scenarioNames, getScenariosByName],
  );

  useEffect(() => {
    if (gqlCameras) {
      const cameras = gqlCameras
        .filter((gqlCamera) =>
          gqlCamera.cameras_scenarios
            .map((item) => item.customer_scenario_label.name)
            .some((item) => scenarioNames.includes(item)),
        )
        .map((item) => ({
          title: item.name,
          value: item.id,
          isChecked: !isDefined(cameraIds) || cameraIds.includes(item.id),
        }));

      setAllCameraList(cameras);
    }
  }, [gqlCameras, scenarioNames, cameraIds]);

  useEffect(() => {
    refetch();
  }, [refetch, variables]);

  const timePeriod = useMemo(
    () => ({
      from: timeRange.value,
      until: timeRange.end,
    }),
    [timeRange],
  );

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  const onFilterApplied = async (
    whereQuery: WhereQueryProps,
    listLimit: number,
    pageNo: number,
    cameras?: DropdownProps[],
    updatedTimeRange?: DaysListProps,
  ) => {
    setQueryVar(whereQuery);
    const selectedCameraIds = (cameras || allCameraList)
      .filter((item) => item.isChecked)
      .map((item) => Number(item.value));

    const filterValue = JSON.stringify(whereQuery);
    const cameraIdsValue =
      selectedCameraIds.length > 0
        ? JSON.stringify(selectedCameraIds)
        : undefined;

    const timeRangeValue = generateTimeRangeUrlParam(
      updatedTimeRange || timeRange,
      true,
    );

    setSearchParams((searchParams) => {
      searchParams.set('limit', String(listLimit));
      searchParams.set('pageNo', String(pageNo));
      searchParams.set('filter', encodeURI(filterValue));
      if (cameraIdsValue) {
        searchParams.set('cameraIds', encodeURI(cameraIdsValue));
      } else {
        searchParams.delete('cameraIds');
      }
      searchParams.set('query', timeRangeValue);

      return searchParams;
    });
  };

  return (
    <>
      <Breadcrumb
        title={`${i18n.t('dashboard.title')}`}
        toUrl={`/dashboard?${generateTimeRangeUrlParam(timeRange)}`}
      />
      <div className="d-flex">
        <div className="mb-32 flex-1 d-flex align-items-center">
          <h5 className="m-0 me-24">{i18n.t('group_observations.title')}</h5>

          {!emptyList && (
            <p className="m-0 me-24">
              {`${i18n.t('td.scenario')}: `}
              {filteredScenarios.length === 0 ? (
                i18n.t('td.scenario_not_found')
              ) : (
                <ScenarioLabel scenario={filteredScenarios[0]} />
              )}
            </p>
          )}
        </div>
        <div className="mb-32 d-flex align-items-center justify-content-end">
          <TimeRangeDropdown
            className="me-12"
            onChange={(timeRange: DaysListProps) => {
              onFilterApplied({}, 10, 1, undefined, timeRange);
            }}
          />

          <CustomDropdown
            variant="checkbox"
            onSelectedAll={(value, fromChild) => {
              if (!fromChild) {
                setAllCameraList((cameras) =>
                  cameras.map((item) => ({
                    ...item,
                    isChecked: value,
                  })),
                );
              }
            }}
            selectedAll={allCameraList.every((item) => item.isChecked)}
            className="rounded-pill"
            hideContextData
            title={`${i18n.t('button.cameras')}`}
            selectAllTitle={`${i18n.t('button.select_all')}`}
            dropdownData={allCameraList}
            icon={CameraIcon}
            onClick={(value) => {
              setAllCameraList((cameras) =>
                cameras.map((item) => ({
                  ...item,
                  isChecked:
                    item.value === value.value
                      ? !item.isChecked
                      : item.isChecked,
                })),
              );
            }}
            confirmTitle={`${i18n.t('button.confirm')}`}
            showConfirmButton
            onConfirm={(cameras) => {
              onFilterApplied(queryVar, 10, 1, cameras);
            }}
          />
        </div>
      </div>

      {loading && (
        <div className="text-center empty-list">
          <Loader main />
        </div>
      )}
      {!loading && (
        <Row className="landing">
          {emptyList && (
            <Col className="mb-32">
              <h6>{`${i18n.t('group_detail.empty.no_observations_found_within')} ${i18n.t(timeRange.title).toLowerCase()}`}</h6>
            </Col>
          )}
          {groupItem && <ScenarioChart groups={groupItem} />}

          {!emptyList && (
            <Col lg={12}>
              <h6 className="mb-16">{i18n.t('table.observations_overview')}</h6>
              <ObservationList
                variant="group"
                groupId={groupId}
                cameraIds={cameraIds}
                timePeriod={timePeriod}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
