import { useMutation } from '@apollo/client';
import i18n from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ADD_OBSERVATION_BOOKMARK,
  ADD_OBSERVATION_BOOKMARKS,
  DELETE_OBSERVATION_BOOKMARK,
} from '../graphql/mutations/observation';

export function useAddBookmark() {
  const navigate = useNavigate();
  const [addBookmark, result] = useMutation(ADD_OBSERVATION_BOOKMARK);

  useEffect(() => {
    if (result.error) {
      toast.error(result.error.message);
    } else if (result.data) {
      toast.success(i18n.t('toast.success.bookmarked'), {
        autoClose: 500,
        onClick: async () => {
          navigate('/observations?type=2');
          //   window.location.reload();
        },
      });
    }
  }, [result.error, result.data, navigate]);

  return [addBookmark, result] as const;
}

export function useDeleteBookmark() {
  const [deleteBookmark, result] = useMutation(DELETE_OBSERVATION_BOOKMARK);

  useEffect(() => {
    if (result.error) {
      toast.error(result.error.message);
    } else if (result.data) {
      toast.success(i18n.t('toast.success.unbookmark'));
    }
  }, [result.error, result.data]);

  return [deleteBookmark, result] as const;
}

export function useAddBookmarks() {
  const navigate = useNavigate();
  const [addBookmarks, result] = useMutation(ADD_OBSERVATION_BOOKMARKS);

  useEffect(() => {
    if (result.error) {
      toast.error(result.error.message);
    } else if (result.data) {
      toast.success(i18n.t('toast.success.bookmarked'), {
        autoClose: 500,
        onClick: () => {
          navigate('/observations?type=2');
          // window.location.reload();
        },
      });
    }
  }, [result.error, result.data, navigate]);

  return [addBookmarks, result] as const;
}
