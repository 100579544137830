/* eslint-disable no-alert */
/* eslint-disable no-case-declarations */
import 'moment/locale/es';
import 'moment/locale/en-gb';
import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth';
import i18n from 'i18next';
import { useSetAtom } from 'jotai';
import { usePostHog } from 'posthog-js/react';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GRAPHQL_ERROR_MESSAGE_UNAUTHORIZED_REQUEST } from '../graphql/apolloClient';
import { useGetUserQuery } from '../hooks/graphql/user';
import { GqlCurrentUser } from '../typescript/user/user';
import { siteIdAtom } from '../utils/atoms';

type LoginResult = {
  loggedIn: boolean;
  mfaResolver?: MultiFactorResolver;
};

type AuthContextType = {
  user?: GqlCurrentUser;
  firebaseUser?: User | null;
  isAuthInitialized: boolean;
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<LoginResult>;
  loginWithMfa: (
    resolver: MultiFactorResolver,
    verificationId: string,
    verificationCode: string,
  ) => Promise<LoginResult>;
  logout: () => Promise<void>;
  refetchUser: ReturnType<typeof useGetUserQuery>['refetch'];
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<User | null>();
  const [shouldResetNavigation, setShouldResetNavigation] = useState(false);
  const setSiteId = useSetAtom(siteIdAtom);

  // If the user is logged in via Firebase, the user is authenticated
  const isAuthenticated = firebaseUser !== undefined && firebaseUser !== null;

  // Load the user account if the user is authenticated
  const {
    client: apolloClient,
    loading: isUserLoading,
    data,
    error,
    refetch: refetchUser,
  } = useGetUserQuery(!isAuthenticated);
  const user = useMemo(() => data?.user.at(0), [data]);

  useEffect(() => {
    if (error && error.message === GRAPHQL_ERROR_MESSAGE_UNAUTHORIZED_REQUEST) {
      setSiteId(null);
    }
  }, [error, setSiteId]);

  // If the user is authenticated and the user account is loaded, the user is logged in
  const isLoggedIn = isAuthenticated && !isUserLoading && !!user;

  const login = useCallback(
    async (email: string, password: string): Promise<LoginResult> => {
      const auth = getAuth();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        toast.success(i18n.t('toast.auth.login_success'));

        return {
          loggedIn: true,
        };
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/multi-factor-auth-required':
              const mfaResolver = getMultiFactorResolver(
                auth,
                error as MultiFactorError,
              );
              return {
                loggedIn: false,
                mfaResolver,
              };
            default:
              toast.error(i18n.t('toast.wrong_user_details'));
              return {
                loggedIn: false,
              };
          }
        }

        return {
          loggedIn: false,
        };
      }
    },
    [],
  );

  const loginWithMfa = useCallback(
    async (
      resolver: MultiFactorResolver,
      verificationId: string,
      verificationCode: string,
    ) => {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );

      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);

      const _userCredential =
        await resolver.resolveSignIn(multiFactorAssertion);

      // userCredential will also contain the user, additionalUserInfo, optional
      // credential (null for email/password) associated with the first factor sign-in.
      // For example, if the user signed in with Google as a first factor,
      // userCredential.additionalUserInfo will contain data related to Google
      // provider that the user signed in with.
      // - user.credential contains the Google OAuth credential.
      // - user.credential.accessToken contains the Google OAuth access token.
      // - user.credential.idToken contains the Google OAuth ID token.

      return {
        loggedIn: true,
      };
    },
    [],
  );

  const logout = useCallback(async () => {
    // Indicates that the user is logging out
    setFirebaseUser(undefined);
  }, []);

  // Mark the authentication as initialized when the Firebase user is known and the user account is loaded
  useEffect(() => {
    if (
      firebaseUser !== undefined &&
      (firebaseUser === null || !isUserLoading)
    ) {
      setIsAuthInitialized(true);
    }
  }, [firebaseUser, isUserLoading]);

  // Listen to Firebase authentication state changes to manage the user session
  useEffect(
    () =>
      getAuth().onAuthStateChanged(async (updatedUser) => {
        setFirebaseUser((currentUser) => {
          // If the user is still logged in, update the user
          if (updatedUser) {
            return updatedUser;
          }

          // If the user's session has been invalidated, reset the navigation
          if (currentUser) {
            setShouldResetNavigation(true);
          }

          // If the user is not logged in, clear the user
          return null;
        });
      }),
    [],
  );

  // Clear the user session when the user logs out
  useEffect(() => {
    const clearUserSession = async () => {
      // If the user logged out from the app, sign out from Firebase as well
      if (firebaseUser === undefined) {
        await signOut(getAuth());
      }

      // Clear the Apollo cache and PostHog session
      apolloClient.stop();
      await apolloClient.clearStore();
      posthog.reset();
    };

    // If initialization is complete and the user is not logged in, clear the user session
    if (isAuthInitialized && !firebaseUser) {
      clearUserSession();
    }
  }, [apolloClient, firebaseUser, isAuthInitialized, posthog]);

  // If the user's session has been invalidated, reset the navigation state
  useEffect(() => {
    if (shouldResetNavigation) {
      navigate('/login', { replace: true, state: {} });
      setShouldResetNavigation(false);
    }
  }, [shouldResetNavigation, navigate]);

  const context = useMemo(
    () =>
      ({
        user,
        firebaseUser,
        isAuthInitialized,
        isLoggedIn,
        login,
        logout,
        loginWithMfa,
        refetchUser,
      }) satisfies AuthContextType,
    [
      user,
      firebaseUser,
      isAuthInitialized,
      isLoggedIn,
      login,
      logout,
      loginWithMfa,
      refetchUser,
    ],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
