/** @jsxImportSource @emotion/react */
import i18n from 'i18next';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as SpanishFlag } from '../../assets/flags/flag-es.svg';
import { ReactComponent as EnglishFlag } from '../../assets/flags/flag-uk.svg';
import { useTranslationContext } from '../../contextapi/TranslationProvider';
import { useTrackEvent } from '../../hooks/track_event';
import { dropdownCss } from '../elements/CustomDropdown';

export function LanguageDropdown() {
  const { language, setLanguage } = useTranslationContext();
  const trackEvent = useTrackEvent();

  const handleLanguageChange = (language: 'en' | 'es') => {
    trackEvent('language_change', {
      language,
    });
    setLanguage(language);
  };

  return (
    <Dropdown drop="up" className="language-dropdown" css={dropdownCss}>
      <Dropdown.Toggle variant="transparent">
        {language === 'en' && <EnglishFlag />}
        {language === 'es' && <SpanishFlag />}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleLanguageChange('en')}>
          <EnglishFlag className="me-2" />
          {i18n.t('button.english')}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleLanguageChange('es')}>
          <SpanishFlag className="me-2" />
          {i18n.t('button.spanish')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
