import posthog from 'posthog-js';

export function initPosthog() {
  const phApiKey = process.env.REACT_APP_POSTHOG_API_KEY;
  const isPosthogEnabled = localStorage.getItem('ph_enabled') === 'true';
  if (!phApiKey || !isPosthogEnabled) {
    return;
  }

  posthog.init(phApiKey, {
    api_host: 'https://eu.posthog.com',
    cross_subdomain_cookie: false,
    opt_out_persistence_by_default: true,
    opt_out_capturing_by_default: true,
    autocapture: true,
    capture_pageview: false,
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '*',
      maskTextFn: (text, element) => {
        // this checks [data-ph-capture="true"] attribute on the element
        if (element?.dataset.phCapture === 'true') {
          return text;
        }
        return '*'.repeat(text.trim().length);
      },
    },
  });

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_DEV_FORCE_POSTHOG_OPT_IN === 'true'
  ) {
    posthog.opt_in_capturing();
  } else {
    window._hsp.push([
      'addPrivacyConsentListener',
      (consent) => {
        const isAnalyticsAllowed = consent.categories.analytics;
        if (isAnalyticsAllowed) {
          posthog.opt_in_capturing();
        } else {
          posthog.opt_out_capturing();
        }
      },
    ]);
  }
}
