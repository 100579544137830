import { gql } from '@apollo/client';

export const USER_FRAGMENTS = gql`
  fragment UserFragment on users {
    id
    email
    title
    phoneNo: phone_no
    username: name
    isAdmin: is_admin
  }

  fragment CurrentUserFragment on user {
    id
    email
    title
    phoneNo: phone_no
    username: name
    isAdmin: is_admin

    exclude_from_tracking
    phoneNoVerified: phone_no_verified
    trackingId: tracking_id
    customer {
      id
      feature_flags
      trackingId: tracking_id
      sites {
        name
        id
      }
      customer_scenario_labels {
        id
        name
        custom_name
        scenario {
          name
        }
        label {
          name
        }
      }

      users {
        ...UserFragment
      }
    }
  }
`;

export const GET_USER = gql`
  ${USER_FRAGMENTS}

  query GET_USER {
    user {
      ...CurrentUserFragment
    }
  }
`;

export const GET_USER_BY_ID = gql`
  ${USER_FRAGMENTS}

  query GET_USER_BY_ID($id: Int!) {
    users_by_pk(id: $id) {
      ...UserFragment
    }
  }
`;
