import i18n from 'i18next';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TotalObservations } from '../../assets/dashboard/activity.svg';
import { ReactComponent as Alerts } from '../../assets/dashboard/bell.svg';
import { ReactComponent as ActiveCameras } from '../../assets/dashboard/camera.svg';
import { ReactComponent as TodayObservations } from '../../assets/dashboard/eye.svg';
import { ReactComponent as Assigned } from '../../assets/dashboard/helmat.svg';
import { useAuthenticatedUserContext } from '../../contextapi/AuthenticatedUserProvider';
import {
  GetDashboardDataQueryVariables,
  useGetDashboardData,
} from '../../hooks/graphql/dashboard';
import { ObservationListTab } from '../../pages/ObservationListPage';
import { COLOR_STATUS_ERROR, COLOR_STATUS_SUCCESS } from '../../scss/colors';
import { Badge } from '../elements/Badge';
import { DetailCard } from '../elements/DetailCard';
import { FlexCol } from '../elements/FlexCol';
import { SectionTitle } from '../elements/SectionTitle';
import { TrendLabel } from '../elements/TrendLabel';

export function SiteOverview() {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUserContext();

  const now = useMemo(() => moment().unix(), []);
  const startOfDay = useMemo(
    () => moment.unix(now).startOf('day').unix(),
    [now],
  );
  const todayLabel = useMemo(
    () => moment.unix(now).format('MMM Do, yyyy'),
    [now],
  );
  const thirtyDaysAgo = useMemo(
    () => moment.unix(now).subtract(30, 'days').startOf('day').unix(),
    [now],
  );
  const sixtyDaysAgo = useMemo(
    () => moment.unix(thirtyDaysAgo).subtract(30, 'days').startOf('day').unix(),
    [thirtyDaysAgo],
  );
  const alertsCreatedAt = useMemo(
    () => ({
      _gte: moment.unix(thirtyDaysAgo).utc().format('YYYY-MM-DDTHH:mm:ss'),
      _lte: moment.unix(now).utc().format('YYYY-MM-DDTHH:mm:ss'),
    }),
    [now, thirtyDaysAgo],
  );

  const queryVariables: GetDashboardDataQueryVariables = {
    whereToday: {
      is_false_positive: { _eq: false },
      system_timestamp: {
        _gte: startOfDay,
        _lte: now,
      },
    },
    whereTotal: {
      is_false_positive: { _eq: false },
      system_timestamp: {
        _gte: thirtyDaysAgo,
        _lte: now,
      },
    },
    whereTotalPrevious: {
      is_false_positive: { _eq: false },
      system_timestamp: {
        _gte: sixtyDaysAgo,
        _lte: thirtyDaysAgo,
      },
    },
    whereAssigned: {
      is_false_positive: { _eq: false },
      responder: { _eq: user.email },
    },
    whereOpenedAssigned: {
      is_false_positive: { _eq: false },
      responder: { _eq: user.email },
      status: { _eq: 'open' },
    },
    whereAlert: { created_at: alertsCreatedAt },
  };

  const { loading, error, data } = useGetDashboardData(queryVariables);

  const navigateToObservation = (
    tab: ObservationListTab,
    whereQuery?: object,
  ) => {
    const isFilter = whereQuery ? `&filter=${JSON.stringify(whereQuery)}` : '';
    const encodeUrl = encodeURI(isFilter);

    navigate({
      pathname: '/observations',
      search: `?tab=${tab}&pageNo=${1}&limit=${10}${encodeUrl}`,
    });
  };

  const navigateToAlerts = (whereQuery?: object) => {
    const isFilter = whereQuery ? `&filter=${JSON.stringify(whereQuery)}` : '';
    const encodeUrl = encodeURI(isFilter);

    navigate({
      pathname: '/alerts',
      search: `?pageNo=${1}&limit=${10}${encodeUrl}`,
    });
  };

  if (error) {
    // TODO: Handle error state
    return null;
  }

  const onlineCameraCount = data?.onlineCameras.aggregate.count || 0;
  const offlineCameraCount = data?.offlineCameras.aggregate.count || 0;

  return (
    <Row className="group-observations">
      <Col md={12}>
        <SectionTitle title={i18n.t('dashboard.site_overview')} />
      </Col>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <FlexCol sm={2}>
          <DetailCard
            icon={TodayObservations}
            title={i18n.t('site_overview.todays_observations')}
            footer={todayLabel}
            isLoading={loading}
            onClick={() => {
              const { system_timestamp } = queryVariables.whereToday;
              navigateToObservation('all', { system_timestamp });
            }}
            trackingContext={{
              action: 'observations_page_open',
              observations_view: 'today',
            }}
          >
            {data?.observationsToday.aggregate.count}
          </DetailCard>
        </FlexCol>

        <FlexCol sm={2}>
          <DetailCard
            icon={TotalObservations}
            badge={
              data ? (
                <TrendLabel
                  trend={{
                    current: data.observationsTotal.aggregate.count,
                    previous: data.observationsTotalPrevious.aggregate.count,
                    preferredDirection: 'down',
                  }}
                />
              ) : null
            }
            title={i18n.t('site_overview.total_observations')}
            footer={i18n.t('site_overview.total_observations.last_30_days')}
            isLoading={loading}
            onClick={() => {
              const { system_timestamp } = queryVariables.whereTotal;
              navigateToObservation('all', { system_timestamp });
            }}
            trackingContext={{
              action: 'observations_page_open',
              observations_view: 'all',
            }}
          >
            {data?.observationsTotal.aggregate.count}
          </DetailCard>
        </FlexCol>

        <FlexCol sm={2}>
          <DetailCard
            icon={Assigned}
            title={i18n.t('site_overview.assigned')}
            badge={
              <Badge>
                {i18n.t('badge.observations.open', {
                  count: data?.openAssignedObservations.aggregate.count,
                })}
              </Badge>
            }
            isLoading={loading}
            onClick={() => {
              navigateToObservation('assigned_to_me');
            }}
            trackingContext={{
              action: 'observations_page_open',
              observations_view: 'assigned_to_me',
            }}
          >
            {data?.observationsAssigned.aggregate.count}
          </DetailCard>
        </FlexCol>

        <FlexCol sm={2}>
          <DetailCard
            icon={Alerts}
            title={i18n.t('site_overview.alerts')}
            footer={i18n.t('site_overview.alerts.last_30_days')}
            isLoading={loading}
            onClick={() => {
              const { whereAlert } = queryVariables;
              navigateToAlerts(whereAlert);
            }}
            trackingContext={{
              action: 'alerts_page_open',
            }}
          >
            {data?.alertsTotal.aggregate.count}
          </DetailCard>
        </FlexCol>

        <FlexCol sm={2}>
          <DetailCard
            icon={ActiveCameras}
            title={i18n.t('site_overview.cameras')}
            badge={
              offlineCameraCount > 0 ? (
                <Badge
                  color={COLOR_STATUS_ERROR}
                  backgroundColor={`${COLOR_STATUS_ERROR}30`}
                >
                  {i18n.t('badge.cameras.offline', {
                    count: offlineCameraCount,
                  })}
                </Badge>
              ) : onlineCameraCount > 0 ? (
                <Badge
                  color={COLOR_STATUS_SUCCESS}
                  backgroundColor={`${COLOR_STATUS_SUCCESS}30`}
                >
                  {i18n.t('badge.cameras.online', { count: onlineCameraCount })}
                </Badge>
              ) : null
            }
            isLoading={loading}
            onClick={() => {
              navigate('/cameras');
            }}
            trackingContext={{
              action: 'cameras_page_open',
            }}
          >
            {onlineCameraCount + offlineCameraCount}
          </DetailCard>
        </FlexCol>
      </div>
    </Row>
  );
}
