import React from 'react';
import { fontSize, spacing } from '../../scss/spacing';
import { TrendIcon, TrendIconColor } from '../icons/TrendIcon';

export type Trend = {
  previous: number;
  current: number;
  preferredDirection: 'up' | 'down';
};

enum TrendLabelColor {
  PREFERRED = '#12B76A',
  UNDESIRED = '#F97066',
}

type Props = {
  trend: Trend;
};

export const TrendLabel = ({ trend }: Props) => {
  const value =
    trend.previous === 0
      ? 100
      : ((trend.current - trend.previous) / trend.previous) * 100;

  const absoluteValue = Math.trunc(Math.abs(value));
  const label = value >= 0 ? `+${absoluteValue}%` : `-${absoluteValue}%`;

  const trendDirection = value >= 0 ? 'up' : 'down';
  const isPreferredDirection =
    trend.preferredDirection === trendDirection || value === 0;

  const spanColor = isPreferredDirection
    ? TrendLabelColor.PREFERRED
    : TrendLabelColor.UNDESIRED;

  return (
    <span
      className="ph-no-capture"
      style={{
        padding: `0 ${spacing(2)}`,
        borderRadius: spacing(1),
        height: spacing(6),
        display: 'flex',
        alignItems: 'center',
        gap: spacing(1),
        fontWeight: 'bold',
        fontSize: fontSize(1),
        whiteSpace: 'nowrap',
        backgroundColor: `${spanColor}30`,
        color: spanColor,
      }}
    >
      <TrendIcon
        direction={trendDirection}
        strokeColor={
          isPreferredDirection
            ? TrendIconColor.PREFERRED
            : TrendIconColor.UNDESIRED
        }
      />
      {label}
    </span>
  );
};
