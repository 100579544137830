/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */

import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import { CameraTooltip } from './CameraTooltip';
import { DaytimeTooltip } from './DaytimeTooltip';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import {
  COLOR_STATUS_ERROR,
  COLOR_STATUS_NEUTRAL,
  COLOR_STATUS_SUCCESS,
} from '../../scss/colors';
import {
  GroupingItemProp,
  TimeseriesWithDate,
  updatedDate,
} from '../../typescript/grouping/grouping-observation';
import { CustomTooltip } from '../group/CustomTooltip';
import { TrendIcon, TrendIconColor } from '../icons/TrendIcon';

interface Props {
  groups: GroupingItemProp;
}

export function ScenarioChart({ groups }: Props) {
  const { theme } = useThemeContext();
  const { timeRange } = useTimeRangeContext();

  const formated: Array<TimeseriesWithDate> = groups.timeseries.map(
    (item: { count: number; range: string }) => ({
      count: item.count,
      date: moment(item.range).format('DD MMM'),
      range: item.range,
    }),
  );

  const colorForNull = COLOR_STATUS_NEUTRAL;
  const colorForSuccess = COLOR_STATUS_SUCCESS;
  const colorForError = COLOR_STATUS_ERROR;

  const isDownwardTrend = groups === undefined || groups.trend <= 0;
  const trendColor =
    groups === undefined || groups.trend === null
      ? colorForNull
      : isDownwardTrend
        ? colorForSuccess
        : colorForError;

  const DaytimeInsightsCOLORS = ['#29AD59', '#37D06F', '#ADEBC4'];

  const categorizeData = () => {
    const { morning, evening, midday } = groups.observations_by_time_of_day;
    const morningCount = { count: morning, name: 'Morning' };
    const eveningCount = { count: evening, name: 'Evening' };
    const middayCount = { count: midday, name: 'Midday' };

    return [morningCount, eveningCount, middayCount];
  };

  const graphTitle =
    timeRange.value === 1 || timeRange.value === 7
      ? i18n.t('group_observations.hourly_statistic')
      : i18n.t('group_observations.daily_statistic');
  return (
    <>
      <Col lg={2} className="mb-32 d-flex flex-column">
        <div
          className={`p-3 border border-${theme} border-radius d-flex flex-column justify-content-between flex-1 mb-24`}
        >
          <p className="mb-1 light-gray">
            {timeRange.text === 'today'
              ? i18n.t(timeRange.title)
              : timeRange.text === 'custom'
                ? i18n.t('text.selected_period')
                : `${i18n.t('text.last')} ${i18n.t(timeRange.title)}`}
          </p>
          <h4 className="weight-400 ph-no-capture">
            {groups.count}
            <span
              className="ph-no-capture"
              style={{
                color: trendColor,
                fontWeight: 'bold',
                fontSize: 14,
                whiteSpace: 'nowrap',
              }}
            >
              {!groups.trend ? (
                ''
              ) : (
                <>
                  <TrendIcon
                    className="me-1"
                    direction={isDownwardTrend ? 'down' : 'up'}
                    strokeColor={
                      isDownwardTrend
                        ? TrendIconColor.PREFERRED
                        : TrendIconColor.UNDESIRED
                    }
                  />
                  {`${String(Math.trunc(groups.trend)).replace('-', '')}%`}
                </>
              )}
            </span>
          </h4>
        </div>
        <div
          className={`p-3 border border-${theme} border-radius d-flex flex-column justify-content-between flex-1`}
        >
          <p className="mb-1 light-gray">
            {timeRange.text === 'today'
              ? i18n.t('dropdown.daylist.yesterday')
              : timeRange.text === 'custom'
                ? i18n.t('text.previous_period')
                : `${i18n.t('text.previous')} ${i18n.t(timeRange.title)}`}
          </p>
          <h4 className="m-0 weight-400 ph-no-capture">
            {groups.previous_count}
          </h4>
        </div>
      </Col>
      <Col lg={2} className="mb-32">
        <div className={`h-100 p-24 border border-${theme} border-radius`}>
          <h6 className="weight-600 mb-20">
            {i18n.t('chart.daytime_insights')}
          </h6>
          <ResponsiveContainer
            width="100%"
            height={180}
            className="ph-no-capture"
          >
            <PieChart>
              <Pie
                stroke="none"
                data={categorizeData()}
                innerRadius={55}
                outerRadius={70}
                paddingAngle={4}
                cornerRadius={2}
                dataKey="count"
              >
                {categorizeData().map((entry, index) => (
                  <Cell
                    key={`cell-${entry}`}
                    fill={DaytimeInsightsCOLORS[index]}
                  />
                ))}
              </Pie>

              <Tooltip content={<DaytimeTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Col>
      <Col lg={2} className="mb-32">
        <div className={`h-100 p-24 border border-${theme} border-radius`}>
          <h6 className="weight-600 mb-20">
            {i18n.t('chart.camera_statistic')}
          </h6>
          <ResponsiveContainer
            width="100%"
            height={180}
            className="ph-no-capture"
          >
            <BarChart data={groups.cameras}>
              <defs>
                <linearGradient id="colorCountUp" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#ADEBC4" stopOpacity={1} />
                  <stop offset="100%" stopColor="#37D06F" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<CameraTooltip />}
              />
              <Bar
                dataKey="count"
                barSize={16}
                fill={`url(#${'colorCountUp'})`}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Col>
      <Col lg={6} className="mb-32">
        <div className={`h-100 p-24 border border-${theme} border-radius`}>
          <h6 className="weight-600 mb-20">{graphTitle}</h6>
          <ResponsiveContainer
            width="100%"
            height={180}
            className="ph-no-capture"
          >
            <AreaChart
              data={updatedDate(
                formated,
                timeRange.text,
                timeRange.value,
                timeRange.end,
                timeRange.isHourly,
              )}
            >
              <defs>
                <linearGradient id="colorCountUp" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="60%"
                    stopColor={colorForSuccess}
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="100%"
                    stopColor={`${theme === 'dark' ? '#1F2321' : '#FFFFFF'}`}
                    stopOpacity={0.3}
                  />
                </linearGradient>
                <linearGradient id="colorCountDown" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="60%"
                    stopColor={colorForError}
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="100%"
                    stopColor={`${theme === 'dark' ? '#1F2321' : '#FFFFFF'}`}
                    stopOpacity={0.3}
                  />
                </linearGradient>
                <linearGradient id="colorCountNull" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="60%"
                    stopColor={colorForNull}
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="100%"
                    stopColor={`${theme === 'dark' ? '#1F2321' : '#FFFFFF'}`}
                    stopOpacity={0.3}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeOpacity={0.5}
                strokeDasharray="0"
                vertical={false}
              />
              <YAxis
                tickMargin={10}
                dataKey="count"
                tickCount={10}
                domain={['dataMin', 'dataMax']}
                axisLine={false}
                tickLine={false}
              />

              <Tooltip content={<CustomTooltip />} />
              <Area
                dataKey="count"
                stroke={
                  !groups.trend
                    ? colorForNull
                    : isDownwardTrend
                      ? colorForSuccess
                      : colorForError
                }
                strokeWidth={1.5}
                fillOpacity={1}
                fill={`url(#${
                  !groups.trend
                    ? 'colorCountNull'
                    : isDownwardTrend
                      ? 'colorCountUp'
                      : 'colorCountDown'
                })`}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Col>
    </>
  );
}
