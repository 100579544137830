/* eslint-disable import/no-mutable-exports */
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LOCAL_STORAGE_KEYS } from '../consts';
import { fetchValidIdToken, signOutWithNotification } from '../utils/firebase';
import { isNotNull, isObject } from '../utils/typeUtils';

export const GRAPHQL_API_URL = `${process.env.REACT_APP_GRAPHQL_URL}/v1/graphql`;
export const GRAPHQL_ERROR_MESSAGE_UNAUTHORIZED_REQUEST =
  'Authentication hook unauthorized this request';
export const GRAPHQL_ERROR_MESSAGE_INVALID_RESPONSE =
  'Invalid response from authorization hook';

// if (process.env.NODE_ENV === 'development') {
//   loadDevMessages();
//   loadErrorMessages();
// }

const authLink = setContext(async (_, { headers }) => {
  const idToken = await fetchValidIdToken();
  const siteId = localStorage.getItem(LOCAL_STORAGE_KEYS.SITE_ID);

  if (isObject(headers) && headers['X-Site-Id'] === undefined) {
    const newHeaders = { ...headers };
    delete newHeaders['X-Site-Id'];
    return {
      headers: {
        ...newHeaders,
        ...(isNotNull(idToken) && { Authorization: idToken }),
      },
    };
  }

  return {
    headers: {
      ...headers,
      ...(isNotNull(idToken) && { Authorization: idToken }),
      ...(isNotNull(siteId) && { 'X-Site-Id': siteId }),
    },
  };
});

const authErrorLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors?.some(
      (error) =>
        error.message === GRAPHQL_ERROR_MESSAGE_INVALID_RESPONSE ||
        error.message === GRAPHQL_ERROR_MESSAGE_UNAUTHORIZED_REQUEST,
    )
  ) {
    signOutWithNotification();
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    ListObservationGroupsOutput: {
      fields: {
        cameras: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
        timeseries: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
      },
    },
    observations: {
      fields: {
        observation_users: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
      },
    },
  },
});

export const apolloClient = new ApolloClient({
  link: from([
    authErrorLink,
    authLink,
    createHttpLink({ uri: GRAPHQL_API_URL }),
  ]),
  cache,
});
