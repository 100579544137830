import {
  getAuth,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth';
import i18n from 'i18next';
import React, { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BWButton } from '../components/elements/BWButton';
import { InputField } from '../components/elements/InputField';
import { LoginContainer } from '../components/login/LoginContainer';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTrackEvent } from '../hooks/track_event';

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { login, loginWithMfa } = useAuthContext();
  const trackEvent = useTrackEvent();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver>();
  const [recaptchaWidgetId, setRecaptchaWidgetId] = useState<number>();
  const [verificationId, setVerificationId] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');

  useEffect(() => {
    if (
      !mfaResolver ||
      recaptchaWidgetId !== undefined ||
      mfaResolver.hints.at(0)?.factorId !== PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      return;
    }

    const phoneInfoOptions = {
      multiFactorHint: mfaResolver.hints[0],
      session: mfaResolver.session,
    };

    const auth = getAuth();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        callback: async (_response: unknown) => {
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier,
          );
          toast.info(i18n.t('toast.auth.otp_sent'));
          setVerificationId(verificationId);
        },
      },
    );

    recaptchaVerifier.render().then((widgetId) => {
      setRecaptchaWidgetId(widgetId);
    });
  }, [mfaResolver, recaptchaWidgetId]);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { loggedIn, mfaResolver } = await login(email, password);
      trackEvent('user_login', {
        success: loggedIn,
      });

      if (loggedIn) {
        navigate(location.state?.prevRoute || '/');
      } else if (mfaResolver) {
        setMfaResolver(mfaResolver);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMFA = async (e: FormEvent<HTMLFormElement>) => {
    if (mfaResolver === undefined || verificationId === undefined) {
      return;
    }

    e.preventDefault();
    setLoading(true);
    try {
      const result = await loginWithMfa(
        mfaResolver,
        verificationId,
        verificationCode,
      );
      trackEvent('user_login', {
        success: result.loggedIn,
        mfa: true,
      });
      if (result.loggedIn) {
        navigate(location.state?.prevRoute || '/');
      }
    } finally {
      setLoading(false);
    }
  };

  const loginStep = mfaResolver
    ? verificationId
      ? 'mfa'
      : 'recaptcha'
    : 'credentials';

  return (
    <LoginContainer>
      <h5 className="mb-32">{i18n.t('login.title.sign_in')}</h5>
      {loginStep !== 'credentials' && (
        <p
          className="mb-12"
          style={{
            fontWeight: '500',
          }}
        >
          {loginStep === 'recaptcha'
            ? i18n.t('login.description.recaptcha')
            : i18n.t('login.description.mfa')}
        </p>
      )}

      <Form onSubmit={loginStep === 'credentials' ? handleLogin : handleMFA}>
        {loginStep === 'credentials' && (
          <>
            <InputField
              className="mb-12"
              controlId="formEmail"
              type="email"
              required
              title={`${i18n.t('input.email.title')}`}
              placeholder={`${i18n.t('input.email.placeholder')}`}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />

            <InputField
              className="mb-12"
              type="password"
              controlId="formPassword"
              title={`${i18n.t('input.password.title')}`}
              placeholder={`${i18n.t('input.password.placeholder')}`}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />

            <div className="mb-12">
              <Link
                to="/forgot-password"
                className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
                style={{ textDecoration: 'none' }}
              >
                <BWButton
                  title={i18n.t('button.forgot')}
                  variant="transparent"
                  style={{ padding: 0 }}
                  trackingProperties={{
                    action: 'open_forgot_password_page',
                  }}
                />
              </Link>
            </div>

            <BWButton
              id="sign-in-button"
              title={i18n.t('login.submit.sign_in')}
              className="button-with-loader w-100"
              variant="primary"
              type="submit"
              disabled={loading}
              loading={loading}
              doNotTrack
            />
          </>
        )}

        {loginStep === 'recaptcha' && (
          <div className="mb-12" id="recaptcha-container" />
        )}

        {loginStep === 'mfa' && (
          <>
            {verificationId && (
              <Form.Group className="mb-12" controlId="formVerificationCode">
                {/* <Form.Label className="weight-500">
                  {i18n.t('login.input.verification_code')}
                </Form.Label> */}
                <div className="ph-no-capture">
                  <OTPInput
                    containerStyle={{ justifyContent: 'center' }}
                    inputStyle={{ width: 30, height: 40, fontWeight: 'bold' }}
                    value={verificationCode}
                    onChange={setVerificationCode}
                    numInputs={6}
                    renderSeparator={<span> - </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </Form.Group>
            )}

            <BWButton
              title={i18n.t('login.submit.verify')}
              className="button-with-loader w-100"
              variant="primary"
              type="submit"
              disabled={verificationCode.length !== 6 || loading}
              loading={loading}
              doNotTrack
            />
          </>
        )}
      </Form>
    </LoginContainer>
  );
};
