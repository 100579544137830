import { gql } from '@apollo/client';

export const DELETE_ALERT_RULE_BY_ID = gql`
  mutation DELETE($id: Int!) {
    alert: delete_alert_rules_by_pk(id: $id) {
      id
    }
  }
`;

export const TEST_CHANNEL = gql`
  mutation TEST($channel_name: String) {
    alert_trigger_test(args: { channel_name: $channel_name }) {
      id
      email
    }
  }
`;
