import React from 'react';
import { Placeholder } from 'react-bootstrap';
import type { PlaceholderProps } from 'react-bootstrap/esm/Placeholder';
import { PLACEHOLDER_COLOR } from '../../scss/colors';

export function CustomPlaceholder({ style, ...props }: PlaceholderProps) {
  return (
    <Placeholder
      {...props}
      style={{
        color: PLACEHOLDER_COLOR,
        ...style,
      }}
    />
  );
}
