import React from 'react';
import {
  SECONDARY_COLOR_COMPLIMENT,
  SECONDARY_LIGHT_COLOR,
} from '../../scss/colors';
import { spacing } from '../../scss/spacing';

type Props = {
  title?: string;
  shortcutIcons?: React.ReactNode[];
};

export function ShortcutLine({ title, shortcutIcons }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: `${spacing(4)}`,
      }}
    >
      <p style={{ margin: 0 }}>{title}</p>
      <div
        style={{
          display: 'flex',
          gap: '4px',
        }}
      >
        {shortcutIcons &&
          shortcutIcons.map((icon, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                backgroundColor: `${SECONDARY_COLOR_COMPLIMENT}`,
                padding: `${spacing(1)} ${spacing(2)}`,
                borderRadius: '5px',
                fontWeight: 'bolder',
                minWidth: '24px',
                minHeight: '24px',
                fontSize: '12px',
                color: `${SECONDARY_LIGHT_COLOR}`,
              }}
            >
              {icon}
            </span>
          ))}
      </div>
    </div>
  );
}
