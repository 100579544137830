import i18n from 'i18next';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Scenario } from '../../../lib/features/scenario';
import { isNotNull } from '../../../utils/typeUtils';
import {
  CustomDropdown,
  mapScenarioToDropdownProps,
} from '../../elements/CustomDropdown';

type ScenarioAndActive = [Scenario, boolean];

interface Props {
  selectedScenarios: ScenarioAndActive[];
  onClick: (scenario: ScenarioAndActive[]) => void;
}

export function ScenarioFilter({ selectedScenarios, onClick }: Props) {
  const [selectAllScenario, setSelectAllScenario] = useState<boolean>(
    selectedScenarios.length ===
      selectedScenarios.filter(([_, active]) => active).length,
  );

  const dropdownData = selectedScenarios.map(mapScenarioToDropdownProps);

  return (
    <Col md={12}>
      <hr />
      <p className="mb-1 weight-600">{i18n.t('filter.scenarios.title')}</p>
      <p className="sub-color">{i18n.t('filter.scenarios.body')}</p>

      <CustomDropdown
        variant="scenario"
        showCheckboxes
        onSelectedAll={(value, fromChild) => {
          setSelectAllScenario(value);
          if (!fromChild) {
            const selectAll = selectedScenarios.map(
              ([scenario]) =>
                [
                  {
                    ...scenario,
                  },
                  value,
                ] satisfies ScenarioAndActive,
            );
            onClick(selectAll);
          }
        }}
        selectedAll={selectAllScenario}
        className="rounded-pill"
        title={`${i18n.t('button.show_all_scenarios')}`}
        selectAllTitle={`${i18n.t('button.select_all')}`}
        dropdownData={dropdownData}
        onClick={({ scenario }) => {
          const scenarios = dropdownData
            .map((item) => {
              if (!item.scenario) {
                return null;
              }

              if (item.scenario.name === scenario?.name) {
                return [
                  {
                    ...item.scenario,
                  },
                  !item.isChecked,
                ] satisfies ScenarioAndActive;
              }
              return [
                item.scenario,
                item.isChecked ?? false,
              ] satisfies ScenarioAndActive;
            })
            .filter(isNotNull);

          onClick(scenarios);
        }}
      />
    </Col>
  );
}
