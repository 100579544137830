import i18n from 'i18next';
import { useAtomValue } from 'jotai';
import Konva from 'konva';
import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScenarioContext } from './ScenarioProvider';
import {
  addCameras,
  CameraAddPayload,
  CameraInitPayload,
  CameraRemovePayload,
  doInitCamera,
  doRemoveCamera,
  doUpdateCamera,
  urlTempImage,
} from '../apis/api-request';
import {
  Camera,
  ScenarioPerimeter,
  useGetCamerasQuery,
} from '../hooks/graphql/camera';
import { getScenarioTitle, Scenario } from '../lib/features/scenario';
import {
  CameraProps,
  CameraUpdatePayload,
  transformCameraToUpdatePayload,
} from '../typescript/camera/camera';
import { TimePeriod } from '../typescript/datetime';
import { siteIdAtom } from '../utils/atoms';
import { isDefined, Nullable } from '../utils/typeUtils';

type HeatmapExportData = {
  imageElement: Nullable<HTMLImageElement>;
  scenario?: Scenario;
  timePeriod: TimePeriod;
};

type CameraContextType = {
  gqlCamera?: Camera;
  cameraList: Array<CameraProps>;
  gqlCameras: Camera[];
  isCameraListLoading: boolean;
  cameraImageUrl?: string;
  cameraScenarios: Scenario[];

  setActiveCameraId: (cameraId: number) => void;

  addCamera(payload: CameraAddPayload): Promise<boolean>;
  removeCamera: (payload: CameraRemovePayload) => Promise<boolean>;
  updateCamera: (payload: Partial<CameraUpdatePayload>) => Promise<boolean>;
  resetCamera: (payload: CameraInitPayload) => Promise<boolean>;

  setHeatmapExportData: React.Dispatch<
    React.SetStateAction<HeatmapExportData | undefined>
  >;
  setDangerZoneExportLayer: React.Dispatch<
    React.SetStateAction<Konva.Layer | null>
  >;
  exportCameraImage: () => void;
  fetchCameraImageUrl: (cameraId: number) => Promise<string | null>;
};

const CameraContext = createContext<CameraContextType | undefined>(undefined);

export const useCameraContext = () => {
  const context = useContext(CameraContext);
  if (!context) {
    throw new Error('useCameraContext must be used within a CameraProvider');
  }

  return context;
};

export function CameraProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { getScenariosByCamera } = useScenarioContext();
  const siteId = useAtomValue(siteIdAtom);

  const [heatmapExportData, setHeatmapExportData] =
    useState<HeatmapExportData>();
  const [dangerZoneExportLayer, setDangerZoneExportLayer] =
    useState<Konva.Layer | null>(null);

  const [cameraImageUrls, setCameraImageUrls] = useState<
    Record<string, string | null>
  >({});
  const [activeCameraId, setActiveCameraId] = useState<number | null>(null);
  const [activeCameraImageUrl, setActiveCameraImageUrl] = useState<string>();

  const fetchCameraImageUrl = useCallback(
    async (cameraId: number) => {
      const cameraImageKey = `${cameraId}`;
      if (!isDefined(cameraImageUrls[cameraImageKey])) {
        const imageResponse = await urlTempImage(siteId, {
          camera_id: cameraId,
        });

        const imageUrl: string | null = imageResponse?.data.message || null;

        setCameraImageUrls((prev) => ({
          ...prev,
          [cameraImageKey]: imageUrl,
        }));

        return imageUrl;
      }

      return cameraImageUrls[cameraImageKey];
    },
    [cameraImageUrls, siteId],
  );

  const {
    data: camerasData,
    refetch: refetchCameras,
    loading: isCameraListLoading,
  } = useGetCamerasQuery();

  useEffect(() => {
    refetchCameras();
  }, [siteId, refetchCameras]);

  const gqlCameras = useMemo(() => camerasData?.cameras || [], [camerasData]);
  const gqlCamera = useMemo(
    () => gqlCameras.find((item) => item.id === activeCameraId),
    [activeCameraId, gqlCameras],
  );
  const cameraList = useMemo(
    () => gqlCameras.map(transformCameraToUpdatePayload),
    [gqlCameras],
  );

  useEffect(() => {
    if (!isCameraListLoading && activeCameraId && !gqlCamera) {
      navigate('/cameras');
      setActiveCameraId(null);
      setCameraImageUrls({});
    }
  }, [activeCameraId, gqlCamera, isCameraListLoading, navigate]);

  useEffect(() => {
    const fetchCameraImage = async (cameraId: number) => {
      const imageUrl = await fetchCameraImageUrl(cameraId);
      setActiveCameraImageUrl(imageUrl || undefined);
    };

    if (gqlCamera) {
      fetchCameraImage(gqlCamera.id);
    }
  }, [fetchCameraImageUrl, gqlCamera]);

  const cameraScenarios = useMemo(
    () => getScenariosByCamera(gqlCamera),
    [getScenariosByCamera, gqlCamera],
  );

  const addCamera = useCallback(
    async (param: CameraAddPayload) => {
      const addNewCamera = await addCameras(siteId, param);
      if (addNewCamera.status === 200) {
        toast.success(addNewCamera.data.message);
        refetchCameras();
        return true;
      }
      toast.error(addNewCamera.data.message);
      return false;
    },
    [refetchCameras, siteId],
  );

  const removeCamera = useCallback(
    async (payload: CameraRemovePayload) => {
      const deleteCamera = await doRemoveCamera(siteId, payload);

      if (deleteCamera.status === 200) {
        toast.success(deleteCamera.data.message);
        refetchCameras();
        navigate('/cameras');
        return true;
      }
      toast.error(deleteCamera.data.message);
      return false;
    },
    [refetchCameras, navigate, siteId],
  );

  const waitUntilCameraUpdates = useCallback(
    async (
      cameraId: number,
      cameraUpdates: Partial<CameraUpdatePayload>,
      retryCount: number,
      waitBeforeRetry = 1000,
    ): Promise<boolean> => {
      if (retryCount > 10) {
        toast.error(i18n.t('toast.error.camera_update_failed'));
        return false;
      }

      if (waitBeforeRetry > 0) {
        await new Promise((resolve) => {
          setTimeout(resolve, waitBeforeRetry);
        });
      }

      const result = await refetchCameras();
      const updatedCamera = result.data.cameras.find(
        (camera) => camera.id === cameraId,
      );

      if (!updatedCamera) {
        toast.error(i18n.t('toast.error.camera_update_failed'));
        return false;
      }

      const scenarioUpdates = cameraUpdates.scenarios;
      if (scenarioUpdates) {
        const updatedScenarios = updatedCamera.cameras_scenarios.map(
          (item) => item.customer_scenario_label.name,
        );
        if (
          updatedScenarios.length !== scenarioUpdates.length ||
          !updatedScenarios.every((scenarioName) =>
            scenarioUpdates.includes(scenarioName),
          )
        ) {
          return waitUntilCameraUpdates(
            cameraId,
            cameraUpdates,
            retryCount + 1,
          );
        }
      }

      const perimeterUpdates = cameraUpdates.perimeters;
      if (perimeterUpdates) {
        const updatedPerimeters = JSON.parse(
          perimeterUpdates,
        ) as ScenarioPerimeter[];

        const success = updatedPerimeters.every((perimeter) => {
          const updatedPerimeter = updatedCamera.cameras_scenarios.find(
            (item) => item.perimeter?.scenario === perimeter.scenario,
          )?.perimeter;
          if (
            !updatedPerimeter ||
            JSON.stringify(perimeter.position) !==
              JSON.stringify(updatedPerimeter.position)
          ) {
            return false;
          }
          return true;
        });

        if (!success) {
          return waitUntilCameraUpdates(
            cameraId,
            cameraUpdates,
            retryCount + 1,
          );
        }
      }

      const scheduleUpdates = cameraUpdates.schedule;
      if (scheduleUpdates) {
        const updatedSchedule = JSON.parse(scheduleUpdates);
        if (
          JSON.stringify(updatedSchedule) !==
          JSON.stringify(updatedCamera.schedule)
        ) {
          return waitUntilCameraUpdates(
            cameraId,
            cameraUpdates,
            retryCount + 1,
          );
        }
      }

      toast.success(i18n.t('toast.success.camera_update_finished'));

      return true;
    },
    [refetchCameras],
  );

  const updateCamera = useCallback(
    async (cameraUpdates: Partial<CameraUpdatePayload>) => {
      if (!gqlCamera) {
        return false;
      }

      const updatedCamera = {
        ...transformCameraToUpdatePayload(gqlCamera),
        ...cameraUpdates,
      };

      const result = await doUpdateCamera(siteId, updatedCamera);
      switch (result.status) {
        case 200:
          toast.success(i18n.t('toast.success.camera_update_started'));
          return waitUntilCameraUpdates(gqlCamera.id, cameraUpdates, 0);

        case 504:
          toast.error(i18n.t('toast.error.cannot_update'));
          break;

        default:
          toast.error(result.data.message);
          break;
      }

      return false;
    },
    [gqlCamera, siteId, waitUntilCameraUpdates],
  );

  const resetCamera = useCallback(
    async (payload: CameraInitPayload) => {
      const updateCameraImage = await doInitCamera(siteId, payload);

      if (updateCameraImage.status === 200) {
        toast.success(i18n.t('toast.success.reset_camera'));
        return true;
      }

      toast.error(updateCameraImage.data.message);
      return false;
    },
    [siteId],
  );

  const exportCameraImage = useCallback(() => {
    if (heatmapExportData && gqlCamera) {
      const { imageElement, scenario, timePeriod } = heatmapExportData || {};
      const dateRange = `${moment.unix(timePeriod.from).format('DD MMM, YYYY, HH:mm')} ~ ${moment.unix(timePeriod.until).format('DD MMM, YYYY, HH:mm')}`;

      if (imageElement) {
        const stageWidth = imageElement.width;
        const stageHeight = imageElement.height;
        const exportLayer = new Konva.Layer();

        const padding = 10;
        const textHeight = 20;
        const containerHeight = textHeight + 4 * padding;
        const newCanvasHeight = stageHeight + containerHeight;

        const image = new Konva.Image({
          image: imageElement,
          width: stageWidth,
          height: stageHeight,
          fill: 'black',
        });
        const background = new Konva.Rect({
          x: 0,
          y: newCanvasHeight - 2 * textHeight - 2 * padding,
          width: stageWidth,
          height: containerHeight,
          fill: 'white',
        });

        const cameraText = new Konva.Text({
          text: `${i18n.t('heatmap_image.camera_name')}: ${gqlCamera.name},`,
          x: padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const scenarioText = new Konva.Text({
          text: `${i18n.t('heatmap_image.scenario')}: ${scenario ? getScenarioTitle(scenario) : ''}`,
          x: cameraText.width() + 8 + padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const dateText = new Konva.Text({
          text: `${i18n.t('heatmap_image.date_range')}: ${dateRange}`,
          x: padding,
          y: newCanvasHeight - 1 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
          align: 'right',
        });

        exportLayer.add(image);
        exportLayer.add(background);
        exportLayer.add(cameraText);
        exportLayer.add(scenarioText);
        exportLayer.add(dateText);

        if (dangerZoneExportLayer) {
          dangerZoneExportLayer.children?.forEach((child) => {
            exportLayer.add(child.clone());
          });
        }

        exportLayer.draw();
        const uri = exportLayer.toDataURL({
          mimeType: 'image/png',
          quality: 1.0,
        });
        const link = document.createElement('a');
        link.download = 'camera-image';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        image.destroy();
        background.destroy();
        cameraText.destroy();
        scenarioText.destroy();
        dateText.destroy();
      } else {
        console.warn('Konva stage not yet available');
      }
    }
  }, [gqlCamera, heatmapExportData, dangerZoneExportLayer]);

  const context = useMemo(
    () =>
      ({
        gqlCamera,
        cameraList,
        gqlCameras,
        isCameraListLoading,
        cameraImageUrl: activeCameraImageUrl,
        cameraScenarios,
        setHeatmapExportData,
        setActiveCameraId,
        fetchCameraImageUrl,
        addCamera,
        removeCamera,
        updateCamera,
        resetCamera,
        exportCameraImage,
        setDangerZoneExportLayer,
      }) satisfies CameraContextType,
    [
      gqlCamera,
      cameraList,
      gqlCameras,
      isCameraListLoading,
      activeCameraImageUrl,
      cameraScenarios,
      addCamera,
      removeCamera,
      updateCamera,
      resetCamera,
      setActiveCameraId,
      exportCameraImage,
      setDangerZoneExportLayer,
      fetchCameraImageUrl,
    ],
  );

  return (
    <CameraContext.Provider value={context}>{children}</CameraContext.Provider>
  );
}
