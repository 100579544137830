export const LOCAL_STORAGE_KEYS = {
  SITE_ID: 'site_id',
  TRADUORA_TOKEN: 'traduora_token',
};

export const DEPRECATED_LOCAL_STORAGE_KEYS = {
  USER_TOKEN: 'userToken',
  SITE_ID: 'siteId',
  FEATURE_FLAG_OVERRIDES: 'featureFlagOverrides',
  // CAMERA_PER_ROW: 'cameraPerRow',
};
