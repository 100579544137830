/* eslint-disable import/no-extraneous-dependencies */
import { saveAs } from 'file-saver';
import { getAuth } from 'firebase/auth';
import i18n from 'i18next';
import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { BWButton } from './BWButton';
import { BUDDYWISE_API_URL } from '../../apis/api-request';
import { handleUnexpectedApiError } from '../../apis/utils';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { siteIdAtom } from '../../utils/atoms';

type Props = {
  imageUrl?: string;
  onClick?: () => void;
};

export function ImageDownloader({ imageUrl, onClick }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const siteId = useAtomValue(siteIdAtom);

  const handleDownload = useCallback(async () => {
    if (onClick) {
      onClick();
      return;
    }

    if (!imageUrl) {
      return;
    }

    setLoading(true);
    const userToken = await getAuth().currentUser?.getIdToken();
    fetch(
      `${BUDDYWISE_API_URL}/v2/images/download-image/?url=${btoa(imageUrl)}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${userToken}`,
          ...(siteId && { 'X-Site-Id': `${siteId}` }),
        },
      },
    )
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'image.jpg');
      })
      .catch((error) => {
        handleUnexpectedApiError(error);
        console.error('Error downloading image:', error);
      })
      .finally(() => setLoading(false));
  }, [imageUrl, siteId, onClick]);

  return (
    <BWButton
      disabled={loading}
      loading={loading}
      title={`${i18n.t('button.download')}`}
      icon={DownloadIcon}
      iconProps={{ stroke: '#1F2321' }}
      onClick={handleDownload}
      className="button-with-loader"
      variant="outline"
    />
  );
}
