import i18n from 'i18next';
import { useAuthenticatedUserContext } from '../contextapi/AuthenticatedUserProvider';
import { ClientUser } from '../typescript/observation/assignee';
import {
  OBSERVATION_PRIORITIES,
  TitleAndValue,
} from '../typescript/observation/observation';

export const useTableAction = () => {
  const { siteUsers } = useAuthenticatedUserContext();

  const listOfStatus = [
    {
      title: `${i18n.t('button.acknowledge')}`,
      value: 'acknowledged',
    },
    {
      title: `${i18n.t('button.resolve')}`,
      value: 'resolve',
    },
  ];
  const listOfPriority = OBSERVATION_PRIORITIES.map((item: TitleAndValue) => ({
    title: `${i18n.t(item.title)}`,
    value: item.value,
  }));
  const listOfUser = siteUsers.map((item: ClientUser) =>
    item.username
      ? {
          title: item.username,
          value: item.email,
        }
      : {
          title: item.email,
          value: item.email,
        },
  );

  const dropdownActionList = [
    {
      title: 'button.report_incident_false',
      value: 'report',
    },
    {
      title: 'button.change_assignee',
      value: 'responder',
      subMenu: listOfUser,
    },
    {
      title: 'button.change_priority',
      value: 'priority',
      subMenu: listOfPriority,
    },
    {
      title: 'button.change_status',
      value: 'status',
      subMenu: listOfStatus,
    },
    {
      title: 'button.bookmark',
      value: 'bookmark',
    },
    {
      title: 'button.view_details',
      value: 'view_details',
    },
  ];

  const dropdownFilteredList = dropdownActionList
    .filter((item) => item.value !== 'view_details' && item.value !== 'report')
    .map((item) => ({
      ...item,
      title: i18n.t(`${item.title}`),
    }));
  return {
    listOfUser,
    dropdownActionList,
    dropdownFilteredList,
  };
};
