/* eslint-disable react/display-name */
import Konva from 'konva';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import {
  Group,
  Image as KonvaImage,
  Label,
  Layer,
  Line,
  Rect,
  Stage,
  Tag,
  Text,
} from 'react-konva';
import { useFeatureFlags } from '../../contextapi/FeatureFlagsProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { getScenarioTitle } from '../../lib/features/scenario';
import {
  ObservationProp,
  updatedAnnotationPointer,
  ZoneSizeProp,
} from '../../typescript/observation/observation';
import { isDefined, isNotNull } from '../../utils/typeUtils';
import { DangerZoneOverlay } from '../cameras/settings/danger-zone/DangerZoneOverlay';

type Props = {
  observation: ObservationProp;
  className?: string;
  imageUrl?: string;
  containerWidth?: string | number;
  containerHeight?: string | number;
  isImageLoading?: boolean;
  hideLabel?: boolean;
  hideDetections?: boolean;
  isInteractive?: boolean;
  showDangerZone?: boolean;
  crossOrigin?: 'anonymous' | 'use-credentials' | '';
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  onError?: React.ReactEventHandler<HTMLImageElement>;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

export type AnnotationRef = {
  downloadImage(): void;
  stageRef: React.RefObject<Konva.Stage>;
  imageRef: React.RefObject<HTMLImageElement>;
};

export const ImageWithAnnotation = forwardRef<AnnotationRef, Props>(
  (
    {
      observation,
      imageUrl,
      containerWidth,
      containerHeight,
      className,
      isImageLoading = false,
      hideLabel = false,
      hideDetections = false,
      isInteractive = false,
      showDangerZone = false,
      crossOrigin,
      onLoad,
      onError,
      onClick,
    },
    ref,
  ) => {
    const { isAnnotatedObservation } = useFeatureFlags();
    const { getScenarioById } = useScenarioContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const stageRef = useRef<Konva.Stage>(null);

    const [zoneSize, setZoneSize] = useState<ZoneSizeProp>();
    const [konvaImage, setKonvaImage] = useState<HTMLImageElement | null>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(
      null,
    );
    const [highlightedIndexClicked, setHighlightedIndexClicked] =
      useState<boolean>(false);

    const { detections, perimeter } = observation;
    const hideAnnotations = isAnnotatedObservation(observation);

    const detectionFirstItem = useMemo(
      () =>
        zoneSize
          ? detections.map((item) => updatedAnnotationPointer(item, zoneSize))
          : [],
      [detections, zoneSize],
    );

    const labelConfigList = useMemo(
      () =>
        detectionFirstItem
          .map((item) => {
            const box = {
              x: Math.min(...item.annotations.filter((_, i) => i % 2 === 0)),
              y: Math.min(...item.annotations.filter((_, i) => i % 2 !== 0)),
              width:
                Math.max(...item.annotations.filter((_, i) => i % 2 === 0)) -
                Math.min(...item.annotations.filter((_, i) => i % 2 === 0)),
              height:
                Math.max(...item.annotations.filter((_, i) => i % 2 !== 0)) -
                Math.min(...item.annotations.filter((_, i) => i % 2 !== 0)),
            };
            const scenario = getScenarioById(item.scenario);
            return scenario
              ? {
                  x: box.x + box.width / 2,
                  y: box.y,
                  scenario,
                }
              : null;
          })
          .filter(isNotNull),
      [detectionFirstItem, getScenarioById],
    );

    const updateZoneSize = useCallback(() => {
      if (konvaImage && !isImageLoading) {
        setZoneSize({
          width: konvaImage.clientWidth,
          height: konvaImage.clientHeight,
        });
      }
    }, [konvaImage, isImageLoading]);

    useEffect(() => {
      setKonvaImage(null);
      setHighlightedIndex(null);
      setHighlightedIndexClicked(false);
    }, [imageUrl, isInteractive]);

    useEffect(() => {
      updateZoneSize();
      window.addEventListener('resize', updateZoneSize);
      return () => window.removeEventListener('resize', updateZoneSize);
    }, [imageUrl, updateZoneSize]);

    const handleExport = () => {
      if (stageRef.current) {
        const uri = stageRef.current.toDataURL({
          mimeType: 'image/png',
          quality: 1.0,
        });
        const link = document.createElement('a');
        link.download = 'observation';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn('Konva stage not yet available');
      }
    };

    useImperativeHandle(ref, () => ({
      downloadImage() {
        return handleExport();
      },
      imageRef,
      stageRef,
    }));

    const handleImageClick = useCallback(() => {
      if (isInteractive) {
        setHighlightedIndexClicked(false);
        setHighlightedIndex(null);
      }
    }, [isInteractive]);

    const handleDetectionClick = (index: number) =>
      isInteractive
        ? () => {
            setHighlightedIndexClicked(true);
            setHighlightedIndex(index);
          }
        : undefined;

    const handleDetectionMouseEnter = (index: number) =>
      isInteractive
        ? () => {
            if (!highlightedIndexClicked) {
              setHighlightedIndex(index);
            }
          }
        : undefined;

    const handleDetectionMouseLeave = isInteractive
      ? () => {
          if (!highlightedIndexClicked) {
            setHighlightedIndex(null);
          }
        }
      : undefined;

    const getPointerDirection = (labelX: number, labelY: number) => {
      if (zoneSize) {
        const margin = 30;
        if (labelY < margin && labelX < zoneSize.width - margin) return 'top';
        if (labelY > margin + 70 && labelX > zoneSize.width - margin + 70)
          return 'right';
        if (labelY > margin + 70 && labelX < margin + 70) return 'left';
      }
      return 'down';
    };

    const handleImageLoad = useCallback(
      (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setKonvaImage(imageRef.current);

        if (onLoad) {
          onLoad(e);
        }
      },
      [onLoad],
    );

    const showKonvaImage =
      !hideAnnotations &&
      !isImageLoading &&
      isNotNull(konvaImage) &&
      isDefined(zoneSize);

    const showHighlights =
      isInteractive && (isNotNull(highlightedIndex) || highlightedIndexClicked);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
      <div
        ref={containerRef}
        className={`canvas-container ph-no-capture ${className}`}
        role="button"
        onClick={onClick}
        style={{
          position: 'relative',
          width: containerWidth,
          height: containerHeight,
        }}
      >
        <BootstrapImage
          src={imageUrl}
          width={containerWidth}
          height={containerHeight}
          onLoad={handleImageLoad}
          onError={onError}
          ref={imageRef}
          id="image"
          style={{
            visibility: 'visible',
          }}
          crossOrigin={crossOrigin}
        />

        {showKonvaImage && (
          <Stage
            className="image-annotoation add-cursor"
            width={zoneSize.width}
            height={zoneSize.height}
            ref={stageRef}
          >
            <Layer>
              {showHighlights && (
                <>
                  <Rect
                    width={zoneSize.width}
                    height={zoneSize.height}
                    fill="black"
                  />
                  <Group
                    clipFunc={(ctx) => {
                      ctx.beginPath();
                      if (
                        highlightedIndexClicked &&
                        highlightedIndex !== null
                      ) {
                        const { annotations } =
                          detectionFirstItem[highlightedIndex];

                        ctx.moveTo(annotations[0], annotations[1]);
                        for (let i = 2; i < annotations.length; i += 2) {
                          ctx.lineTo(annotations[i], annotations[i + 1]);
                        }
                        ctx.closePath();
                      } else {
                        detectionFirstItem.forEach((item) => {
                          ctx.moveTo(item.annotations[0], item.annotations[1]);
                          for (let i = 2; i < item.annotations.length; i += 2) {
                            ctx.lineTo(
                              item.annotations[i],
                              item.annotations[i + 1],
                            );
                          }
                          ctx.closePath();
                        });
                      }
                      ctx.clip();
                    }}
                  >
                    <KonvaImage
                      image={konvaImage}
                      width={zoneSize.width}
                      height={zoneSize.height}
                      onClick={handleImageClick}
                    />
                  </Group>
                </>
              )}

              <Group>
                <KonvaImage
                  image={konvaImage}
                  width={zoneSize.width}
                  height={zoneSize.height}
                  opacity={highlightedIndex === null ? 1 : 0.3}
                  onClick={handleImageClick}
                />
                {showDangerZone && perimeter && (
                  <DangerZoneOverlay
                    perimeter={perimeter}
                    imageElement={imageRef.current}
                    renderInsideLayer
                  />
                )}
                {!hideDetections &&
                  detectionFirstItem.map((item, index) => (
                    <React.Fragment key={`${String(index)}`}>
                      {labelConfigList[index] && (
                        <>
                          <Line
                            points={item.annotations}
                            fill="transparent"
                            stroke={
                              labelConfigList[index].scenario.primaryColor
                            }
                            strokeWidth={2}
                            closed
                            onClick={handleDetectionClick(index)}
                            onMouseEnter={handleDetectionMouseEnter(index)}
                            onMouseLeave={handleDetectionMouseLeave}
                            ref={(node) => {
                              if (node && highlightedIndex === index) {
                                node.moveToTop();
                              }
                            }}
                          />
                          {!hideLabel && (
                            <Label
                              x={labelConfigList[index].x}
                              y={labelConfigList[index].y}
                              opacity={
                                highlightedIndex === index
                                  ? 1
                                  : highlightedIndex !== null
                                    ? 0
                                    : 0.8
                              }
                              onClick={handleDetectionClick(index)}
                              onMouseEnter={handleDetectionMouseEnter(index)}
                              onMouseLeave={handleDetectionMouseLeave}
                              ref={(node) => {
                                if (node && highlightedIndex === index) {
                                  node.moveToTop();
                                }
                              }}
                              visible
                            >
                              <Tag
                                fill={
                                  labelConfigList[index].scenario.primaryColor
                                }
                                pointerDirection={getPointerDirection(
                                  labelConfigList[index].x,
                                  labelConfigList[index].y,
                                )}
                                pointerWidth={10}
                                pointerHeight={10}
                              />
                              <Text
                                text={getScenarioTitle(
                                  labelConfigList[index].scenario,
                                )}
                                fontSize={18}
                                padding={5}
                                fill="white"
                              />
                            </Label>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </Group>
            </Layer>
          </Stage>
        )}
      </div>
    );
  },
);
