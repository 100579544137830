import React from 'react';
import { fontSize } from '../../scss/spacing';

type Props = {
  title: string;
  removeMargins?: boolean;
};

export const PageTitle = ({ title, removeMargins = false }: Props) => (
  <h1
    className={removeMargins ? 'm-0' : 'mb-32'}
    style={{
      fontSize: fontSize(7),
      fontWeight: 600,
      lineHeight: '38px',
    }}
  >
    {title}
  </h1>
);
