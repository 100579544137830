enum SPACING_REM {
  SPACING_1 = 0.25, // 4px
  SPACING_2 = 0.5, // 8px
  SPACING_3 = 0.75, // 12px
  SPACING_4 = 1, // 16px
  SPACING_5 = 1.25, // 20px
  SPACING_6 = 1.5, // 24px
  SPACING_7 = 2, // 32px
  SPACING_8 = 2.5, // 40px
  SPACING_9 = 3, // 48px
  SPACING_10 = 4, // 64px
  SPACING_11 = 5, // 80px
  SPACING_12 = 6, // 96px
  SPACING_13 = 8, // 128px
  SPACING_14 = 10, // 160px
  SPACING_15 = 12, // 192px
  SPACING_16 = 14, // 224px
  SPACING_17 = 16, // 256px
}

export type SpacingValue =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17;

export function spacing(value: SpacingValue): string {
  const key = `SPACING_${value}` as const;
  return `${SPACING_REM[key]}rem`;
}

enum FONT_SIZE_REM {
  FONT_SIZE_1 = '0.75rem', // 12px
  FONT_SIZE_2 = '0.875rem', // 14px
  FONT_SIZE_3 = '1rem', // 16px
  FONT_SIZE_4 = '1.125rem', // 18px
  FONT_SIZE_5 = '1.25rem', // 20px
  FONT_SIZE_6 = '1.5rem', // 24px
  FONT_SIZE_7 = '1.875rem', // 30px
  FONT_SIZE_8 = '2.25rem', // 36px
  FONT_SIZE_9 = '3rem', // 48px
  FONT_SIZE_10 = '3.75rem', // 60px
  FONT_SIZE_11 = '4.5rem', // 72px
}

type FontSizeValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export function fontSize(value: FontSizeValue): string {
  const key = `FONT_SIZE_${value}` as const;
  return FONT_SIZE_REM[key];
}
