/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ShortcutLine } from './ShortcutLine';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/shortcut-overlay/back.svg';
import { ReactComponent as ForwardIcon } from '../../assets/icons/shortcut-overlay/forward.svg';
import { spacing } from '../../scss/spacing';

type Props = {
  position?: 'top' | 'bottom' | 'left' | 'right';
};

export function ShortCutOverlay({ position = 'top' }: Props) {
  return (
    <OverlayTrigger
      placement={position}
      overlay={
        <Tooltip
          css={css`
            .tooltip-inner {
              max-width: fit-content;
              padding: ${spacing(4)};
            }
          `}
          id="button-tooltip-2"
        >
          <h3 style={{ marginBottom: `${spacing(4)}`, textAlign: 'left' }}>
            {i18n.t('observation.overlay.shortcut.keyboard_shortcuts')}
          </h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: `${spacing(4)}`,
            }}
          >
            <ShortcutLine
              title={`${i18n.t('observation.overlay.shortcut.navigate')}`}
              shortcutIcons={[<BackIcon key={0} />, <ForwardIcon key={1} />]}
            />

            <ShortcutLine
              title={`${i18n.t('observation.overlay.shortcut.report_false_positive')}`}
              shortcutIcons={['SHIFT', 'F']}
            />

            <ShortcutLine
              title={`${i18n.t('observation.overlay.shortcut.acknowledge_observation')}`}
              shortcutIcons={['SHIFT', 'A']}
            />

            <ShortcutLine
              title={`${i18n.t('observation.overlay.shortcut.resolve_observation')}`}
              shortcutIcons={['SHIFT', 'R']}
            />

            <ShortcutLine
              title={`${i18n.t('observation.overlay.shortcut.bookmark_observation')}`}
              shortcutIcons={['SHIFT', 'B']}
            />
          </div>
        </Tooltip>
      }
    >
      <InfoIcon className="icon" />
    </OverlayTrigger>
  );
}
