export const PRIMARY_COLOR = '#37d06f';
export const PRIMARY_COLOR_DARK = '#29ad59';
export const PRIMARY_COLOR_LIGHT = '#adebc4';
export const PRIMARY_COLOR_LIGHT_1 = '#d6f5e1';

export const SECONDARY_COLOR = '#1f2321';
export const SECONDARY_COLOR_RGB = '31, 35, 33';
export const SECONDARY_COLOR_COMPLIMENT = '#2f3a34';
export const SECONDARY_COLOR_COMPLIMENT_50 = '#2f3a3450';
export const SECONDARY_LIGHT_COLOR = '#96A097';

export const GRAY_SUBTLE = '#f7f8f7';
export const GRAY_LIGHT = '#e6eae6';
export const GRAY_MIDDLE = '#cfd3cf';
export const GRAY_WARM = '#96a097';
export const GRAY_WARM_DARKER_PLACEHOLDER = '#6b7272';
export const GRAY_WARM_DARKER_AA = '#6f7676';

export const PLACEHOLDER_COLOR = '#cfd3cf';

export const COLOR_STATUS_SUCCESS = '#12B76A' as const;
export const COLOR_STATUS_ERROR = '#F97066' as const;
export const COLOR_STATUS_NEUTRAL = '#b0b0b0' as const;

export const COLOR_PURPLE = '#b468fa';
export const COLOR_PURPLE_DARK = '#7c30c2';

export const COLOR_BLUE = '#7989ff';
export const COLOR_BLUE_DARK = '#4b5bd1';

export const COLOR_RED_600 = '#d92d20';

// Pastel contrating palette 1

// Download
// #e399f3

// Contrast

// Black Text

// 10.12:1
// #edbcf9

// Contrast

// Black Text

// 13.16:1
// #d8f6df

// Contrast

// Black Text

// 18.17:1
// #baecc7

// Contrast

// Black Text

// 15.91:1
// #99e3ad

// Contrast

// Black Text

// 13.95:1
// Pastel contrasting palette 2

// Download
// #ffa59d

// Contrast

// Black Text

// 11.12:1
// #ffc3be

// Contrast

// Black Text

// 13.80:1
// #baecc7

// Contrast

// Black Text

// 15.91:1
// #b9bbfe

// Contrast

// Black Text

// 11.60:1
// #9396fd

// Contrast

// Black Text

// 8.02:1
