/* eslint-disable react/display-name */
import Konva from 'konva';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Circle, Group, Line } from 'react-konva';
import { XYCoordinate } from '../../../../hooks/graphql/camera';
import { DangerZone } from '../../../../typescript/camera/camera';

export interface CanvasRef {
  isMouseOverStartingPoint(): boolean;
}

export const CIRCLE_DIAMETER = 10;

type CanvasSize = {
  width: number;
  height: number;
};

interface Props {
  dangerZone: DangerZone;
  canvasSize: CanvasSize;
  activeColor: string;
  isActive: boolean;

  mouseCoordinate: XYCoordinate | null;

  onCoordinatesUpdated?: (coordinates: XYCoordinate[]) => void;
  onMouseOver?: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
  onMouseOut?: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
}

export const KonvaDangerZone = forwardRef<CanvasRef, Props>(
  (
    {
      activeColor,
      dangerZone,
      canvasSize,
      isActive,
      mouseCoordinate,
      onCoordinatesUpdated,
      onMouseOver,
      onMouseOut,
    }: Props,
    ref,
  ) => {
    // state
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
      if (isActive) {
        const animation = setInterval(() => {
          setOpacity((prevOpacity) => {
            // Reset opacity to 1 if it reaches a threshold (e.g., 0.1)
            if (prevOpacity <= 0.1) {
              return 1;
            }
            return prevOpacity - 0.1;
          });
        }, 100);

        // Cleanup the animation interval
        return () => clearInterval(animation);
      }
      return () => false;
    }, [isActive]);

    useImperativeHandle(ref, () => ({
      isMouseOverStartingPoint() {
        return false;
      },
    }));

    const handleCircleMouseOver = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      const index = parseInt(event.currentTarget.name(), 10);
      if (index !== 0) {
        return;
      }

      if (dangerZone.completed || dangerZone.coordinates.length < 3) {
        event.target.scale({ x: 1, y: 1 });
      } else {
        event.target.scale({ x: 2, y: 2 });
      }
    };

    const handleCircleMouseOut = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      event.target.scale({ x: 1, y: 1 });
    };

    const linePoints = useMemo(
      () =>
        dangerZone.coordinates
          .concat(
            dangerZone.completed || !mouseCoordinate ? [] : mouseCoordinate,
          )
          .flat()
          .map(
            (value, index) =>
              value * (index % 2 ? canvasSize.height : canvasSize.width),
          ),
      [canvasSize, dangerZone, mouseCoordinate],
    );

    const handleGroupDragMove = useCallback(
      (event: Konva.KonvaEventObject<DragEvent>) => {
        const stage = event.currentTarget.getStage();
        if (!stage) {
          return;
        }

        const box = event.currentTarget.getClientRect();
        const absPos = event.currentTarget.getAbsolutePosition();
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;

        const newAbsPos = { ...absPos };
        if (box.x < 0) {
          newAbsPos.x = -offsetX;
        }
        if (box.y < 0) {
          newAbsPos.y = -offsetY;
        }
        if (box.x + box.width > stage.width()) {
          newAbsPos.x = stage.width() - box.width - offsetX;
        }
        if (box.y + box.height > stage.height()) {
          newAbsPos.y = stage.height() - box.height - offsetY;
        }

        event.currentTarget.setAbsolutePosition(newAbsPos);
      },
      [],
    );

    const handleCircleDragMove = useCallback(
      (event: Konva.KonvaEventObject<DragEvent>) => {
        const { x, y } = event.currentTarget.attrs;
        const index = parseInt(event.currentTarget.name(), 10);

        const box = event.currentTarget.getClientRect();
        const absPos = event.currentTarget.getAbsolutePosition();
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;

        const newAbsPos = { ...absPos };
        if (box.x < 0) {
          newAbsPos.x = -offsetX;
        }
        if (box.y < 0) {
          newAbsPos.y = -offsetY;
        }
        if (box.x + box.width > canvasSize.width) {
          newAbsPos.x = canvasSize.width - box.width - offsetX;
        }
        if (box.y + box.height > canvasSize.height) {
          newAbsPos.y = canvasSize.height - box.height - offsetY;
        }

        event.currentTarget.setAbsolutePosition(newAbsPos);

        if (x > 0 && x < canvasSize.width && y > 0 && y < canvasSize.height) {
          const line = event.currentTarget.getParent().findOne('.line');
          line.getAttrs().points[index * 2] = x;
          line.getAttrs().points[index * 2 + 1] = y;
        }
      },
      [canvasSize.height, canvasSize.width],
    );

    const handleGroupDragEnd = useCallback(
      (e: Konva.KonvaEventObject<DragEvent>) => {
        const group = e.currentTarget as unknown as Konva.Group;
        const absPos = group.getAbsolutePosition();
        const line = group
          .getChildren()
          .find((child) => child.attrs.name === 'line');

        const points = line?.attrs.points as number[];
        if (!points) {
          return;
        }

        const updatedPoints = points.map((point, index) => {
          if (index % 2) {
            return (point + absPos.y) / canvasSize.height;
          }
          return (point + absPos.x) / canvasSize.width;
        });

        if (updatedPoints.some((point) => point < 0 || point > 1)) {
          console.warn('OUT OF BOUNDS');
          return;
        }

        const coordinates = updatedPoints.reduce<XYCoordinate[]>(
          (acc, point, index) => {
            if (index % 2 === 0) {
              acc.push([point, updatedPoints[index + 1]]);
            }
            return acc;
          },
          [],
        );

        group.setAbsolutePosition({ x: 0, y: 0 });
        onCoordinatesUpdated?.(coordinates);
      },
      [canvasSize, onCoordinatesUpdated],
    );

    return (
      <Group
        draggable={isActive}
        name={dangerZone.index?.toString()}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onDragMove={handleGroupDragMove}
        onDragEnd={handleGroupDragEnd}
      >
        <Line
          key={dangerZone.index}
          points={linePoints}
          fill={`${isActive ? '#29AD5950' : `${activeColor}50`}`}
          stroke={`${isActive ? '#29AD59' : `${activeColor}`}`}
          strokeWidth={1}
          closed={dangerZone.completed}
          name="line"
        />
        {dangerZone.coordinates.map((coordinate, index) => {
          const x = coordinate[0] * canvasSize.width;
          const y = coordinate[1] * canvasSize.height;

          return (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              x={x}
              y={y}
              name={index.toString()}
              width={CIRCLE_DIAMETER}
              height={CIRCLE_DIAMETER}
              fill={`${isActive ? '#29AD5980' : `${activeColor}`}`}
              stroke={`${isActive ? '#29AD59' : `${activeColor}`}`}
              strokeWidth={1}
              draggable={isActive}
              onDragMove={handleCircleDragMove}
              onMouseOver={handleCircleMouseOver}
              onMouseOut={handleCircleMouseOut}
              opacity={isActive ? opacity : 1}
            />
          );
        })}
      </Group>
    );
  },
);
