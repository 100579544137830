/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth';
import i18next from 'i18next';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useAuthenticatedUserContext } from '../../contextapi/AuthenticatedUserProvider';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { fontSize } from '../../scss/spacing';
import { BWButton } from '../elements/BWButton';

interface Props {
  show: boolean;
  phoneNumber: string;
  onClose: () => void;
}

export function ManageMFAModal({ show, phoneNumber, onClose }: Props) {
  const { logout } = useAuthContext();
  const { firebaseUser } = useAuthenticatedUserContext();

  const isEnrolled = multiFactor(firebaseUser).enrolledFactors.length > 0;

  const [step, setStep] = useState<
    'recaptcha' | 'logout' | 'otp_verification' | 'unenroll'
  >(isEnrolled ? 'unenroll' : 'recaptcha');
  const [recaptchaWidgetId, setRecaptchaWidgetId] = useState<number>();
  const [verificationId, setVerificationId] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');

  const closeModal = useCallback(() => {
    setStep(isEnrolled ? 'otp_verification' : 'recaptcha');
    setVerificationId(undefined);
    setRecaptchaWidgetId(undefined);
    setVerificationCode('');
    onClose();
  }, [isEnrolled, onClose]);

  const handleFirebaseError = useCallback((error: unknown) => {
    if (!(error instanceof FirebaseError)) {
      toast.error(i18next.t('toast.error.unknown_error'));
      return;
    }

    switch (error.code) {
      case 'auth/requires-recent-login':
        setStep('logout');
        // toast.warning(i18next.t('toast.warning.recent_login_required'));
        // logout();
        break;
      case 'auth/second-factor-already-in-use':
        toast.error(i18next.t('toast.error.mfa_already_enrolled'));
        break;
      default:
        console.error('Firebase error:', error);
        break;
    }
  }, []);

  const onSubmitEnrollmentForm = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (verificationId === undefined) {
        throw new Error('Verification ID is not set');
      }

      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      try {
        await multiFactor(firebaseUser).enroll(multiFactorAssertion);
        toast.success(i18next.t('toast.success.mfa_enrolled'));
        logout();
      } catch (error) {
        console.error('Error while enrolling MFA:', error);
        handleFirebaseError(error);
      }

      closeModal();
    },
    [
      closeModal,
      firebaseUser,
      handleFirebaseError,
      logout,
      verificationCode,
      verificationId,
    ],
  );

  const onSubmitUnenrollmentForm = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      try {
        const multiFactorInfo = multiFactor(firebaseUser).enrolledFactors[0];
        await multiFactor(firebaseUser).unenroll(multiFactorInfo);
        toast.success(i18next.t('toast.success.mfa_unenrolled'));
        logout();
      } catch (error) {
        console.error('Error while unenrolling MFA:', error);
        handleFirebaseError(error);
      }

      closeModal();
    },
    [closeModal, firebaseUser, handleFirebaseError, logout],
  );

  useEffect(() => {
    if (!show || recaptchaWidgetId !== undefined || step !== 'recaptcha') {
      return;
    }

    const auth = getAuth();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'normal',
        callback: async () => {
          const multiFactorSession =
            await multiFactor(firebaseUser).getSession();

          const phoneInfoOptions = {
            phoneNumber,
            session: multiFactorSession,
          };

          const auth = getAuth();
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          try {
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              recaptchaVerifier,
            );
            setVerificationId(verificationId);
            setStep('otp_verification');
          } catch (error) {
            console.error('Error while verifying phone number:', error);
            handleFirebaseError(error);
            recaptchaVerifier?.clear();
          }
        },
      },
    );

    recaptchaVerifier
      .render()
      .then((widgetId) => {
        setRecaptchaWidgetId(widgetId);
      })
      .catch((error) => {
        console.error('Error while rendering recaptcha:', error);
        handleFirebaseError(error);
      });
  }, [
    firebaseUser,
    handleFirebaseError,
    phoneNumber,
    recaptchaWidgetId,
    show,
    step,
  ]);

  return (
    <Modal
      centered
      show={show}
      contentClassName="no-min-height"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {isEnrolled
            ? i18next.t('modal.mfa.title.enrolled')
            : i18next.t('modal.mfa.title.enroll')}
        </Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={closeModal}
        />
      </Modal.Header>
      <Modal.Body>
        {step === 'recaptcha' ? (
          <>
            <h2
              className="mb-20"
              style={{
                fontSize: fontSize(4),
                fontWeight: 600,
              }}
            >
              {i18next.t('modal.mfa.recaptcha_title')}
            </h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div id="recaptcha-container" className="mb-12" />
            </div>
          </>
        ) : step === 'logout' ? (
          <>
            <h2
              className="mb-20"
              style={{
                fontSize: fontSize(4),
                fontWeight: 600,
              }}
            >
              {i18next.t('modal.mfa.logout_title')}
            </h2>
            <p className="mb-12">{i18next.t('modal.mfa.logout_description')}</p>
            <div className="d-flex justify-content-center">
              <BWButton
                title={i18next.t('modal.mfa.submit.logout')}
                className="button-with-loader"
                variant="primary"
                onClick={async () => {
                  await logout();
                  closeModal();
                }}
              />
            </div>
          </>
        ) : (
          <>
            {step === 'otp_verification' && (
              <>
                <h2
                  className="mb-20"
                  style={{
                    fontSize: fontSize(4),
                    fontWeight: 600,
                  }}
                >
                  {i18next.t('modal.mfa.otp_verification_title')}
                </h2>
                <p className="mb-12">
                  {i18next.t('modal.mfa.otp_verification_description')}
                </p>
              </>
            )}
            <Form
              onSubmit={
                isEnrolled ? onSubmitUnenrollmentForm : onSubmitEnrollmentForm
              }
              style={{ maxWidth: 320 }}
              className="d-flex flex-column mx-auto"
            >
              {!isEnrolled && (
                <div className="ph-no-capture mb-12">
                  <OTPInput
                    containerStyle={{ justifyContent: 'center' }}
                    inputStyle={{ width: 30, height: 40, fontWeight: 'bold' }}
                    value={verificationCode}
                    onChange={setVerificationCode}
                    numInputs={6}
                    renderSeparator={<span> - </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              )}

              <BWButton
                title={
                  isEnrolled
                    ? i18next.t('modal.mfa.submit.unenroll')
                    : i18next.t('modal.mfa.submit.enroll')
                }
                className="button-with-loader"
                variant="primary"
                type="submit"
                disabled={!isEnrolled && verificationCode.length !== 6}
              />
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
