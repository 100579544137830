import React from 'react';
import { Route } from 'react-router-dom';
import { AuthenticatedProviders } from './AuthenticatedProviders';
import { SentryRoutes } from './SentryRoutes';
import { UnkownRouteHandler } from './UnknownRouteHandler';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useTranslationContext } from '../contextapi/TranslationProvider';
import { useScrollToTop } from '../hooks/window';
import { ProtectedLayout } from '../layouts/ProtectedLayout';
import { AlertDetailsPage } from '../pages/AlertDetailsPage';
import { AlertsPage } from '../pages/AlertsPage';
import { CameraDetailsPage } from '../pages/CameraDetailsPage';
import { CamerasPage } from '../pages/CamerasPage';
import { CreateAlertRulePage } from '../pages/CreateAlertRule';
import { DashboardPage } from '../pages/DashboardPage';
import { LoadingPage } from '../pages/LoadingPage';
import { LoginPage } from '../pages/LoginPage';
import { ObservationDetailsPage } from '../pages/ObservationDetailsPage';
import { ObservationListPage } from '../pages/ObservationListPage';
import { ProfilePage } from '../pages/ProfilePage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage';
import { ScenarioDetailsPage } from '../pages/ScenarioDetailsPage';
import { SettingsPage } from '../pages/SettingsPage';
import { UpdateAlertRulePage } from '../pages/UpdateAlertRulePage';
import { withPageContext } from '../utils/trackingContext';

export function Router() {
  const { isAuthInitialized, isLoggedIn } = useAuthContext();
  const { isTranslationReady } = useTranslationContext();
  useScrollToTop();

  if (!isTranslationReady || !isAuthInitialized) {
    return <LoadingPage />;
  }

  if (!isLoggedIn) {
    return (
      <SentryRoutes>
        <Route
          path="/login"
          element={withPageContext(<LoginPage />, 'login_page')}
        />
        <Route
          path="/forgot-password"
          element={withPageContext(
            <ResetPasswordPage />,
            'forgot_password_page',
          )}
        />
        <Route path="*" element={<UnkownRouteHandler to="/login" />} />
      </SentryRoutes>
    );
  }

  return (
    <AuthenticatedProviders>
      <SentryRoutes>
        <Route element={<ProtectedLayout />}>
          <Route
            path="/dashboard"
            element={withPageContext(<DashboardPage />, 'dashboard_page')}
          />

          <Route
            path="/observations"
            element={withPageContext(
              <ObservationListPage />,
              'observations_page',
            )}
          />
          <Route
            path="/group-observations/:groupId"
            element={withPageContext(
              <ScenarioDetailsPage />,
              'scenario_details_page',
            )}
          />

          <Route
            path="/observation/:slug"
            element={withPageContext(
              <ObservationDetailsPage />,
              'observation_details_page',
            )}
          />
          <Route
            path="/cameras"
            element={withPageContext(<CamerasPage />, 'cameras_page')}
          />

          <Route
            path="/camera/:cameraId"
            element={withPageContext(
              <CameraDetailsPage />,
              'camera_details_page',
            )}
          />

          <Route
            path="/alerts"
            element={withPageContext(<AlertsPage />, 'alerts_page')}
          />
          <Route
            path="/alerts/create"
            element={withPageContext(
              <CreateAlertRulePage />,
              'create_alerting_rule_page',
            )}
          />
          <Route
            path="/alerts/edit/:slug"
            element={withPageContext(
              <UpdateAlertRulePage />,
              'edit_alerting_rule_page',
            )}
          />
          <Route
            path="/alerts/:slug"
            element={withPageContext(
              <AlertDetailsPage />,
              'alert_details_page',
            )}
          />

          <Route
            path="/settings"
            element={withPageContext(<SettingsPage />, 'settings_page')}
          />
          <Route
            path="/profile/:slug"
            element={withPageContext(<ProfilePage />, 'user_profile_page')}
          />

          <Route path="*" element={<UnkownRouteHandler to="/dashboard" />} />
        </Route>
      </SentryRoutes>
    </AuthenticatedProviders>
  );
}
