import i18n from 'i18next';
import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { ObservationList } from '../components/observation/ObservationList';
import { useAuthenticatedUserContext } from '../contextapi/AuthenticatedUserProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTrackEvent } from '../hooks/track_event';

const tabs = ['all', 'assigned_to_me', 'bookmarked'] as const;
export type ObservationListTab = (typeof tabs)[number];

const tabSchema = z.enum(tabs);

export const ObservationListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticatedUserContext();
  const { theme } = useThemeContext();
  const trackEvent = useTrackEvent();

  const typeSearchParam = Number(searchParams.get('type'));
  const tabSearchParam = tabSchema.safeParse(searchParams.get('tab')).data;

  const activeTab =
    tabSearchParam ||
    (!Number.isNaN(typeSearchParam) && tabs.at(typeSearchParam)) ||
    'all';

  return (
    <>
      <Breadcrumb title={`${i18n.t('dashboard.title')}`} toUrl="/" />
      <h5 className="mb-32">{i18n.t('landing.title')}</h5>
      <Row className="landing">
        <Col lg={12} className="d-flex flex-column">
          <Tabs
            defaultActiveKey={activeTab}
            className={`mb-32 nav-tabs-${theme}`}
            onSelect={(selectedTab) => {
              const tab = tabSchema.safeParse(selectedTab).data;
              if (!tab) {
                return;
              }
              trackEvent('navigation_tab_click', {
                tab_name: tab,
              });
              setSearchParams((searchParams) => {
                searchParams.set('tab', tab);
                searchParams.delete('type');
                searchParams.set('pageNo', '1');
                searchParams.set('limit', '10');
                return searchParams;
              });
            }}
          >
            <Tab
              tabClassName="me-12"
              eventKey="all"
              title={i18n.t('landing.tab.all')}
            >
              <ObservationList
                variant="default"
                skipDataFetching={activeTab !== 'all'}
              />
            </Tab>
            <Tab
              tabClassName="d-flex flex-column"
              eventKey="assigned_to_me"
              title={i18n.t('landing.tab.assigne_me')}
            >
              <ObservationList
                variant="default"
                assignee={user.email}
                skipDataFetching={activeTab !== 'assigned_to_me'}
              />
            </Tab>
            <Tab
              tabClassName="d-flex flex-column"
              eventKey="bookmarked"
              title={i18n.t('landing.tab.bookmarked')}
            >
              <ObservationList
                variant="bookmark"
                skipDataFetching={activeTab !== 'bookmarked'}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
