import moment from 'moment';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DonutChart } from './charts/DonutChart';
import { MultiBarChart } from './charts/MultiBarChart';
import { EmptyChart } from './EmptyChart';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { useGetChartDataQuery } from '../../hooks/graphql/chart';
import { updatedDate } from '../../typescript/grouping/grouping-observation';

type Props = {
  cameraIds?: Array<number>;
};

export function ObservationCharts({ cameraIds }: Props) {
  const { timeRange } = useTimeRangeContext();

  const { loading, data } = useGetChartDataQuery({
    from: timeRange.value,
    until: timeRange.end,
    camera_ids: cameraIds,
  });

  const observationSets = useMemo(
    () =>
      (data?.chartData || []).map((group) => ({
        ...group,
        timeseries: updatedDate(
          group.timeseries.map((item) => ({
            count: item.count,
            date: moment(item.range).format('DD MMM'),
            range: item.range,
          })),
          timeRange.text,
          timeRange.value,
          timeRange.end,
          timeRange.isHourly,
        ),
      })),
    [timeRange, data?.chartData],
  );

  return !loading && observationSets?.length === 0 ? (
    <EmptyChart />
  ) : (
    <Row className="mb-32">
      <Col md={4}>
        <DonutChart
          dataSets={observationSets}
          cameraIds={cameraIds}
          isLoading={loading}
        />
      </Col>
      <Col md={8}>
        <MultiBarChart
          data={observationSets}
          cameraIds={cameraIds}
          isLoading={loading}
        />
      </Col>
    </Row>
  );
}
