import i18n from 'i18next';
import React from 'react';
import { Col, FormControl, Modal, Row } from 'react-bootstrap';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ReportFalseFormData } from '../../typescript/components/modal';
import { OBSERVATION_REPORT_REASONS } from '../../typescript/observation/observation';
import { CustomDropdown } from '../elements/CustomDropdown';

type Props = {
  formData: ReportFalseFormData;
  onFormUpdate: (metadata: ReportFalseFormData) => void;
};

export function ReportFalseModalBody({ formData, onFormUpdate }: Props) {
  const { theme } = useThemeContext();
  const { reason, comment } = formData;

  const selectedReason = reason
    ? OBSERVATION_REPORT_REASONS.find((item) => item.value === reason)
    : undefined;

  return (
    <Modal.Body>
      <Row>
        <Col md={12}>
          <p className="mb-1 weight-600 ">
            {i18n.t('modal.report.reason.title')}
          </p>
          <p className="sub-color">{i18n.t('modal.report.reason.body')}</p>

          <CustomDropdown
            title={
              !selectedReason
                ? i18n.t('button.select_reason')
                : i18n.t(selectedReason.title)
            }
            dropdownData={OBSERVATION_REPORT_REASONS.map((item) => ({
              title: i18n.t(item.title) || '',
              value: item.value,
            }))}
            onClick={(value) => {
              onFormUpdate({ reason: String(value.value), comment });
            }}
            trackingProperties={{
              action: 'report_reason',
            }}
          />

          <hr />

          <p className="mb-1 weight-600 ">
            {i18n.t('modal.report.comment.title')}
          </p>
          <p className="sub-color">{i18n.t('modal.report.comment.body')}</p>

          <FormControl
            placeholder={`${i18n.t('input.comment.placeholder')}`}
            as="textarea"
            rows={5}
            className={`form-control-${theme}`}
            value={comment || ''}
            onChange={(e) => {
              e.stopPropagation();
              onFormUpdate({ reason, comment: e.target.value });
            }}
          />
        </Col>
      </Row>
    </Modal.Body>
  );
}
