import i18n from 'i18next';
import React from 'react';
import { Col } from 'react-bootstrap';
import { ClientUser } from '../../../typescript/observation/assignee';
import { CustomDropdown } from '../../elements/CustomDropdown';

interface Props {
  responders: Array<ClientUser>;
  selectedResponder: string | undefined;
  onClick: (responder: string) => void;
}

export function ResponderFilter({
  onClick,
  responders,
  selectedResponder,
}: Props) {
  return (
    <Col md={12} className="">
      <hr />
      <p className="mb-1 weight-600 ">{i18n.t('filter.responder.title')}</p>
      <p className="sub-color">{i18n.t('filter.responder.body')}</p>

      <CustomDropdown
        title={
          !selectedResponder
            ? `${i18n.t('button.select_responder')}`
            : selectedResponder
        }
        hideContextData
        className="me-2"
        dropdownData={responders.map((item: ClientUser) =>
          item.username
            ? {
                title: item.username,
                value: item.email,
              }
            : {
                title: item.email,
                value: item.email,
              },
        )}
        onClick={(item) => {
          onClick(String(item.value));
          return true;
        }}
      />
    </Col>
  );
}
