/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  getScenarioDescription,
  getScenarioTitle,
  Scenario,
  ScenarioApiName,
} from '../../lib/features/scenario';
import { spacing } from '../../scss/spacing';
import { isDefined } from '../../utils/typeUtils';

export type ScenarioLabelProps = {
  scenario: Scenario;
  as?: 'li' | 'span';
  label?: string | null;
  showTooltip?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  isHighlighted?: boolean;
  fadeOpacity?: number;
  iconType?: 'add' | 'remove';
  cursor?: 'inherit' | 'default' | 'pointer';
  onClick?: (scenario: Scenario) => void;
  onIconClick?: (scenario: Scenario) => void;
  customCss?: SerializedStyles;
  scenarioColors?: Partial<Record<ScenarioApiName, string>>;
};

export function ScenarioLabel({
  scenario,
  as = 'span',
  label = getScenarioTitle(scenario),
  showTooltip = false,
  tooltipPlacement = 'bottom',
  isHighlighted,
  fadeOpacity = 0.5,
  iconType,
  cursor,
  onClick,
  onIconClick,
  customCss,
  scenarioColors,
}: ScenarioLabelProps) {
  const { name, apiName } = scenario;
  const { primaryColor, secondaryColor } = scenario;

  const isClickable = isDefined(onClick);

  const labelCss = [
    css`
      color: ${secondaryColor};
      background-color: ${scenarioColors?.[apiName as ScenarioApiName] ??
      primaryColor};
      display: inline-flex;
      align-items: center;
      margin: 0;
      height: 24px;
      padding: 0 ${spacing(2)};
      border-radius: ${spacing(1)};
      white-space: nowrap;
      width: fit-content;
      opacity: ${isDefined(isHighlighted) && !isHighlighted ? fadeOpacity : 1};
      cursor: ${cursor ??
      (showTooltip ? 'help' : isClickable ? 'pointer' : 'inherit')};
    `,
    customCss,
  ];

  const icon = iconType && (
    <span style={{ cursor: 'pointer' }}>
      {iconType === 'remove' ? (
        <svg
          className="ms-2"
          onClick={() => onIconClick?.(scenario)}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 1L1 7M1 1L7 7"
            stroke={secondaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          className="ms-2"
          onClick={() => onIconClick?.(scenario)}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.24264 0.757812V5.00045M5.24264 5.00045V9.24309M5.24264 5.00045H1M5.24264 5.00045H9.48528"
            stroke={secondaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  );

  const labelComponent =
    as === 'li' ? (
      <li
        className="me-2 mb-12 ph-no-capture"
        css={labelCss}
        onClick={() => onClick?.(scenario)}
      >
        {label}
        {icon}
      </li>
    ) : (
      <span
        className="ph-no-capture"
        css={labelCss}
        onClick={() => onClick?.(scenario)}
      >
        {label}
        {icon}
      </span>
    );

  return showTooltip ? (
    <OverlayTrigger
      key={name}
      placement={tooltipPlacement}
      overlay={
        <Tooltip id="button-tooltip-2">
          {getScenarioDescription(scenario)}
        </Tooltip>
      }
    >
      {labelComponent}
    </OverlayTrigger>
  ) : (
    labelComponent
  );
}
