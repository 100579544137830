import i18n from 'i18next';
import React from 'react';
import { TooltipProps } from 'recharts';
import { useScenarioContext } from '../../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../../contextapi/ThemeProvider';
import {
  getScenarioTitle,
  ScenarioName,
} from '../../../../lib/features/scenario';

type CustomTooltipProps = TooltipProps<number, ScenarioName> & {
  totalObservations: number;
};

export function BarTooltip({
  active,
  payload,
  totalObservations,
}: CustomTooltipProps) {
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();
  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius`}
      >
        <p className="m-0 ">
          {i18n.t('bartooltip.date')}
          <span className="ms-1 weight-600">{payload[0].payload.date}</span>
        </p>
        {payload.map((barValue) => {
          const scenario = getScenarioByName(barValue.name);
          if (!scenario) {
            return null;
          }

          const percentage = ((barValue.value || 0) / totalObservations) * 100;
          return (
            <div key={barValue.name} className="d-flex align-items-center">
              <div
                className="me-1"
                style={{
                  width: 10,
                  height: 10,
                  background: scenario.primaryColor,
                }}
              />
              <p className="m-0 ">{`${getScenarioTitle(scenario)} — ${percentage.toFixed(2)}% (${barValue.value})`}</p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}
