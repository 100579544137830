import { gql } from '@apollo/client';

export const OBSERVATION_FRAGMENT = gql`
  fragment ObservationFragment on observations {
    id
    imageUrl: image_url
    priority
    requestId: request_id
    responder
    status
    system_timestamp
    created
    perimeter
    detections {
      location
      id
      label
      observation {
        customer_scenario_label_id
      }
    }
    customer_scenario_label {
      id
      name
      scenario {
        name
      }
      label {
        name
      }
    }
    camera {
      id
      name
    }
    observation_users {
      id
      user_id
    }
  }
`;

export const GET_OBSERVATIONS = gql`
  ${OBSERVATION_FRAGMENT}

  query GET_OBSERVATIONS(
    $limit: Int
    $offset: Int
    $where: observations_bool_exp
    $orderBy: order_by
  ) {
    observations(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { system_timestamp: $orderBy }
    ) {
      ...ObservationFragment
    }

    observations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BOOKMARKED_OBSERVATIONS = gql`
  ${OBSERVATION_FRAGMENT}

  query GET_BOOKMARKED_OBSERVATIONS(
    $where: observations_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: order_by
  ) {
    observation_user(
      limit: $limit
      offset: $offset
      where: { observation: $where }
      order_by: { created_at: $orderBy }
    ) {
      observation {
        ...ObservationFragment
      }
    }

    observation_user_aggregate(where: { observation: $where }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OBSERVATION = gql`
  ${OBSERVATION_FRAGMENT}

  query GET_OBSERVATION($id: Int!) {
    observations_by_pk(id: $id) {
      ...ObservationFragment
      is_false_positive
      timeline
      comments
    }
  }
`;

// Observations Query Props
export type WhereQueryProps = {
  priority?: { _eq: string };
  status?: { _eq: string };
  customer_scenario_label?: { label: { name: { _in: Array<string> } } };
  customer_scenario_label_id?: { _in: Array<number> };
  responder?: { _eq: string };
  camera_id?: { _in?: Array<number>; _eq?: number };
  created?: { _gte: number; _lte: number };
  system_timestamp?: { _gte: number; _lte: number };
  is_false_positive?: { _eq: boolean };
};

export type ObservationsQueryProps = {
  limit: number;
  offset: number;
  where?: WhereQueryProps;
};
