import { ApolloProvider } from '@apollo/client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { PostHogPageView } from './components/pageview';
import { AuthProvider } from './contextapi/AuthProvider';
import { ThemedToastContainer } from './contextapi/ThemedToastContainer';
import { ThemeProvider } from './contextapi/ThemeProvider';
import { TranslationProvider } from './contextapi/TranslationProvider';
import { apolloClient } from './graphql/apolloClient';
import { Router } from './routes/Router';

export function App() {
  return (
    <TranslationProvider>
      <PostHogProvider client={posthog}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <ThemeProvider>
              <ThemedToastContainer />
              <PostHogPageView />
              <Router />
            </ThemeProvider>
          </AuthProvider>
        </ApolloProvider>
      </PostHogProvider>
    </TranslationProvider>
  );
}
