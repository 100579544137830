import { usePostHog } from 'posthog-js/react';
import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { useAuthenticatedUserContext } from './AuthenticatedUserProvider';

const PosthogContext = createContext<null>(null);

export const PosthogContextProvider = ({ children }: PropsWithChildren) => {
  const posthog = usePostHog();
  const { user, site: activeSite } = useAuthenticatedUserContext();

  useEffect(() => {
    posthog.identify(user.trackingId, {
      user_id: user.trackingId,
      customer_id: user.customer.trackingId,
      is_admin: user.isAdmin,
      phone_number_verified: user.phoneNoVerified,
      exclude_from_tracking: user.exclude_from_tracking || false,
    });
    posthog.group('customer', `customer:${user.customer.trackingId}`);
    posthog.group('site', `site:${activeSite.id}`);
  }, [user, activeSite, posthog]);

  return (
    <PosthogContext.Provider value={null}>{children}</PosthogContext.Provider>
  );
};
