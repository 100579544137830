import React, { Navigate, To, useLocation } from 'react-router-dom';

type Props = {
  to: To;
};

export const UnkownRouteHandler = ({ to }: Props) => {
  const prevRoute = useLocation();
  return <Navigate to={to} state={{ prevRoute }} replace />;
};
