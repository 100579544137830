/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useThemeContext } from '../../contextapi/ThemeProvider';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
}
export function DaytimeTooltip({ active, payload }: Props) {
  const { theme } = useThemeContext();

  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius`}
      >
        <p className="m-0">{`${payload[0].name}: ${payload[0].payload.count}`}</p>
      </div>
    );
  }
  return null;
}
