import Lottie from 'lottie-react';
import React from 'react';
import imageLoader from '../../assets/animations/image-loader.json';

interface Props {
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
}
export function ImageLoader({ className, style }: Props) {
  return (
    <Lottie className={className} style={style} animationData={imageLoader} />
  );
}
