import i18n from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { LoadingPage } from './LoadingPage';
import { ReactComponent as CameraIcon } from '../assets/dashboard/camera.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings-dark.svg';
import { CameraStatus } from '../components/camera/CameraStatus';
import { CameraHeatMap } from '../components/camera/variants/CameraHeatMap';
import { ObservationCharts } from '../components/charts-container/ObservationCharts';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { BWButton } from '../components/elements/BWButton';
import { FlexCol } from '../components/elements/FlexCol';
import { ImageDownloader } from '../components/elements/ImageDownloader';
import { PageTitle } from '../components/elements/PageTitle';
import { SectionTitle } from '../components/elements/SectionTitle';
import {
  CameraSettingsModal,
  cameraSettingsModals,
} from '../components/modal/CameraSettingsModal';
import { ObservationList } from '../components/observation/ObservationList';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { Scenario } from '../lib/features/scenario';
import { GRAY_WARM_DARKER_PLACEHOLDER } from '../scss/colors';
import { spacing } from '../scss/spacing';
import { isDefined } from '../utils/typeUtils';

export function CameraDetailsPage() {
  const { timeRange } = useTimeRangeContext();
  const { gqlCamera, setActiveCameraId, exportCameraImage } =
    useCameraContext();

  const { cameraId: cameraIdParam } = useParams();
  if (!cameraIdParam) {
    throw new Error('Camera ID is required');
  }
  const cameraId = Number(cameraIdParam);

  useEffect(() => {
    setActiveCameraId(cameraId);
  }, [cameraId, setActiveCameraId]);

  const [searchParams, setSearchParams] = useSearchParams();

  const scenarioId = z.coerce
    .number()
    .safeParse(searchParams.get('scenarioId')).data;

  const defaultModal = z
    .enum(cameraSettingsModals)
    .safeParse(searchParams.get('modal')).data;

  const [showCameraSettingsModal, setShowCameraSettingsModal] =
    useState<boolean>(isDefined(defaultModal));

  const timePeriod = useMemo(
    () => ({
      from: timeRange.value,
      until: timeRange.end,
    }),
    [timeRange],
  );

  const handleScenarioChange = useCallback(
    (scenario: Scenario) => {
      setSearchParams((searchParams) => {
        if (scenario) {
          searchParams.set('scenarioId', String(scenario.id));
        } else if (searchParams.has('scenarioId')) {
          searchParams.delete('scenarioId');
        }

        return searchParams;
      });
    },
    [setSearchParams],
  );

  if (!gqlCamera || gqlCamera.id !== cameraId) {
    return <LoadingPage />;
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Breadcrumb
            title={`${i18n.t('page.camera_details.go_back')}`}
            toUrl="/cameras"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="d-flex justify-content-between mb-32">
          <div className="d-flex flex-column gap-2">
            <PageTitle
              title={i18n.t('page.camera_details.title')}
              removeMargins
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: spacing(4),
              }}
            >
              <span
                style={{
                  display: 'flex',
                  gap: spacing(2),
                  color: GRAY_WARM_DARKER_PLACEHOLDER,
                  fontWeight: 500,
                }}
              >
                <CameraIcon />
                {gqlCamera.name}
              </span>
              {gqlCamera.status !== null && (
                <CameraStatus status={gqlCamera.status} />
              )}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-end">
            <BWButton
              className="button-with-loader"
              icon={SettingsIcon}
              variant="outline"
              title={i18n.t('page.camera_details.settings')}
              onClick={() =>
                setShowCameraSettingsModal(!showCameraSettingsModal)
              }
            />
            <TimeRangeDropdown />
          </div>
        </Col>
      </Row>
      <Row className="mb-32">
        <FlexCol md={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: spacing(6),
            }}
          >
            <SectionTitle
              removeMargins
              title={i18n.t('page.camera_details.heatmap')}
            />
            <ImageDownloader onClick={exportCameraImage} />
          </div>
          <CameraHeatMap
            camera={gqlCamera}
            scenarioId={scenarioId}
            onScenarioChange={handleScenarioChange}
          />
        </FlexCol>
      </Row>
      <Row>
        <SectionTitle title={i18n.t('page.camera_details.stats')} />
      </Row>
      <ObservationCharts cameraIds={[gqlCamera.id]} />
      <Row>
        <FlexCol md={12}>
          <SectionTitle
            title={i18n.t('page.camera_details.observation_list')}
          />
          <ObservationList
            variant="camera"
            cameraId={cameraId}
            timePeriod={timePeriod}
          />
        </FlexCol>
      </Row>
      <CameraSettingsModal
        cameraId={gqlCamera.id}
        show={showCameraSettingsModal}
        defaultModal={defaultModal}
        onCloseClick={() => {
          setShowCameraSettingsModal(!showCameraSettingsModal);
        }}
      />
    </>
  );
}
