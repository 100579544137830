/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { GRAY_MIDDLE, GRAY_WARM_DARKER_AA } from '../../scss/colors';
import { spacing } from '../../scss/spacing';

type Props = PropsWithChildren<{
  title: string;
  style?: React.CSSProperties;
}>;

export function TitleWithContent({ title, style, children }: Props) {
  const { theme } = useThemeContext();
  return (
    <div
      style={style}
      css={css`
        padding: ${spacing(4)};
        border-top: 1px solid
          ${theme === 'light' ? GRAY_MIDDLE : GRAY_WARM_DARKER_AA};
      `}
    >
      <h3
        style={{
          padding: 0,
          marginBottom: spacing(4),
        }}
      >
        {title}
      </h3>
      {children}
    </div>
  );
}
