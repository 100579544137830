import { CameraSchedule } from '../../hooks/graphql/camera';

export type ScheduleProps = {
  [key: string]: string[];
};

export type ScenarioScheduleProps = {
  [key: string]: ScheduleProps;
};

export const emptyCameraSchedule: CameraSchedule = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
};
