import i18n from 'i18next';
import React from 'react';
import { Col } from 'react-bootstrap';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';

const shouldDisableDate = (date: Date) => date > new Date();

type Props = {
  onChange?: (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>,
  ) => void;
};

export function DateTimeFilter({ onChange }: Props) {
  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('filter.date_and_time.title')}</p>
      <p className="sub-color">{i18n.t('filter.date_and_time.body')}</p>

      <DateRangePicker
        format="yyyy-MM-dd, HH:mm:ss"
        shouldDisableDate={shouldDisableDate}
        onChange={onChange}
      />
    </Col>
  );
}
